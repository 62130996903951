@import "assets/scss/vars.scss";

.my_appointments {
	.appointments_type {
		margin-bottom: 24px;

		:global {
			.p-tabview-nav-container {
				.p-tabview-nav-content {
					.p-tabview-nav {
						border-bottom: 1px solid #dce0e5;
						padding-bottom: 2px;

						li {
							&.p-unselectable-text {
								flex-shrink: 0;

								.p-tabview-nav-link {
									border: 0;
									box-shadow: none;
									padding: 12px;
									@include font(14px, 500, #202326);
									position: relative;
									text-transform: capitalize;

									&::before {
										content: "";
										width: 100%;
										height: 3px;
										background: linear-gradient(
											90deg,
											#45cc9c 0%,
											#0c75e6 100%
										);
										position: absolute;
										bottom: 0;
										display: block;
										@include borderRadius(4px);
										left: 0;
										opacity: 0;
										visibility: hidden;
										@include transition(0.3s);
									}
								}

								&:not(.p-tabview-selected) {
									&:not(.p-highlight) {
										.p-tabview-nav-link {
											&:hover {
												background-color: #f8f9fc;
											}
										}
									}
								}

								&.p-tabview-selected {
									&.p-highlight {
										.p-tabview-nav-link {
											font-weight: 700;

											&::before {
												opacity: 1;
												visibility: visible;
											}
										}
									}
								}
							}

							&.p-tabview-ink-bar {
								display: none;
							}
						}
					}
				}
			}

			.p-tabview-panels {
				padding: 0;
			}
		}
	}

	.status_search {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		margin-bottom: 24px;

		@media (max-width: 767.98px) {
			flex-wrap: wrap;
		}

		.status_tab {
			:global {
				.p-tabview-nav-container {
					.p-tabview-nav-content {
						.p-tabview-nav {
							border: 0;
							padding-bottom: 2px;
							gap: 12px;

							li {
								&.p-unselectable-text {
									flex-shrink: 0;

									.p-tabview-nav-link {
										border: 0;
										box-shadow: none;
										padding: 9px 12px;
										@include font(12px, 600, #202326);
										background: #fff;
										border: 1px solid #dce0e5;
										@include borderRadius(8px);
										text-transform: capitalize;
									}

									&.p-tabview-selected {
										&.p-highlight {
											.p-tabview-nav-link {
												background: linear-gradient(
													90deg,
													#45cc9c 0%,
													#0c75e6 100%
												);
												@include font(12px, 700, #fff);
											}
										}
									}
								}

								&.p-tabview-ink-bar {
									display: none;
								}
							}
						}
					}
				}

				.p-tabview-panels {
					padding: 0;
				}
			}
		}

		:global {
			.input_holder {
				min-width: 250px;
			}
		}
	}

	.appointments_count {
		@include font(14px, 500, #808b9a);
		margin-bottom: 24px;
	}

	.appointments_cards {
		& > * {
			&:not(:last-child) {
				margin-bottom: 20px;
			}
		}
	}
}


.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; 
    display: flex;
    justify-content: center; 
    align-items: center; 
}

