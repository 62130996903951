@import "assets/scss/vars.scss";

.doctor_signup {
	min-height: 100vh;
	background-color: #fff;
	display: flex;
	flex-direction: column;

	.signup_head {
		background-color: #fff;
		border-bottom: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		padding: 11px 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: 575.98px) {
			padding: 11px 30px;
		}

		.logo {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 38px;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;

			img {
				height: 100%;
			}
		}

		.auth_lang {
			-webkit-padding-start: 0;
			padding-inline-start: 0;
			-webkit-margin-start: 0;
			margin-inline-start: 0;
			-webkit-border-start: 0;
			border-inline-start: 0;
			border: 1px solid #dce0e5;
			@include borderRadius(6px);

			[class*="lang_btn"] {
				padding: 7px 12px;

				&:hover {
					background-color: #e6e7e9;
				}
			}
		}
	}

	.signup_body {
		flex-grow: 1;
		display: flex;

		@media (max-width: 575.98px) {
			flex-direction: column;
		}

		.outlet_content {
			flex-grow: 1;
			padding: 72px 240px;

			@media (max-width: 1400.98px) {
				padding: 72px 170px;
			}

			@media (max-width: 1199.98px) {
				padding: 72px 140px;
			}

			@media (max-width: 991.98px) {
				padding: 72px 100px;
			}

			@media (max-width: 767.98px) {
				padding: 72px 30px;
			}

			@media (max-width: 575.98px) {
				padding: 30px;
			}
		}
	}
}
