@import "assets/scss/vars.scss";

.doctor_header {
    padding: 12px 32px;
    background-color: #fff;
    border-bottom: 1px solid #dce0e5;
    box-shadow: 0px 1px 8px 0px #020e1f0a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;

    @media (max-width: 991.98px) {
        flex-direction: column;
    }

    @media (max-width: 767.98px) {
        padding: 12px 20px;
    }

    .right {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;

        @media (max-width: 991.98px) {
            width: 100%;
        }

        @media (max-width: 600.98px) {
            flex-wrap: wrap;
        }
    }
}