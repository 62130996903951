@import "assets/scss/vars.scss";

.vitals {
	margin-bottom: 32px;

	.vitals_card {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);

		img {
			width: 32px;
			height: 32px;
			margin-bottom: 6px;
		}

		h6 {
			@include font(14px, 400, #808b9a);
			margin-bottom: 2px;
		}

		h5 {
			@include font(14px, 700, #202326);
			margin-bottom: 0;
		}
	}
}
