@import "assets/scss/vars.scss";

.create_account {
	.head {
		margin-bottom: 30px;

		h2 {
			@include font(24px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.form_holder {
		.inputs_wrapper {
			margin-bottom: 20px;
		}

		.agree {
			margin-bottom: 30px;

			&_label {
				a {
					@extend %gradientText;
				}
			}
		}
	}
}

.inpt_lbl {
	@include font(14px, 400, #202326);
	margin-bottom: 8px;
}
