@import "assets/scss/vars.scss";

.filter_doctors {
	background-color: #fff;
	padding: 0 20px;
	@include borderRadius(8px);

	:global {
		.p-accordion {
			.p-accordion-tab {
				margin-bottom: 0;

				.p-accordion-header {
					&-link {
						box-shadow: none !important;
						background-color: #fff !important;
						border: 0;
						padding: 20px 0;
						@include borderRadius(0);
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 8px;

						.p-icon {
							margin-right: 0;
							flex-shrink: 0;
							width: 100%;
							max-width: 12px;
							height: 12px;
							color: #020e1f;

							#{$rtl} & {
								@include transform(rotate(180deg));
							}
						}

						.p-accordion-header-text {
							@include font(14px, 700, #202326);
						}
					}
				}

				&:not(:last-child) {
					border-bottom: 1px solid #dce0e5;
				}

				&-active {
					padding-bottom: 20px;

					.p-accordion-header {
						&-link {
							.p-icon {
								#{$rtl} & {
									@include transform(rotate(0deg));
								}
							}
						}
					}
				}
			}

			&-content {
				border: 1px solid #dce0e5;
				@include borderRadius(6px);
				padding: 16px;
			}
		}
	}
}

.check_boxes {
	[class*="check_box"] {
		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}
}

.lang_box {
	:global {
		.input_holder {
			margin-bottom: 16px;
		}
	}

	.check_boxes {
		height: auto;
		max-height: 128px;
		overflow: hidden;
		@include transition(0.3s);

		&.height_auto {
			max-height: 500px;
		}
	}

	.view_all {
		padding: 2px;
		background-color: transparent !important;
		border: 0 !important;
		margin-top: 16px;

		:global {
			.p-button-label {
				@include font(14px, 600, transparent);
				@extend %gradientText;
			}
		}
	}
}

.rate_checkbox {
	&:not(:last-child) {
		margin-bottom: 16px;
	}

	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
}
