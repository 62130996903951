@import "assets/scss/vars.scss";

.responsive_header {
	background-color: #000050;

	:global {
		.p-sidebar-header {
			.p-sidebar-close {
				color: #fff;
			}
		}
	}

	.sidebar_content {
		height: 100%;
		display: flex;
		flex-direction: column;

		.content_top {
			flex-grow: 1;

			.menu_links {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-direction: column;
				gap: 8px;

				.menu_link {
					flex-shrink: 0;

					a {
						padding: 12px;
						display: flex;
						align-items: center;
						justify-content: center;
						@include font(14px, 500, #fff);
						@include transition(0.3s);
						position: relative;
						white-space: nowrap;

						&:hover {
							background-color: #f7f7f7;
						}
					}
				}
			}
		}

		.content_bottom {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 14px;

			:global {
				[class*="lang_holder"] {
					flex-shrink: 0;
					padding-inline-start: 0;
					margin-inline-start: 0;
					border-inline-start: 0;

					[class*="lang_btn"] {
						&:hover,
						&:active {
							background-color: transparent !important;
							color: #fff !important;
						}

						img {
							filter: brightness(0) invert(1) grayscale(100%);
							-webkit-filter: brightness(0) invert(1) grayscale(100%);
						}

						span {
							color: #fff;
						}
					}
				}
			}
		}
	}
}

.sidebar_wapper {
	&:global(.p-sidebar-left) {
		#{$ltr} & {
			justify-content: flex-start;
		}
		#{$rtl} & {
			justify-content: flex-end;
		}
	}
}
