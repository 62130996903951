@import "assets/scss/vars.scss";

.profile_content {
	.head {
		margin-bottom: 24px;

		h2 {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.upload_img {
		margin-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 21px;
		cursor: pointer;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;

		@media (max-width: 575.98px) {
			flex-direction: column;
			align-items: flex-start;
		}

		input[type="file"] {
			display: none;
		}

		.img_box {
			flex-shrink: 0;
			width: 120px;
			height: 120px;
			overflow: hidden;
			@include borderRadius(8px);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}

		.upload_txt {
			h5 {
				@include font(14px, 700, transparent);
				@extend %gradientText;
				margin-bottom: 2px;
				line-height: 20px;
			}

			h6 {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
				line-height: 20px;
			}
		}
	}
}
