@import "assets/scss/vars.scss";

.add_drawer_holder {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	@include borderRadius(8px);
	width: 26.875rem !important;
	margin: 16px;
	height: calc(100% - 32px) !important;

	:global {
		.p-sidebar-header {
			display: none;
		}

		.p-sidebar-content {
			display: flex;
			flex-direction: column;
			padding: 0;
		}
	}

	.drawer_header {
		padding: 17px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		border-bottom: 1px solid #dce0e5;

		h2 {
			@include font(20px, 800, #202326);
			margin-bottom: 0;
		}

		.close_btn {
			width: 30px;
			height: 30px;
			padding: 6px;
			@include borderRadius(4px);
			background-color: #fff !important;
			border: 0 !important;
			color: #020e1f !important;
		}
	}

	.drawer_form {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		height: calc(100% - 65px);
	}

	.drawer_content {
		flex-grow: 1;
		overflow-y: auto;
		padding: 24px 24px 40px;

		/* Custom Scroll bar */
		&::-webkit-scrollbar {
			width: 0.3em;
		}
	}

	.drawer_footer {
		padding: 24px;
		border-top: 1px solid #dce0e5;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 12px;

		.done_btn {
			:global {
				.p-button-label {
					font-size: 14px;
				}
			}
		}
	}
}

.upload_sec {
	.upload_head {
		margin-bottom: 20px;

		h5 {
			@include font(14px, 700, #202326);
			margin-bottom: 0;
			line-height: 20px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
			line-height: 20px;
		}
	}

	.upload_action {
		input[type="file"] {
			display: none;
		}

		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
		padding: 24px;
		background-color: #f8f9fc;
		border: 1px dashed #dce0e5;
		@include borderRadius(6px);
		cursor: pointer;

		.upload_icon {
			flex-shrink: 0;
			width: 20px;
			height: 20px;
		}

		.txt {
			h5 {
				@include font(14px, 700, #202326);
				margin-bottom: 0;
				line-height: 20px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
				line-height: 20px;
			}
		}
	}

	.uploaded_files {
		.uploaded_file {
			&:not(:last-child) {
				margin-bottom: 20px;
			}

			background-color: #fff;
			border: 0.5px solid #dce0e5;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			@include borderRadius(6px);

			.file_data {
				padding: 24px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 12px;

				.file_info {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;

					.file_icon {
						flex-shrink: 0;
						width: 32px;
						height: 32px;
					}

					.txt {
						h4 {
							@include font(14px, 700, #202326);
							margin-bottom: 0;
							line-height: 20px;
						}

						p {
							@include font(14px, 400, #808b9a);
							margin-bottom: 0;
							line-height: 20px;
						}
					}
				}

				.delete_btn {
					flex-shrink: 0;
					padding: 0;
					width: 20px;
					height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}
}

.add_drawer {
	&:global(.p-sidebar-left) {
		#{$ltr} & {
			justify-content: flex-start;
		}
		#{$rtl} & {
			justify-content: flex-end;
		}
	}
}
