@import "assets/scss/vars.scss";

.communication_step {
	&_content {
		padding: 24px 24px 40px;

		.title {
			margin-bottom: 24px;

			h3 {
				@include font(16px, 800, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.hint {
			@include font(12px, 500, #808b9a);
			margin-top: 8px;
		}
	}

	.footer_actions {
		padding: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #dce0e5;
	}
}
