@import "assets/scss/vars.scss";

.download_app {
	background-color: #eaf8f9;

	&_img {
		width: 100%;
	}

	.right_side {
		padding: 50px 0;

		h2 {
			@include font(36px, 800, #202326);
			margin-bottom: 16px;
		}

		p {
			@include font(16px, 400, #202326);
			margin-bottom: 30px;
		}

		.download_form {
			display: flex;
			justify-content: space-between;
			gap: 24px;
			margin-bottom: 32px;

			@media (max-width: 380.98px) {
				flex-direction: column;
				align-items: flex-start !important;
			}

			:global {
				.input_holder {
					margin-bottom: 0;
					width: 100%;
				}
			}

			[class*="gradient_btn"] {
				flex-shrink: 0;
			}
		}

		.download_btns {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 32px;
			flex-wrap: wrap;

			.app_btn {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;

				img {
					height: 100%;
				}
			}
		}
	}
}
