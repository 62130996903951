@import "assets/scss/vars.scss";

.appointment_date {
	margin-bottom: 24px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 24px;
	}

	&_content {
		padding: 24px;
		background-color: #fff;
		@include borderRadius(8px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: 575.98px) {
			flex-direction: column;
		}

		.left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;

			.icon {
				flex-shrink: 0;
				width: 38px;
				height: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				@include borderRadius(6px);

				img {
					width: 100%;
					max-width: 18px;
				}
			}

			.appointment_data {
				.place {
					@include font(17px, 700, #202326);
					margin-bottom: 2px;
				}

				.date {
					@include font(14px, 400, #202326);
				}
			}
		}
	}
}
