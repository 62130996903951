@import "assets/scss/vars.scss";

.pricing_step {
	&_content {
		padding: 24px 24px 40px;

		.sec_holder {
			&:not(:last-child) {
				margin-bottom: 24px;
			}

			.title {
				@include font(14px, 700, #202326);
				margin-bottom: 24px;
			}

			.currency {
				@include font(14px, 700, #202326);
			}
		}
	}

	.footer_actions {
		padding: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #dce0e5;
	}
}
