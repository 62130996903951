@import "assets/scss/vars.scss";

.progressBar_wrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 24px;

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	.progress_txt {
		flex-shrink: 0;
		flex-basis: 70px;
		@include font(12px, 500, #202326);
	}

	:global {
		.p-progressbar {
			flex-grow: 1;
			height: 6px;
			background-color: #d4f0f3;
			@include borderRadius(12px);

			&-value {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				@include borderRadius(50px);
			}
		}
	}

	.progress_val {
		flex-shrink: 0;
		flex-basis: 36px;
		@include font(12px, 600, #202326);
		text-align: end;
	}
}
