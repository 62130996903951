@import "assets/scss/vars.scss";

.medication_card {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 12px;
	padding: 20px;
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);

	@media (max-width: 575.98px) {
		flex-direction: column;
		gap: 40px;
	}

	.icon_info {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 12px;
		flex-grow: 1;
		width: 100%;

		@media (max-width: 375.98px) {
			flex-direction: column;
		}

		.icon {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
		}

		.medication_info {
			flex-grow: 1;

			.info_head {
				margin-bottom: 10px;

				h3 {
					@include font(14px, 700, #202326);
					margin-bottom: 2px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			.info_body {
				:global(.row) {
					&:not(:last-child) {
						margin-bottom: 10px;
					}
				}

				h5 {
					@include font(14px, 500, #202326);
					margin-bottom: 0;
				}

				h6 {
					@include font(14px, 600, #202326);
					margin-bottom: 0;
				}
			}
		}
	}

	.action {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 10px;

		[class*="white_btn"] {
			padding: 6px;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}
}
