@import "assets/scss/vars.scss";

.achievements {
	padding: 96px 0;

	.sec_head {
		margin-bottom: 48px;

		h2 {
			@include font(32px, 800, #202326);
			text-align: center;
			margin-bottom: 2px;
		}

		p {
			@include font(14x, 400, #808b9a);
			text-align: center;
			margin-bottom: 0;
		}
	}

	.achievement_box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2px;

		.count {
			@include font(36px, 800, #202326);
			text-align: center;
		}

		.title {
			@include font(16px, 400, #202326);
			text-align: center;
		}
	}
}
