@import "assets/scss/vars.scss";

.schedule_data {
	padding: 24px 16px;
	background-color: #fff;
	border: 0.5px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	height: 100%;

	.title_arrows {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		margin-bottom: 16px;

		h2 {
			@include font(16px, 800, #202326);
			margin-bottom: 0;
		}

		:global {
			.schedule-swiper-button-prev,
			.schedule-swiper-button-next {
				background-color: #fff;
				border: 0;
				color: #020e1f;
				flex-shrink: 0;
				padding: 0;
				width: 24px;
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	.appointments_type {
		margin-bottom: 24px;

		:global {
			.p-tabview-nav-container {
				.p-tabview-nav-content {
					.p-tabview-nav {
						border-bottom: 1px solid #dce0e5;
						padding-bottom: 2px;
						justify-content: space-between;

						li {
							&.p-unselectable-text {
								flex-shrink: 0;

								.p-tabview-nav-link {
									border: 0;
									box-shadow: none;
									padding: 12px 39px;
									@include font(14px, 500, #202326);
									position: relative;
									text-transform: capitalize;

									&::before {
										content: "";
										width: 100%;
										height: 3px;
										background: linear-gradient(
											90deg,
											#45cc9c 0%,
											#0c75e6 100%
										);
										position: absolute;
										bottom: 0;
										display: block;
										@include borderRadius(4px);
										left: 0;
										opacity: 0;
										visibility: hidden;
										@include transition(0.3s);
									}
								}

								&:not(.p-tabview-selected) {
									&:not(.p-highlight) {
										.p-tabview-nav-link {
											&:hover {
												background-color: #f8f9fc;
											}
										}
									}
								}

								&.p-tabview-selected {
									&.p-highlight {
										.p-tabview-nav-link {
											font-weight: 700;

											&::before {
												opacity: 1;
												visibility: visible;
											}
										}
									}
								}
							}

							&.p-tabview-ink-bar {
								display: none;
							}
						}
					}
				}
			}

			.p-tabview-panels {
				padding: 0;
			}
		}
	}

	.slide_content {
		.schedule_status {
			margin-bottom: 16px;

			.status_box {
				&:not(:last-child) {
					margin-bottom: 16px;
				}

				padding: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				@include borderRadius(6px);

				.txt {
					@include font(16px, 800, #202326);
				}

				.val {
					@include font(16px, 800, transparent);
				}

				&.waiting {
					background-color: #fff9e6;

					.val {
						color: #ffc107;
					}
				}

				&.done {
					background-color: #edf8f2;

					.val {
						color: #48bb78;
					}
				}
			}
		}

		.appointments_count {
			@include font(12px, 400, #808b9a);
			margin-bottom: 16px;
		}

		.appointments {
			.appointment_box {
				&:not(:last-child) {
					margin-bottom: 16px;
				}

				padding: 16px;
				background-color: #fff;
				border: 1px solid transparent;
				-webkit-border-start: 3px solid transparent;
				border-inline-start: 3px solid transparent;
				display: flex;
				align-items: center;
				justify-content: flex-start;

				.left {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					img {
						width: 20px;
						height: 20px;
						margin-bottom: 6px;
					}

					span {
						@include font(14px, 600, #202326);
						text-align: center;
					}
				}

				.right {
					-webkit-padding-start: 12px;
					padding-inline-start: 12px;
					-webkit-margin-start: 12px;
					margin-inline-start: 12px;
					-webkit-border-start: 1p solid #dce0e5;
					border-inline-start: 1px solid #dce0e5;

					h5 {
						@include font(14px, 700, #202326);
						margin-bottom: 0;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}

				&.inClinic {
					border-color: #d4f0f3;
					border-inline-start-color: #29b6c2;
				}

				&.videoCall {
					border-color: #f8d7da;
					border-inline-start-color: #dc3545;
				}
			}
		}
	}
}
