@import "assets/scss/vars.scss";

.top_articles {
	padding: 96px 0;

	.txt {
		margin-bottom: 50px;

		h2 {
			@include font(24px, 800, #202326);
			text-align: center;
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			text-align: center;
			margin-bottom: 0;
		}
	}

	.article_box {
		padding: 16px;
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(8px);

		.article_img {
			width: 100%;
			height: 250px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			margin-bottom: 24px;

			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				@include transition(0.3s);
			}
		}

		.article_body {
			h5 {
				@include font(16px, 800, #202326);
				margin-bottom: 4px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				text-overflow: ellipsis;
				height: 40px;
			}

			.author_date {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin-bottom: 8px;

				span {
					@include font(14px, 400, #808b9a);
					-webkit-padding-end: 5px;
					padding-inline-end: 5px;
					-webkit-border-end: 2px solid #808b9a;
					border-inline-end: 2px solid #808b9a;

					&:not(:last-child) {
						-webkit-margin-end: 5px;
						margin-inline-end: 5px;
					}
				}
			}

			.read_more {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 6px;
				padding: 4px 6px;
				background-color: transparent;

				span {
					@include font(14px, 700, transparent);
					background-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}

				img {
					width: 100%;
					max-width: 24px;
					@include transition(0.3s);

					#{$rtl} & {
						@include transform(rotate(180deg));
					}
				}
			}
		}

		&:hover {
			.article_img {
				img {
					@include transform(scale(1.1));
				}
			}
		}
	}

	[class*="gradient_btn"] {
		margin: 50px auto 0;
	}
}
