@import "assets/scss/vars.scss";

.table_head {
	margin-bottom: 32px;
}

.table_holder {
	background-color: #fff;
	border: 0.7px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	overflow: hidden;

	:global {
		.p-datatable-table {
			.p-datatable-thead {
				tr {
					th {
						&:first-child {
							#{$ltr} & {
								border-top-left-radius: 6px;
							}
							#{$rtl} & {
								border-top-right-radius: 6px;
							}
						}

						&:last-child {
							#{$ltr} & {
								border-top-right-radius: 6px;
							}
							#{$rtl} & {
								border-top-left-radius: 6px;
							}
						}

						background-color: #f8f9fc;
						@include font(14px, 700, #808b9a);
					}
				}
			}

			.p-datatable-tbody {
				tr {
					td {
						@include font(14px, 600, #202326);
					}
				}
			}
		}
	}
}

.table_action {
	.action_btn {
		width: 20px;
		height: 20px;
		padding: 3px 9px;
		font-size: 14px;
		background-color: #fff !important;
		border: 0;
		// color: #020e1f !important;
		color: red !important;

		:global(.p-button-icon) {
			font-size: 14px;
		}
	}
}

.action_menu {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 0.5px solid #dce0e5 !important;
	@include borderRadius(8px);
	padding: 12px;
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				.p-menuitem-link {
					padding: 10px 8px;
					@include borderRadius(4px);
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					width: 100%;
					background-color: #fff !important;
					border: 0 !important;

					img {
						flex-shrink: 0;
						width: 18px;
						height: 18px;
					}

					span {
						@include font(14px, 500, #202326);
						text-transform: capitalize;
					}

					&:hover {
						background-color: #f3f4f6 !important;
					}
				}
			}
		}
	}
}
