@import "assets/scss/vars.scss";

.white_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	padding: 10px 12px;
	@include borderRadius(6px);
	background-color: #fff !important;
	@include font(14px, 700, transparent);
	background-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	width: fit-content;
	border: 0 !important;
	@include transition(0.3s);
	position: relative;

	:global {
		.p-button-label {
			@include font(14px, 700, transparent);
			background-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			flex-grow: initial;
		}
	}

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #fff;
		@include borderRadius(6px);
	}

	&,
	span {
		@include font(14px, 700, transparent);
		background-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	span {
		z-index: 1;
	}

	&:hover,
	&:active {
		box-shadow: none;
	}

	img,
	i {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		position: relative;
	}
}
