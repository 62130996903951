@import "assets/scss/vars.scss";

.confirm_appointments_booking {
	flex-grow: 1;
	background-color: #f8f9fc;
	padding: 32px 0 50px;

	h1 {
		@include font(20px, 800, #202326);
		margin-bottom: 32px;
	}

	.summary_row {
		&:not(:last-child) {
			margin-bottom: 16px;
		}

		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 8px;

		@media (max-width: 280.98px) {
			flex-direction: column;
			align-items: flex-start;
		}

		.txt {
			@include font(14px, 400, #202326);
		}

		.val {
			@include font(14px, 600, #202326);
		}
	}
}
