@import "assets/scss/vars.scss";

.inputs_wrapper {
	padding-bottom: 32px;
	margin-bottom: 32px;
	border-bottom: 1px solid #e5e7eb;
}

.hint {
	@include font(12px, 500, #808b9a);
	margin-top: 8px;
}
