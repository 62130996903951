@import "assets/scss/vars.scss";

.specialties {
	padding: 96px 0;

	:global {
		.sec_head {
			margin-bottom: 50px;
		}
	}

	.specialties_slider {
		position: relative;

		@media (max-width: 575.98px) {
			margin: 0 10px;
		}

		.specialty_box {
			@include borderRadius(8px);
			background-color: #fff;
			overflow: hidden;

			.specialty_img {
				padding: 10px;
				@include borderRadius(8px);
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 180px;
				width: 100%;

				img {
					width: 100%;
					max-width: 60px;
				}
			}

			.name {
				padding: 14px 2px 0;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				@include font(14px, 500, #30363c);
			}
		}
	}
}
