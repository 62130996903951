@import "assets/scss/vars.scss";

.registration_documents {
	.head {
		margin-bottom: 32px;

		h2 {
			@include font(24px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.upload_files {
		&_title {
			margin-bottom: 20px;

			h5 {
				@include font(14px, 700, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.uploaded_files {
			margin-bottom: 32px;

			.uploaded_file_box {
				&:not(:last-child) {
					margin-bottom: 32px;
				}

				background-color: #fff;
				border: 0.5px solid #dce0e5;
				box-shadow: 0px 1px 8px 0px #020e1f0a;
				@include borderRadius(6px);
				overflow: hidden;

				.file_info {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 12px;
					padding: 24px;

					@media (max-width: 375.98px) {
						padding: 10px;
					}

					.left {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 12px;

						.file_icon {
							flex-shrink: 0;
							width: 100%;
							max-width: 32px;
						}

						.file_data {
							h5 {
								@include font(14px, 700, #202326);
								margin-bottom: 2px;

								@media (max-width: 375.98px) {
									font-size: 12px;
								}
							}

							h6 {
								@include font(14px, 400, #808b9a);
								margin-bottom: 0;

								@media (max-width: 375.98px) {
									font-size: 12px;
								}
							}
						}
					}

					.delete_btn {
						flex-shrink: 0;
						padding: 0;
						width: 20px;
						height: 20px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: transparent;
						border: 0;
					}
				}
			}
		}

		.upload_inputs {
			margin-bottom: 32px;

			.upload_input {
				&:not(:last-child) {
					margin-bottom: 32px;
				}

				.upload_title {
					margin-bottom: 20px;

					h5 {
						@include font(14px, 700, #202326);
						margin-bottom: 2px;
					}

					h6 {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}
				.upload_image {
					max-width: 20%; /* Ensures the image does not exceed the container width */
					height: auto; /* Maintains the aspect ratio of the image */
					display: block; /* Removes bottom space/gap */
				}

				.upload_action_box {
					input[type="file"] {
						display: none;
					}

					padding: 24px;
					background-color: #f8f9fc;
					border: 1px dashed #dce0e5;
					@include borderRadius(6px);
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 20px;
					cursor: pointer;

					@media (max-width: 375.98px) {
						padding: 10px;
						gap: 12px;
					}

					.upload_icon {
						flex-shrink: 0;
						width: 100%;
						max-width: 20px;
					}

					.upload_txt {
						h4 {
							@include font(14px, 700, #202326);
							margin-bottom: 2px;

							@media (max-width: 375.98px) {
								font-size: 12px;
							}
						}

						p {
							@include font(14px, 400, #808b9a);
							margin-bottom: 0;

							@media (max-width: 375.98px) {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}
}
