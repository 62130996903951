@import "assets/scss/vars.scss";

.booked_doctor {
	padding: 24px;
	background-color: #fff;
	@include borderRadius(8px);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 32px;
	margin-bottom: 24px;

	@media (max-width: 280.98px) {
		flex-direction: column;
	}

	.doctor_img {
		flex-shrink: 0;
		width: 100%;
		max-width: 104px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.doctor_info {
		.name {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		.speciality {
			@include font(14px, 400, #808b9a);
			margin-bottom: 6px;
		}

		.desc {
			@include font(14px, 400, #202326);
		}
	}
}
