@import "assets/scss/vars.scss";

.doctorList_card {
	background-color: #fff;
	@include borderRadius(6px);
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;

	&:not(:last-child) {
		margin-bottom: 16px;
	}

	@media (max-width: 767.98px) {
		flex-wrap: wrap;
	}

	.left {
		flex-shrink: 0;

		.doctor_img {
			width: 154px;
			height: 180px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			margin: 0 auto 10px;

			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}
	}

	.middle {
		flex-grow: 1;

		.name {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		.speciality {
			@include font(14px, 400, #808b9a);
			margin-bottom: 6px;
		}

		.rate_reviews {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			margin-bottom: 8px;

			.reviews {
				@include font(12px, 500, #808b9a);
			}
		}

		.info_list {
			.info_row {
				&:not(:last-child) {
					margin-bottom: 8px;
				}

				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 4px;

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 16px;
				}

				span {
					@include font(14px, 400, #202326);
				}
			}
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		gap: 16px;
		flex-shrink: 0;
	}
}
