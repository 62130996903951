@import "assets/scss/vars.scss";

// CheckBox
.radio_btn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;

	:global {
		.p-radiobutton {
			width: 18px;
			height: 18px;

			&-box {
				width: 18px;
				height: 18px;
				border: 2px solid #b5bec9 !important;
				background: #fff;
				box-shadow: 0px 1px 8px 0px #020e1f0a !important;

				&.p-highlight {
					background: linear-gradient(
						90deg,
						#45cc9c 0%,
						#0c75e6 100%
					) !important;
					border: 0 !important;
				}

				.p-radiobutton-icon {
					width: 8px !important;
					height: 8px !important;
				}
			}
		}
	}

	.radioBtn_lbl {
		@include font(14px, 400, #202326);
	}
}
