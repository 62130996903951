@import "assets/scss/vars.scss";

.about_doctor {
	padding: 24px;
	background-color: #fff;
	@include borderRadius(8px);
	margin-bottom: 16px;

	h3 {
		@include font(16px, 800, #202326);
		margin-bottom: 16px;
	}

	p {
		@include font(14px, 400, #202326);
		margin-bottom: 0;
	}
}
