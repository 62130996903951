@import "assets/scss/vars.scss";

.log_p {
	min-height: 100vh;
	background-color: #fff;
	overflow: hidden;

	& > :global(.row) {
		min-height: 100vh;
	}

	.auth_lang {
		position: absolute;
		top: 40px;
		#{$ltr} & {
			right: 50px;

			@media (max-width: 575.98px) {
				right: 20px;
			}
		}
		#{$rtl} & {
			left: 50px;

			@media (max-width: 575.98px) {
				left: 20px;
			}
		}
		-webkit-padding-start: 0;
		padding-inline-start: 0;
		-webkit-margin-start: 0;
		margin-inline-start: 0;
		-webkit-border-start: 0;
		border-inline-start: 0;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);

		[class*="lang_btn"] {
			padding: 7px 12px;

			&:hover {
				background-color: #e6e7e9;
			}
		}
	}

	.img_side {
		display: block;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}

	.log_form {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 32px 140px;

		@media (max-width: 1199.98px) {
			padding: 32px 60px;
		}

		@media (max-width: 991.98px) {
			padding: 32px 30px;
		}

		.top_sec {
			flex-grow: 1;
			width: 100%;
			padding: 207px 24px;
			margin-bottom: 10px;

			@media (max-width: 1199.98px) {
				padding: 100px 24px;
			}

			@media (max-width: 575.98px) {
				padding: 80px 0px;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				margin-bottom: 24px;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;

				img {
					height: 100%;
				}
			}

			.f_txt {
				margin-bottom: 24px;

				h3 {
					@include font(24px, 800, #202326);
					margin-bottom: 2px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			.inputs_wrapper {
				margin-bottom: 16px;
			}

			.forget_Pass {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				@include font(14px, 700, transparent);
				@extend %gradientText;
				@include transition(0.3s);
				border-bottom: 1px solid transparent;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				margin-bottom: 24px;

				&:hover {
					-o-border-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					border-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					border-image-slice: 1;
				}
			}

			.have_account {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;
				margin-top: 24px;

				span {
					@include font(14px, 400, #202326);
				}

				a {
					@include font(14px, 700, transparent);
					@extend %gradientText;
				}
			}
		}

		.auth_footer {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			a {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(12px, 500, #202326);
				@include transition(0.3s);

				&:hover {
					@include font(12px, 600, transparent);
					@extend %gradientText;
				}
			}
		}
	}
}
