@import "assets/scss/vars.scss";

.page_head {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 20px;

	@media (max-width: 768.98px) {
		flex-wrap: wrap;
	}

	@media (max-width: 375.98px) {
		flex-direction: column;
	}

	.txt {
		h1 {
			@include font(20px, 800, #202326);
			margin-bottom: 2px;
		}

		h5 {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}
}
