@import "assets/scss/vars.scss";

.reminders_content {
	.appointments_tabs {
		border: 1px solid #dce0e5;
		margin-bottom: 24px;

		:global {
			.rts___tabs___container {
				padding: 0;

				.rts___tabs {
					padding: 0;

					.header_btn {
						flex-shrink: 0;
						background-color: #fff;
						padding: 20px 24px;
						border: 0;
						position: relative;
						box-shadow: none;
						@include borderRadius(0);
						margin: 0;

						&::before {
							content: "";
							width: 35px;
							height: 3px;
							background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
							position: absolute;
							bottom: 0;
							display: block;
							left: 50%;
							@include transform(translateX(-50%));
							@include borderRadius(8px);
							opacity: 0;
							visibility: hidden;
							@include transition(0.3s);
						}

						&_content {
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							gap: 2px;
						}

						.day {
							@include font(14px, 600, #202326);
							line-height: 20px;
						}

						.date {
							@include font(12px, 400, #808b9a);
							line-height: 20px;
						}

						.day {
							@include font(14px, 600, #202326);
							line-height: 20px;
						}

						.date {
							@include font(12px, 400, #808b9a);
							line-height: 20px;
						}

						&.rts___tab___selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}

				.rts___nav___btn___container {
					display: flex !important;

					.rts___btn {
						box-shadow: 0px 4px 20px -2px #020e1f14, 0px 0px 1px 0px #020e1f1a !important;
						border: 1px solid #dce0e5 !important;
						background-color: #fff;
						width: 34px;
						@include borderRadius(0);

						img {
							width: 22px;
							height: 22px;
						}
					}
				}
			}
		}
	}

	.reminders_boxes {
		.reminder_row {
			&:not(:last-child) {
				margin-bottom: 24px;
			}

			.reminder_head {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;
				margin-bottom: 24px;

				img {
					flex-shrink: 0;
					width: 24px;
				}

				span {
					@include font(16px, 800, #202326);
					text-transform: capitalize;
				}
			}

			.reminders_wrapper {
				[class*="reminder_box"] {
					&:first-child {
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}

					&:last-child {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
					}

					&:not(:first-child):not(:last-child) {
						border-top-left-radius: 0;
						border-top-right-radius: 0;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
			}
		}
	}
}