@import "assets/scss/vars.scss";

.user_dropdown {
	flex-shrink: 0;
	position: relative;

	.menu_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		padding: 7px 2px;
		background-color: transparent !important;
		border: 0;
		@include font(14px, 700, #020e1f !important);

		.profile_icon {
			width: 100%;
			max-width: 22px;

			@media (max-width: 575.98px) {
				max-width: 18px;
			}

			@media (max-width: 375.98px) {
				max-width: 16px;
			}
		}

		.user_name {
			@include font(14px, 700, #020e1f !important);

			@media (max-width: 375.98px) {
				font-size: 12px;
			}
		}
	}
}

.user_menu {
	padding: 12px;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 1px solid #dce0e5 !important;
	background-color: #fff !important;
	@include borderRadius(8px);

	:global {
		.p-menu {
			&-list {
				.p-menuitem {
					&-link {
						padding: 10px 8px;
						box-shadow: none !important;
						justify-content: flex-start;
						gap: 12px;

						&:hover {
							background-color: #f7f7f7;
						}
					}

					&-icon {
						margin: 0 !important;
						font-size: 18px;
						height: 18px;
						color: #8392a5;
						width: 100%;
						max-width: 18px;
						flex-shrink: 0;
					}

					&-text {
						@include font(14px, 500, #202326);
					}
				}
			}
		}
	}
}
