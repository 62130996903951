@import "assets/scss/vars.scss";

.doctor_appointments {
	padding: 32px;

	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		flex-wrap: wrap;
		margin-bottom: 32px;

		h1 {
			@include font(24px, 800, #202326);
			margin-bottom: 0;
		}

		.actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 24px;
			flex-wrap: wrap;

			& > * {
				flex-shrink: 0;
			}

			:global {
				.input_holder {
					min-width: 250px;

					[class*="field_holder"] {
						[class*="input_box"] {
							:global {
								.p-dropdown {
									&-label {
										padding: 9px 10px;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
