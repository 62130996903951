@import "assets/scss/vars.scss";

.dialog_wrapper {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	@include borderRadius(8px);

	:global {
		.p-dialog-header {
			padding: 17px 24px;
			border-bottom: 1px solid #dce0e5;
		}

		.p-dialog-content {
			padding: 0;
			overflow: hidden;

			.modal_body {
				padding: 24px;
				max-height: calc(90vh - 147px);
				overflow-y: auto;
			}

			.modal_footer {
				padding: 24px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 12px;
				border-top: 1px solid #dce0e5;

				.discard_btn {
					padding: 7px 12px;
					background-color: #fff !important;
					border: 0 !important;

					:global {
						.p-button-label {
							@include font(14px, 700, #020e1f);
						}
					}

					&.withBorder {
						border: 1px solid #dce0e5 !important;
						@include borderRadius(6px);

						&:hover,
						&:active {
							background-color: #f8f9fc !important;
						}
					}
				}

				.done_btn {
					padding: 7px 12px;

					.p-button-label {
						font-size: 14px;
					}
				}
			}
		}
	}
}
