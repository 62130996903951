@import "assets/scss/vars.scss";

.settings_practiceDetails {
	.profile_wrapper {
		h6 {
			@include font(14px, 400, #202326);
			margin-bottom: 30px;
		}

		.upload_img {
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 21px;
			cursor: pointer;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;

			@media (max-width: 575.98px) {
				flex-direction: column;
				align-items: flex-start;
			}

			input[type="file"] {
				display: none;
			}

			.img_box {
				flex-shrink: 0;
				width: 120px;
				height: 120px;
				overflow: hidden;
				@include borderRadius(8px);
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}

			.upload_txt {
				h5 {
					@include font(14px, 700, transparent);
					@extend %gradientText;
					margin-bottom: 2px;
					line-height: 20px;
				}

				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
					line-height: 20px;
				}
			}
		}
	}

	.inputs_wrapper {
		margin-bottom: 30px;

		.hint {
			@include font(12px, 500, #808b9a);
			margin-top: 8px;
		}
	}
}
