@import "assets/scss/vars.scss";

.payment_method {
	margin-bottom: 24px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 24px;
	}

	&_content {
		padding: 24px;
		background-color: #fff;
		@include borderRadius(8px);

		.payment_row {
			&:not(:last-child) {
				padding-bottom: 20px;
				margin-bottom: 20px;
				border-bottom: 1px solid #dce0e5;
			}

			.option_holder {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;
				width: fit-content;

				:global {
					.p-radiobutton {
						flex-shrink: 0;
						width: 18px;
						height: 18px;

						&-box {
							width: 18px;
							height: 18px;
							box-shadow: none !important;
							border: 2px solid #b5bec9;

							&.p-highlight {
								border: 0;
								background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
								background-repeat: no-repeat;
							}

							.p-radiobutton-icon {
								width: 8px;
								height: 8px;
							}
						}
					}
				}

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 24px;
				}

				.lbl {
					h5 {
						@include font(14px, 700, #202326);
						margin-bottom: 2px;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}
			}

			.credit_card {
				margin-top: 16px;

				.credit_row {
					background: linear-gradient(0deg, #f8f9fc, #f8f9fc),
						linear-gradient(0deg, #dce0e5, #dce0e5);
					border: 1px solid #dce0e5;
					@include borderRadius(6px);
					padding: 14px 12px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 12px;
					margin-top: 10px;
					cursor: pointer;

					.left {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 12px;

						.payment_icon {
							flex-shrink: 0;
							width: 100%;
							max-width: 24px;
						}

						p {
							@include font(14px, 500, #202326);
							margin-bottom: 0;
						}
					}

					.check_icon {
						flex-shrink: 0;
						width: 100%;
						max-width: 18px;
					}
				}

				.add_btn {
					margin-top: 16px;
					flex-direction: row-reverse;

					:global {
						i {
							font-weight: 700;
							@extend %gradientText;
						}
					}
				}
			}
		}
	}
}