@import "assets/scss/vars.scss";

.billing_menu {
	padding: 12px !important;
	background-color: #fff !important;
	border: 0.5px solid #dce0e5 !important;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	@include borderRadius(8px);

	:global {
		.p-menu-list {
			.p-menuitem {
				&-link {
					padding: 10px 8px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					width: 100%;
					@include borderRadius(6px);
					background-color: transparent !important;
					border: 0 !important;

					img {
						flex-shrink: 0;
						width: 18px;
						height: 18px;
					}

					.lbl {
						@include font(14px, 500, #202326);
					}

					&:hover {
						background-color: #f3f4f6 !important;
					}
				}
			}
		}
	}
}

.billing_rows {
	.billing_row {
		padding: 20px;
		background-color: #fff;
		border: 0.7px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;

		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.billing_info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 16px;

			.icon {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 44px;
				height: 44px;
				background-color: #d4d8e6;
				@include borderRadius(8px);

				img {
					width: 24px;
					height: 24px;
				}
			}

			.text {
				h5 {
					@include font(14px, 700, #202326);
					margin-bottom: 0;
					line-height: 20px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
					line-height: 20px;
				}
			}
		}

		.row_action {
			flex-shrink: 0;

			.action_btn {
				width: 30px;
				height: 30px;
				padding: 6px;
				font-size: 14px;
				background-color: #fff !important;
				border: 0;
				color: #020e1f !important;

				:global(.p-button-icon) {
					font-size: 14px;
				}
			}
		}
	}
}

.action_menu {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 0.5px solid #dce0e5 !important;
	@include borderRadius(8px);
	padding: 12px;
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				.p-menuitem-link {
					padding: 10px 8px;
					@include borderRadius(4px);
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					width: 100%;
					background-color: #fff !important;
					border: 0 !important;

					img {
						flex-shrink: 0;
						width: 18px;
						height: 18px;
					}

					span {
						@include font(14px, 500, #202326);
						text-transform: capitalize;
					}

					&:hover {
						background-color: #f3f4f6 !important;
					}
				}
			}
		}
	}
}
