@import "assets/scss/vars.scss";

.consult_online {
	padding: 96px 0;
	background-color: #f8f9fc;

	:global {
		.sec_head {
			margin-bottom: 48px;
		}
	}

	.consult_boxes {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		gap: 24px;
		flex-wrap: wrap;

		@media (max-width: 1400.98px) {
			gap: 24px 16px;
		}

		.consult_box {
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 24px;
			max-width: 170px;

			.consult_img {
				width: 170px;
				height: 170px;
				@include borderRadius(140px);
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
					@include transition(0.3s);
				}
			}

			.consult_name {
				@include font(14px, 700, #202326);
				text-align: center;
			}

			&:hover {
				.consult_img {
					img {
						@include transform(scale(1.1));
					}
				}
			}
		}
	}
}
