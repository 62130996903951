@import "assets/scss/vars.scss";

.doctor_info {
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px solid #dce0e5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 20px;

	.doctor_img {
		width: 72px;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.txt {
		.doctor_name {
			@include font(16px, 800, #202326);
			text-align: center;
			margin-bottom: 2px;
		}

		.role {
			@include font(14px, 400, #808b9a);
			text-align: center;
			margin-bottom: 0;
		}
	}
}

.rate_msg {
	.rate {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;
	}

	[class*="input_box"] {
		height: 104px;
	}
}
