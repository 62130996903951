@import "assets/scss/vars.scss";

.moreLink_popover {
	padding: 20px 16px;
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	border: 0.5px solid #dce0e5;
	@include borderRadius(8px);
	z-index: 9;
	display: flex;
	flex-direction: column;
	width: 22%;
	max-width: 90%;

	.popover_head {
		margin-bottom: 16px;

		h4 {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		h6 {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.popover_body {
		flex-grow: 1;
		max-height: 120px;
		overflow: hidden auto;

		.event_box {
			&:not(:last-child) {
				margin-bottom: 16px;
			}

			padding: 8px 12px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			-webkit-border-start: 3px solid transparent;
			border-inline-start: 3px solid transparent;

			.time {
				flex-shrink: 0;
				max-width: 30%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				@include font(14px, 600, #202326);
			}

			.info {
				-webkit-margin-start: 12px;
				margin-inline-start: 12px;
				-webkit-padding-start: 12px;
				padding-inline-start: 12px;
				-webkit-border-start: 1px solid transparent;
				border-inline-start: 1px solid transparent;

				h5 {
					@include font(14px, 700, #202326);
					margin-bottom: 2px;
				}

				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			&.inClinic {
				background-color: #eaf8f9;
				border-inline-start-color: #29b6c2;

				.info {
					border-inline-start-color: #7fd3da;
				}
			}

			&.videoCall {
				background-color: #fbebec;
				border-inline-start-color: #dc3545;

				.info {
					border-inline-start-color: #ea868f;
				}
			}
		}
	}
}
