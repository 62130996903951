@import "assets/scss/vars.scss";

.app_footer {
	background-color: #000050;

	.footer_top {
		padding: 72px 0 48px;

		.footer_col {
			h5 {
				@include font(14px, 700, #fff);
				padding-bottom: 8px;
				margin-bottom: 24px;
				border-bottom: 4px solid transparent;
				border-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				border-image-slice: 1;
				width: fit-content;
			}

			.footer_links {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 8px;

				a {
					@include font(14px, 400, #fff);
					@include transition(0.3s);

					&:hover {
						text-decoration: underline !important;
					}
				}
			}
		}
	}

	.footer_bottom {
		padding: 32px 0;
		box-shadow: 0px 1px 0px 0px #485e901a inset;
		border-top: 1px solid #ffffff1f;

		&_content {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 24px;
			flex-wrap: wrap;

			.footer_logo {
				flex-shrink: 0;
				height: 38px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 100%;
				}
			}

			.copyRights {
				@include font(12px, 500, #fff);
			}

			.social_links {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 16px;

				a {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 30px;
					height: 30px;
					padding: 6px;
					@include transition(0.3s);

					img {
						width: 100%;
						max-width: 18px;
					}

					&:hover {
						background-color: rgba(255, 255, 255, 0.15);
					}
				}
			}
		}
	}
}
