@import "assets/scss/vars.scss";

.lang_holder {
	flex-shrink: 0;
	padding-inline-start: 16px;
	margin-inline-start: 6px;
	border-inline-start: 1px solid #dce0e5;

	@media (min-width: 992px) and (max-width: 1199.98px) {
		padding-inline-start: 6px;
	}

	.lang_btn {
		padding: 7px 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		background-color: transparent;
		color: #020e1f;

		img {
			flex-shrink: 0;
			width: 100%;
			max-width: 22px;

			@media (max-width: 575.98px) {
				max-width: 18px;
			}
		}

		span {
			@include font(14px, 700, #020e1f);
		}
	}
}
