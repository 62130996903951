@import "assets/scss/vars.scss";

.main_layout {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	.outlet_content {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}
}
