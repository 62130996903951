@import "assets/scss/vars.scss";

.app_header {
	padding: 16px 0;
	background-color: #fff;
	box-shadow: 0px 4px 20px -2px #020e1f14, 0px 0px 1px 0px #020e1f1a;
	z-index: 1;

	.header_content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;

		@media (max-width: 575.98px) {
			gap: 10px;
		}

		.header_left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 24px;

			.app_logo {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 38px;

				@media (min-width: 992px) and (max-width: 1199.98px) {
					height: 24px;
				}

				@media (max-width: 575.98px) {
					height: 24px;
				}

				img {
					height: 100%;
				}
			}

			.menu_links {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;

				@media (min-width: 992px) and (max-width: 1199.98px) {
					gap: 4px;
				}

				.menu_link {
					flex-shrink: 0;

					a {
						padding: 12px;
						display: flex;
						align-items: center;
						justify-content: center;
						@include font(14px, 500, #202326);
						@include transition(0.3s);
						position: relative;
						white-space: nowrap;

						@media (min-width: 992px) and (max-width: 1199.98px) {
							padding: 12px 6px;
						}

						&:hover {
							background-color: #f7f7f7;
						}
					}
				}
			}
		}

		.header_right {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 12px;

			@media (min-width: 992px) and (max-width: 1199.98px) {
				gap: 4px;
			}

			@media (max-width: 575.98px) {
				gap: 8px;
			}
		}
	}
}

.after_login {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 12px;

	@media (min-width: 992px) and (max-width: 1199.98px) {
		gap: 4px;
	}

	@media (max-width: 575.98px) {
		gap: 8px;
	}
}

.link_box {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	white-space: nowrap;

	img {
		flex-shrink: 0;
		width: 100%;
		max-width: 22px;

		@media (max-width: 575.98px) {
			max-width: 18px;
		}
	}

	span {
		@include font(14px, 700, #020e1f);
	}
}

.hamburger_btn {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px !important;
	width: auto !important;
	font-size: 0;
	background-color: transparent !important;
	border: 0 !important;
	color: #000050 !important;
}
