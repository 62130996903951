@import "assets/scss/vars.scss";

.input_lbl {
	@include font(14px, 400, #202326);
	margin-bottom: 8px;
}

.calendar_menu {
	min-width: 250px !important;
	max-width: 100%;
}

.address_title {
	@include font(14px, 700, #202326);
	margin-bottom: 16px;
	line-height: 20px;
}
