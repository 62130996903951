@import "assets/scss/vars.scss";

.vitals_signs {
	:global {
		.p-datatable {
			&-wrapper {
				.p-datatable-table {
					.p-datatable-thead {
						tr {
							th {
								background-color: #fff;
								padding: 12px 16px;

								.p-column-header-content {
									.p-column-title {
										@include font(14px, 700, #808b9a);
									}
								}
							}
						}
					}

					.p-datatable-tbody {
						tr {
							&:last-child {
								td {
									border-bottom: 0;
									@include font(14px, 600, #202326);
								}
							}
						}
					}
				}
			}
		}
	}
}

.delete {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px !important;
	height: 30px !important;
	padding: 6px !important;
}
