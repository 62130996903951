@import "assets/scss/vars.scss";

.summary {
	margin-bottom: 32px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 32px;
	}

	&_box {
		padding: 20px 24px;
		background-color: #fff;
		border: 0.7px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;

		@media (max-width: 600.98px) {
			flex-wrap: wrap;
		}

		.left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 24px;

			img {
				flex-shrink: 0;
				width: 24px;
				height: 24px;
			}

			.info {
				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 2px;
				}

				h4 {
					@include font(18px, 800, #202326);
					margin-bottom: 0;
				}
			}
		}
	}
}
