@import "assets/scss/vars.scss";

.check_symptoms {
	padding: 50px 0;
	background-color: #000050;

	&_content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		flex-wrap: wrap;

		.left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 24px;

			.symptoms_img {
				flex-shrink: 0;
				width: 100%;
				max-width: 95px;
			}

			.txt {
				h3 {
					@include font(24px, 800, #fff);
					margin-bottom: 6px;

					@media (max-width: 575.98px) {
						font-size: 20px;
					}
				}

				p {
					@include font(14px, 400, #fff);
					margin-bottom: 0;
				}
			}
		}
	}
}
