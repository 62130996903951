@import "assets/scss/vars.scss";

.chart_holder {
	padding: 24px;
	background-color: #fff;
	border: 1px solid #dce0e5;
	border-top: 0;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	margin-bottom: 32px;

	.chart_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		margin-bottom: 24px;

		@media (max-width: 991.98px) {
			flex-wrap: wrap;
		}

		h2 {
			@include font(20px, 800, #202326);
			margin-bottom: 0;
		}

		.duration_selection {
			:global {
				.p-selectbutton {
					padding: 2px;
					background-color: #f3f4f6;
					@include borderRadius(6px);
					display: flex;
					height: 100%;

					.p-button {
						padding: 5px 10px;
						background-color: transparent;
						border: 1px solid transparent;
						box-shadow: none !important;
						display: flex;
						align-items: center;
						justify-content: center;
						@include font(14px, 700, #676e79);
						@include borderRadius(6px);

						&.p-highlight {
							background-color: #fff;
							border: 1px solid #dce0e5;
							box-shadow: 0px 4px 12px -2px #3232470f, 0px 0px 1px 0px #0c1a4b1a !important;
							color: #020e1f;
						}
					}
				}
			}
		}
	}

	.chart_statistics {
		margin-bottom: 24px;

		.statistics_box {
			padding: 16px;
			background-color: #fff;
			border: 1px solid #dce0e5;
			@include borderRadius(6px);

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 2px;
			}

			.data {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;

				@media (max-width: 600.98px) {
					flex-wrap: wrap;
				}

				h4 {
					@include font(16px, 800, #202326);
					margin-bottom: 0;
				}

				h5 {
					margin-bottom: 0;

					display: flex;
					align-items: center;
					justify-content: center;
					gap: 8px;

					img {
						flex-shrink: 0;
						height: 12px;
					}

					span {
						@include font(18px, 800, #48bb78);
					}
				}

				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}
		}
	}

	.chart_wrapper {
		:global {
			.apexcharts-legend-marker {
				#{$rtl} & {
					margin-right: 0 !important;
					margin-left: 3px;
				}
			}

			.apexcharts-legend-text {
				#{$rtl} & {
					padding-left: 0 !important;
					margin-left: 0 !important;
					padding-right: 15px;
					margin-right: -15px;
				}
			}

			.apexcharts-tooltip {
				padding: 10px 16px;
				background-color: #fff;
				border: 0.5px solid #dce0e5;
				@include borderRadius(8px);
				box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;

				&-title {
					background-color: transparent !important;
					padding: 0;
					padding-bottom: 10px;
					margin-bottom: 10px;
					border-bottom: 1px solid #dce0e5 !important;
					@include font(14px !important, 500, #808b9a);
				}

				&-series-group {
					padding: 0;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 10px;

					&:not(:last-child) {
						margin-bottom: 10px;
					}

					.apexcharts-tooltip-marker {
						flex-shrink: 0;
						margin: 0;
						width: 9px;
						height: 9px;
					}

					.apexcharts-tooltip-text {
						flex-grow: 1;

						.apexcharts-tooltip-y-group {
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 6px;
							padding: 0;

							.apexcharts-tooltip-text-y-label {
								@include font(14px, 600, #202326);
							}

							.apexcharts-tooltip-text-y-value {
								@include font(20px, 800, #202326);
							}
						}
					}
				}
			}

			.apexcharts-legend {
				justify-content: flex-start;
				padding-top: 24px;
				margin-top: 24px;
			}
		}
	}
}
