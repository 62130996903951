@import "assets/scss/vars.scss";

.filter_range {
	:global {
		.p-slider {
			margin-bottom: 25px;
			height: 3px;
			background-color: #dce0e5;

			&-range {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			}

			&-handle {
				width: 18px;
				height: 18px;
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%) !important;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 6px;
				border: 0;
				box-shadow: none !important;

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					background-color: #fff;
					@include borderRadius(100%);
				}
			}

			& * {
				@include transition(0s);
			}
		}
	}

	.from_to {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;

		&_box {
			flex-basis: 50%;

			.txt {
				@include font(14px, 400, #202326);
				margin-bottom: 8px;
			}

			.val {
				padding: 12px 10px;
				border: 1px solid #dce0e5;
				@include borderRadius(8px);
				@include font(14px, 600, #202326);
			}
		}
	}
}
