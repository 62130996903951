@import "assets/scss/vars.scss";

.doctor_reports {
	padding: 32px;
	background-color: #fff;
	min-height: 100%;

	&_head {
		margin-bottom: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: 600.98px) {
			flex-wrap: wrap;
		}

		.left {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;

			@media (max-width: 600.98px) {
				flex-wrap: wrap;
			}

			h1 {
				@include font(24px, 800, #202326);
				margin-bottom: 0;
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 12px;

			@media (max-width: 600.98px) {
				flex-wrap: wrap;
			}

			.from_date {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;

				h5 {
					@include font(14px, 500, #202326);
					margin-bottom: 0;
				}
			}

			.to_date {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;

				h5 {
					@include font(14px, 500, #202326);
					margin-bottom: 0;
				}
			}
		}
	}

	&_body {
		.reports_tabs {
			:global {
				.p-tabview {
					&-nav {
						background-color: #f8f9fc;
						border: 1px solid #dce0e5;
						border-top-left-radius: 6px;
						border-top-right-radius: 6px;
						padding: 16px 24px 0;
						overflow-y: hidden;
						overflow-x: auto;

						.p-unselectable-text {
							.tab_btn {
								margin-bottom: 0;
								border: 0;
								padding: 18px 12px;
								background-color: transparent !important;
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 8px;

								img {
									flex-shrink: 0;
									width: 18px;
									height: 18px;

									&.gray_icon {
										display: block;
									}

									&.gradient_icon {
										display: none;
									}
								}

								.title {
									@include font(14px, 500, #202326);
								}
							}

							&.p-tabview-selected {
								.tab_btn {
									img {
										&.gray_icon {
											display: none;
										}

										&.gradient_icon {
											display: block;
										}
									}

									.title {
										font-weight: 700;
									}
								}
							}
						}

						.p-tabview-ink-bar {
							height: 3px;
							background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
							@include borderRadius(50px);
						}
					}

					&-panels {
						padding: 0;
					}
				}
			}
		}
	}
}
