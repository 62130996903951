@import "assets/scss/vars.scss";

.clinical_notes {
	&_row {
		&:not(:last-child) {
			border-bottom: 1px solid #dce0e5;
			padding-bottom: 12px;
			margin-bottom: 12px;
		}

		h5 {
			@include font(14px, 500, #808b9a);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 600, #202326);
			margin-bottom: 0;
		}
	}

	.edit_rows {
		margin-bottom: 16px;

		.edit_row {
			&:not(:last-child) {
				margin-bottom: 16px;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 16px;

			:global {
				.input_holder {
					width: 100%;
				}
			}
		}
	}
}
