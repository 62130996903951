@import "assets/scss/vars.scss";

.payment_methods {
	.head {
		margin-bottom: 24px;

		h2 {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.saved_payment_methods {
		padding: 16px 20px;
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);
		margin-bottom: 24px;

		.payment_method {
			&:not(:last-child) {
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid #dce0e5;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 12px;

			.info {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;

				img {
					flex-shrink: 0;
					width: 24px;
					height: 24px;
				}

				.txt {
					h5 {
						@include font(14px, 700, #202326);
						margin-bottom: 2px;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}
			}

			.actions {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;
			}
		}
	}
}
