@import "assets/scss/vars.scss";

.gradient_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	padding: 12px 22px;
	background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%) !important;
	color: #fff;
	border: 0;
	@include borderRadius(6px);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	@include font(16px, 700, #fff);
	@include transition(0.3s);

	:global {
		.p-button-label {
			@include font(16px, 700, #fff);
			flex-grow: initial;
		}
	}

	&:hover,
	&:active {
		box-shadow: 0px 8px 24px -8px #269cc699;
	}

	span {
		@include font(16px, 700, #fff);
	}

	&.outlinedBtn {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			width: calc(100% - 2px);
			height: calc(100% - 2px);
			top: 1px;
			bottom: 1px;
			left: 1px;
			right: 1px;
			background-color: #fff;
			@include borderRadius(6px);
			@include transition(0.3s);
		}

		&,
		span {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			-moz-background-clip: text;
			-moz-text-fill-color: transparent;
			@include font(16px, 700, transparent);
		}

		span {
			z-index: 1;
		}

		:global {
			.p-button-label {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-background-clip: text;
				-moz-text-fill-color: transparent;
				z-index: 1;
			}
		}

		&:hover,
		&:active {
			box-shadow: none;

			&::before {
				background-color: #e7f1fc;
			}
		}

		img,
		i {
			flex-shrink: 0;
			width: 20px;
			height: 20px;
			z-index: 1;
		}
	}

	img,
	i {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
	}

	&.rightPos {
		img,
		i {
			order: 1;
		}
	}

	&.leftPos {
		img,
		i {
			order: -1;
		}
	}

	&.arrowIcon {
		img,
		i {
			#{$rtl} & {
				@include transform(rotate(180deg));
			}
		}
	}

	&.p-disabled,
	&:disabled {
		background: #e6e9ed !important;
		border-color: transparent;
		color: #b3becd;

		:global {
			.p-button-label {
				color: #b3becd;
			}
		}
	}

	&:global(.p610) {
		padding: 6px 10px;
		gap: 6px;

		img,
		i {
			width: 18px;
			height: 18px;
		}

		span,
		:global(.p-button-label) {
			font-size: 12px;
		}
	}

	&:global(.p616) {
		padding: 6px 16px;
		gap: 6px;

		img,
		i {
			width: 18px;
			height: 18px;
		}

		span,
		:global(.p-button-label) {
			font-size: 12px;
		}
	}

	&:global(.p712) {
		padding: 7px 12px;
		gap: 6px;

		img,
		i {
			width: 24px;
			height: 24px;
		}

		span,
		:global(.p-button-label) {
			font-size: 14px;
		}
	}

	&:global(.lineH-24) {
		span,
		:global(.p-button-label) {
			line-height: 24px;
		}
	}
}
