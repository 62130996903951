@import "assets/scss/vars.scss";

.answer_card {
	&:not(:last-child) {
		margin-bottom: 24px;
	}

	padding: 12px;
	border: 1px solid #dce0e5;
	@include borderRadius(6px);

	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 12px;

		@media (max-width: 575.98px) {
			flex-direction: column;
			align-items: flex-start;
		}

		.answerer_info {
			flex-grow: 1;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;

			@media (max-width: 375.98px) {
				flex-direction: column;
				align-items: flex-start;
			}

			.img {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 54px;
				height: 54px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}

			.info {
				h5 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 2px;
				}

				.name {
					@include font(14px, 700, #202326);
					margin-bottom: 0;
				}
			}
		}

		.view_profile {
			flex-shrink: 0;
		}
	}

	&_body {
		padding: 12px;
		background-color: #eaf8f9;
		@include borderRadius(6px);

		.answer {
			@include font(14px, 400, #202326);
			margin-bottom: 6px;
		}

		.date {
			@include font(14px, 400, #808b9a);
		}
	}
}
