@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.input_box {
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		@include transition(0.3s);

		:global {
			.p-multiselect {
				border: 0;
				@include borderRadius(6px);
				box-shadow: none !important;
				position: relative;
			}

			.p-multiselect-label {
				padding: 12px !important;
				background-color: #fff !important;
				border: 0;
				box-shadow: none !important;
				@include borderRadius(6px);
				position: relative;
				@include font(14px, 600, #202326);

				&.p-placeholder {
					@include font(14px, 400, #808b9a);
				}
			}

			.p-multiselect-token {
				padding: 3px 6px;
				background-color: #e6e7e9;
				-webkit-margin-end: 6px;
				margin-inline-end: 6px;
				@include borderRadius(6px);
				display: inline-flex;
				align-items: center;
				justify-content: center;
				gap: 2px;

				&-label {
					@include font(10px, 600, #020e1f);
				}

				&-icon {
					font-size: 12px;
					width: 12px;
					height: 12px;
					color: #020e1f;
					margin: 0 !important;
				}
			}
		}

		&:hover {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			border-color: transparent;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
		}

		&:global(.p-invalid) {
			&,
			&:hover {
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}
	}
}

.multi_select {
	:global {
		.p-multiselect-header {
			padding: 14px 12px;
			background-color: #fff;
			border: 0;

			.p-checkbox {
				display: none;
			}

			.p-multiselect-filter-container {
				background-color: #fff;
				border: 1px solid #dce0e5;
				@include borderRadius(6px);

				.p-inputtext {
					background-color: #fff;
					border: 0;
					@include borderRadius(6px);

					#{$ltr} & {
						padding: 9px 10px 9px 38px;
					}

					#{$rtl} & {
						padding: 9px 38px 9px 10px;
					}
				}

				.p-multiselect-filter-icon {
					left: 10px;
				}

				&:hover {
					background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					border-color: transparent;
				}
			}

			.p-multiselect-close {
				display: none;
			}
		}

		.p-multiselect-items-wrapper {
			&::-webkit-scrollbar {
				width: 0.3em;
			}

			.p-multiselect-items {
				padding: 12px;

				.p-multiselect-item {
					white-space: unset;

					&:not(:last-child) {
						margin-bottom: 6px;
					}

					padding: 10px 8px;
					@include borderRadius(6px);

					.p-checkbox {
						width: 18px;
						height: 18px;

						#{$rtl} & {
							margin-right: 0;
							margin-left: 0.5rem;
						}

						&-box {
							width: 18px;
							height: 18px;
							border: 1px solid #b5bec9 !important;
							background: #fff;
							box-shadow: 0px 1px 8px 0px #020e1f0a !important;
							@include borderRadius(4px);
							padding: 2px;

							&.p-highlight {
								background: linear-gradient(
									90deg,
									#45cc9c 0%,
									#0c75e6 100%
								) !important;
								border-color: transparent !important;
								-o-border-image: linear-gradient(
									90deg,
									#45cc9c 0%,
									#0c75e6 100%
								);
								border-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
								border-image-slice: 1;
								border-image-width: 1px;
							}

							.p-checkbox-icon {
								width: 100% !important;
								height: 100% !important;
							}
						}
					}

					span {
						@include font(14px, 500, #202326);
					}

					&:hover,
					&.p-highlight {
						background-color: #f7f7f7 !important;
						box-shadow: none !important;
					}

					&.p-highlight {
						background-color: #fff !important;
					}

					&:hover {
						background-color: #f7f7f7 !important;
					}
				}
			}
		}
	}

	&.no_filter {
		:global {
			.p-multiselect-header {
				display: none;
			}
		}
	}
}
