@import "assets/scss/vars.scss";

.custom_radioBtn {
	input[type="radio"] {
		display: none;
	}

	padding: 1px;
	background-color: #fff;
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	flex-shrink: 0;

	.label_icon {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 12px;
		padding: 16px 32px;
		background-color: #fff;
		@include borderRadius(6px);

		.custom_radioBtn_icon {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
		}

		.custom_radioBtn_lbl {
			@include font(14px, 400, #202326);
		}
	}

	&.checked {
		background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);

		.label_icon {
			background-color: #eaf8f9;

			.custom_radioBtn_lbl {
				font-weight: 600;
			}
		}
	}
}
