@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.input_box {
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		@include transition(0.3s);

		:global {
			.p-dropdown {
				border: 0;
				@include borderRadius(6px);
				box-shadow: none !important;
				position: relative;
			}

			.p-inputtext {
				background-color: #fff !important;
				border: 0;
				box-shadow: none !important;
				@include borderRadius(6px);
				position: relative;
				@include font(14px, 600, #202326);
			}
		}

		&:hover {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			border-color: transparent;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
		}

		&:global(.p-invalid) {
			&,
			&:hover {
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}
	}
}

.dropdown_panel {
	width: 100%;

	:global {
		.p-dropdown-items-wrapper {
			&::-webkit-scrollbar {
				width: 0.3em;
			}

			.p-dropdown-items {
				padding: 12px;

				.p-dropdown-item {
					padding: 10px 8px;
					@include borderRadius(6px);

					&:hover {
						background-color: #f7f7f7 !important;
					}
				}
			}
		}
	}
}

.value_template {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;

	img {
		flex-shrink: 0;
		width: 100%;
		max-width: 20px;
	}

	.selected_val {
		@include font(14px, 600, #202326);
	}

	.place_holder {
		@include font(14px, 600, rgba(#808b9a, 0.7));
	}
}

.item_template {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;

	img {
		flex-shrink: 0;
		width: 100%;
		max-width: 18px;
	}

	div {
		@include font(14px, 500, #202326);
		white-space: normal;
		word-break: break-word;
	}
}
