@import "assets/scss/vars.scss";

.find_care {
	padding: 40px 0;
	background-color: #fff;

	.sec_head {
		margin-bottom: 24px;

		h1 {
			@include font(20px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.search_holder {
		background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
		@include borderRadius(12px);
		padding: 32px;

		.search_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px 16px;
			background-color: #fff;
			border: 1px solid transparent;

			@media (min-width: 1200px) and (max-width: 1400.98px) {
				width: 100%;
			}

			:global {
				.p-button-label {
					flex-grow: 0;
					@include font(14px, 700, #020e1f);
					line-height: 24px;
				}
			}
		}

		[class*="field_holder"],
		[class*="input_holder"] {
			h6 {
				color: #fff;
			}
		}
	}
}
