@import "assets/scss/vars.scss";

.pagination {
	:global {
		.p-paginator {
			padding: 0;
			background-color: transparent;
			gap: 12px;

			@media (max-width: 420.98px) {
				gap: 8px;
			}

			@media (max-width: 410.98px) {
				gap: 4px;
			}

			&-prev,
			&-next {
				margin: 0;
				height: auto;
				min-width: auto;
				border: 1px solid #dce0e5;
				background-color: #fff;
				@include borderRadius(6px);
				padding: 9px 12px;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(14px, 500, #202326);

				&.p-disabled {
					border-color: transparent;
					background-color: #eceff2;
					color: #b3becd;
				}

				@media (max-width: 410.98px) {
					padding: 9px 10px;
				}
			}

			&-pages {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;

				@media (max-width: 399.98px) {
					gap: 4px;
				}

				.p-paginator-page {
					margin: 0;
					height: auto;
					min-width: auto;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 14px;
					@include borderRadius(6px);
					border: 1px solid #dce0e5;
					background-color: #fff;
					@include font(14px, 500, #202326);
					line-height: 20px;

					&.p-highlight {
						position: relative;
						border-color: transparent;
						background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
						@include font(14px, 500, #202326);

						&::before {
							content: "";
							width: 100%;
							height: 100%;
							background-color: #eaf8f9;
							position: absolute;
						}

						span {
							z-index: 1;
							@include font(14px, 500, #202326);
						}
					}
				}
			}
		}
	}
}
