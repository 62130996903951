@import "assets/scss/vars.scss";

.filter_patients {
	flex-shrink: 0;

	&_btn {
		padding: 7px 12px;
		background-color: #fff !important;
		border: 1px solid #dce0e5 !important;
		box-shadow: 0px 4px 12px -2px #3232470f, 0px 0px 1px 0px #0c1a4b1a;
		@include borderRadius(6px);
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 4px;

		img {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
		}

		span {
			@include font(14px, 700, #020e1f);
		}
	}
}

.filter_overlay {
	min-width: 340px;
	max-width: 340px;
	margin-top: 10px;
	background-color: #fff;
	border: 0.5px solid #dce0e5;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;

	&::before,
	&::after {
		display: none;
	}

	:global {
		.p-overlaypanel-content {
			padding: 24px;
		}
	}

	.form_holder {
		display: flex;
		flex-direction: column;
	}

	.overlay_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		padding-bottom: 12px;

		h3 {
			@include font(18px, 800, #202326);
			margin-bottom: 0;
		}

		.close_btn {
			flex-shrink: 0;
			padding: 4px;
			width: 24px;
			height: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 0;
		}
	}

	.overlay_body {
		flex-grow: 1;
		padding: 12px 0;

		.inpt_lbl {
			@include font(14px, 400, #202326);
			margin-bottom: 8px;
		}

		.to {
			display: flex;
			align-items: center;
			justify-content: center;
			@include font(14px, 600, #202326);
			height: 100%;
		}
	}

	.overlay_footer {
		padding-top: 12px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
	}
}
