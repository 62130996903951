@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.otp_inputs {
		justify-content: space-between;
		direction: ltr;
		gap: 16px;

		.otp_inpt {
			width: 76px;
			height: 54px;
			padding: 1px;
			background: #dce0e5;
			@include borderRadius(8px);
			overflow: hidden;
			border: 0;
			@include transition(0.3s);

			input {
				width: 100% !important;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 0 !important;
				border-radius: 0;
				-webkit-border-radius: 0;
				-moz-border-radius: 0;
				-ms-border-radius: 0;
				-o-border-radius: 0;
				background-color: #fff;
				@include font(20px, 500, #202326);
				outline: 0 none !important;
				@include borderRadius(8px);
				@include transition(0.3s);
			}

			&:focus-within {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			}

			&:hover {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			}
		}

		&_error {
			input {
				border-bottom-color: #dc3545;

				&:hover,
				&:focus-visible {
					border-color: #dc3545 !important;
				}
			}
		}
	}
}
