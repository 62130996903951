@import "assets/scss/vars.scss";

.doctor_info {
	padding-bottom: 50px;

	.book_appointment {
		padding: 24px 20px;
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(8px);
		margin-bottom: 16px;

		h3 {
			@include font(16px, 800, #202326);
			text-align: center;
			margin-bottom: 16px;
		}

		.selection_btns {
			margin-bottom: 16px;

			:global {
				.p-selectbutton {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 11px;

					.p-button {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 10px 30px;
						width: 100%;
						@include borderRadius(40px);
						border: 1px solid #dce0e5;
						background-color: #fff;
						gap: 8px;
						box-shadow: none !important;

						img {
							flex-shrink: 0;
							width: 100%;
							max-width: 18px;
						}

						.btn_lbl {
							@include font(14px, 500, #202326);
						}

						&.p-highlight {
							background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);

							img {
								filter: invert(100%) sepia(90%) saturate(7463%)
									hue-rotate(190deg) brightness(119%) contrast(115%);
								-webkit-filter: invert(100%) sepia(90%) saturate(7463%)
									hue-rotate(190deg) brightness(119%) contrast(115%);
							}

							.btn_lbl {
								@include font(14px, 600, #fff);
							}
						}
					}
				}
			}
		}

		.examiniation_fees {
			@include font(14px, 400, #808b9a);
			text-align: center;

			span {
				@include font(14px, 600, #202326);
			}
		}
	}

	.appointments {
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(8px);
		overflow: hidden;
		margin-bottom: 16px;

		:global {
			.rts___tabs___container {
				padding: 0;

				.rts___tabs {
					padding: 0;

					.header_btn {
						flex-shrink: 0;
						background-color: #fff;
						border: 0;
						padding: 22px 32px 10px;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 2px;
						position: relative;
						box-shadow: none;
						@include borderRadius(0);
						margin: 0;

						&::before {
							content: "";
							width: 35px;
							height: 3px;
							background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
							position: absolute;
							bottom: 0;
							display: block;
							left: 50%;
							@include transform(translateX(-50%));
							opacity: 0;
							visibility: hidden;
							@include transition(0.3s);
						}

						.day {
							@include font(14px, 600, #202326);
							line-height: 20px;
						}
						.date {
							@include font(12px, 400, #808b9a);
							line-height: 20px;
						}

						&.rts___tab___selected {
							&::before {
								opacity: 1;
								visibility: visible;
							}
						}
					}
				}

				.rts___nav___btn___container {
					display: flex !important;

					.rts___btn {
						width: 30px;
						color: #020e1f !important;
						border: 0;
						background-color: transparent !important;
						@include borderRadius(0);

						svg {
							stroke: #020e1f !important;
							fill: #020e1f !important;

							path {
								fill: #020e1f !important;
							}
						}

						&[disabled] {
							color: #b5bec9 !important;

							svg {
								stroke: #b5bec9 !important;
								fill: #b5bec9 !important;

								path {
									fill: #b5bec9 !important;
								}
							}
						}
					}
				}

				&-panels {
					padding: 0;
				}
			}
		}

		.times {
			padding: 24px;
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 16px;

			@media (max-width: 575.98px) {
				gap: 8px 5px;
			}

			@media (max-width: 400.98px) {
				grid-template-columns: repeat(2, 1fr);
			}

			@media (max-width: 300.98px) {
				padding: 24px 12px;
			}

			.time_box {
				input[type="radio"] {
					display: none;
				}

				overflow: hidden;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;
				min-width: 110px;
				min-height: 44px;
				cursor: pointer;
				margin: 0 auto;

				@media (max-width: 400.98px) {
					min-width: 100%;
				}

				.lbl_content {
					display: block;
					@include borderRadius(6px);
					padding: 1px;
					background-color: #fff;

					.lbl_txt {
						display: flex;
						align-items: center;
						justify-content: center;
						padding: 12px 23px;
						background-color: #fff;
						border: 1px solid #e6e9ed;
						@include borderRadius(6px);
						@include font(14px, 500, #202326);
						white-space: nowrap;
					}
				}

				&.not_available {
					cursor: not-allowed;

					.lbl_content {
						.lbl_txt {
							@include font(14px, 400, #b3becd);
							text-decoration: line-through;
						}
					}
				}

				input[type="radio"]:checked {
					& + .lbl_content {
						background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);

						.lbl_txt {
							background-color: #eaf8f9;
							@include font(14px, 600, #202326);
						}
					}
				}
			}
		}

		.book_now {
			padding: 0 16px 24px;

			[class*="gradient_btn"] {
				font-size: 14px;
				padding: 7px 22px;

				span {
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}
}
