@import "assets/scss/vars.scss";

.appointment_card {
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	border: 1px solid #d4f0f3;
	@include borderRadius(6px);
	display: flex;

	@media (max-width: 767.98px) {
		flex-direction: column;
	}

	.dateTime_box {
		flex-shrink: 0;
		padding: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		-webkit-border-end: 1px solid #d4f0f3;
		border-inline-end: 1px solid #d4f0f3;

		@media (max-width: 767.98px) {
			-webkit-border-end: 0;
			border-inline-end: 0;
			border-bottom: 1px solid #d4f0f3;
		}

		img {
			width: 20px;
			height: 20px;
			margin-bottom: 6px;
		}

		.date {
			@include font(14px, 700, #202326);
			line-height: 20px;
			margin-bottom: 2px;
		}

		.time {
			@include font(14px, 400, #808b9a);
			line-height: 20px;
		}
	}

	.right {
		flex-grow: 1;

		.status {
			padding: 16px 20px;
			border-bottom: 1px solid #d4f0f3;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 6px;

			img {
				flex-shrink: 0;
				width: 20px;
				height: 20px;
			}

			.txt {
				@include font(14px, 600, transparent);
			}

			&.waiting_for {
				.txt {
					@extend %gradientText;
				}
			}

			&.completed {
				.txt {
					color: #269e73;
				}
			}

			&,
			cancelled {
				.txt {
					color: #b02a37;
				}
			}
		}

		.doctor_info {
			padding: 20px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 20px;

			@media (max-width: 767.98px) {
				flex-direction: column;
			}

			.doctor_img {
				flex-shrink: 0;
				width: 90px;
				height: 100px;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}

			.doctor_data {
				flex-grow: 1;

				.name_desc {
					margin-bottom: 6px;

					.name {
						@include font(14px, 700, #202326);
						margin-bottom: 2px;
						line-height: 20px;
					}

					.desc {
						@include font(14px, 400, #808b9a);
						line-height: 20px;
					}
				}

				.location {
					.where {
						@include font(14px, 400, #808b9a);
						line-height: 20px;
						margin-bottom: 2px;
					}

					p {
						@include font(14px, 400, #202326);
						margin-bottom: 0;
					}
				}

				.card_actions {
					margin-top: 22px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;

					@media (max-width: 575.98px) {
						flex-wrap: wrap;
					}
				}

				.add_feedback {
					margin-top: 16px;
					padding-top: 16px;
					border-top: 1px solid #dce0e5;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 8px;

					@media (max-width: 575.98px) {
						flex-wrap: wrap;
					}

					&_btn {
						flex-shrink: 0;
						padding: 0;
						background-color: transparent !important;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 24px;

						img {
							height: 100%;
						}
					}

					&_txt {
						@include font(14px, 400, #808b9a);
					}
				}
			}
		}
	}
}

.video_call_container {
	display: flex;
	align-items: center;
	gap: 10px;
}

.video_call_text {
	margin: 0;
}

.join_button {
	width: auto;
	padding: 6px 12px;
	font-size: 0.875rem;
}
