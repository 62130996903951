@import "assets/scss/vars.scss";

.settings_practiceStaff {
	&_holder {
		margin-bottom: 32px;
	}

	.table_holder {
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);

		:global {
			.p-datatable-table {
				.p-datatable-thead {
					tr {
						th {
							&:first-child {
								#{$ltr} & {
									border-top-left-radius: 6px;
								}
								#{$rtl} & {
									border-top-right-radius: 6px;
								}
							}

							&:last-child {
								#{$ltr} & {
									border-top-right-radius: 6px;
								}
								#{$rtl} & {
									border-top-left-radius: 6px;
								}
							}

							padding: 12px;
							background-color: #f8f9fc;
							@include font(14px, 700, #808b9a);
						}
					}
				}

				.p-datatable-tbody {
					tr {
						td {
							&:first-child {
								#{$ltr} & {
									border-bottom-left-radius: 6px;
								}
								#{$rtl} & {
									border-bottom-right-radius: 6px;
								}
							}

							&:last-child {
								#{$ltr} & {
									border-bottom-right-radius: 6px;
								}
								#{$rtl} & {
									border-bottom-left-radius: 6px;
								}
							}

							padding: 8px 12px;
							@include font(14px, 600, #202326);
						}
					}
				}
			}
		}

		.doctor_info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;

			.doctor_img {
				flex-shrink: 0;
				width: 44px;
				height: 44px;
				@include borderRadius(100%);
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}

			.data {
				h5 {
					@include font(14px, 600, #202326);
					margin-bottom: 0;
				}

				p {
					@include font(14px, 500, #808b9a);
					margin-bottom: 0;
				}
			}
		}

		.table_action {
			.action_btn {
				width: 30px;
				height: 30px;
				padding: 6px;
				font-size: 18px;
				background-color: #fff;
				border: 0;
				color: #020e1f;

				:global(.p-button-icon) {
					font-size: 18px;
				}
			}
		}
	}
}

.action_menu {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 0.5px solid #dce0e5 !important;
	@include borderRadius(8px);
	padding: 12px;
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				.p-menuitem-link {
					padding: 10px 8px;
					@include borderRadius(4px);
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					width: 100%;
					background-color: #fff !important;
					border: 0 !important;

					img {
						flex-shrink: 0;
						width: 18px;
						height: 18px;
					}

					span {
						@include font(14px, 500, #202326);
						text-transform: capitalize;
					}

					&:hover {
						background-color: #f3f4f6 !important;
					}

					&.status_item {
						justify-content: space-between;
						gap: 10px;

						.left {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 12px;

							.bullet {
								flex-shrink: 0;
								width: 18px;
								height: 18px;
								display: flex;
								align-items: center;
								justify-content: center;

								&_small {
									display: flex;
									align-items: center;
									justify-content: center;
									width: 12px;
									height: 12px;
									@include borderRadius(100%);
								}
							}

							.text {
								@include font(14px, 500, #202326);
							}
						}

						.status_box {
							flex-shrink: 0;
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 3px 6px;
							@include borderRadius(26px);
							@include font(10px, 600, #fff);
							text-transform: capitalize;
						}

						&.active {
							.left {
								.bullet {
									&_small {
										background-color: #48bb78;
									}
								}
							}

							.status_box {
								background-color: #48bb78;
							}
						}

						&.inactive {
							.left {
								.bullet {
									&_small {
										background-color: #8392a5;
									}
								}
							}

							.status_box {
								background-color: #8392a5;
							}
						}
					}
				}
			}
		}
	}
}

.action_menu_status {
	top: 40px !important;
	#{$ltr} & {
		left: auto !important;
		right: 100% !important;
	}
	#{$rtl} & {
		left: 100% !important;
		right: auto !important;
	}

	.status_row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;

		[class*="radio_btn"] {
			flex-grow: 1;
		}

		.status_bullet {
			flex-shrink: 0;
			width: 12px;
			height: 12px;
			display: flex;
			align-items: center;
			justify-content: center;
			@include borderRadius(100%);

			&.active {
				background-color: #48bb78;
			}

			&.inactive {
				background-color: #b5bec9;
			}
		}
	}
}
