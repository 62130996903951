@import "assets/scss/vars.scss";

.hero_section {
	background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
	padding-top: 96px;

	.hero_txt {
		padding: 50px 0 165px;
		max-width: 70%;

		@media (max-width: 1199.98px) {
			max-width: 100%;
		}

		h1 {
			@include font(40px, 900, #fff);
			margin-bottom: 16px;
		}

		.line_divider {
			width: 58px;
			height: 5px;
			background-color: #fff;
			margin-bottom: 16px;
		}

		p {
			@include font(16px, 400, #fff);
			margin-bottom: 0;
		}
	}

	.hero_img {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		height: 100%;

		img {
			width: 100%;
		}
	}
}
