@import "assets/scss/vars.scss";

.feel_better {
	.slider_box {
		background: linear-gradient(
				90deg,
				rgba(69, 204, 156, 0.4),
				#45cc9c 0%,
				rgba(12, 117, 230, 0.4),
				#0c75e6 100%
			),
			linear-gradient(
				to right bottom,
				rgba(0, 0, 0, 0.4) 0%,
				rgba(0, 0, 0, 0.4) 50%,
				rgba(0, 0, 0, 0.3) 50%,
				rgba(0, 0, 0, 0.3) 100%
			);
		@include borderRadius(16px);
		overflow: hidden;
		padding: 20px 80px 0;

		@media (max-width: 575.98px) {
			padding: 20px 40px 0;
		}

		.txt_side {
			padding: 80px 0;

			@media (max-width: 575.98px) {
				padding: 40px 0;
			}

			.title {
				@include font(40px, 800, #fff);
				margin-bottom: 16px;

				@media (max-width: 575.98px) {
					font-size: 20px;
				}
			}

			.desc {
				@include font(14px, 400, #fff);
				margin-bottom: 16px;
			}
		}

		.slider_img {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			height: 100%;

			img {
				width: 100%;
				object-fit: cover;
			}
		}
	}

	:global {
		.swiper-custom-pagination {
			margin-top: 24px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 10px;
		}
	}
}
