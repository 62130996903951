@import "assets/scss/vars.scss";

.event_popover {
	padding: 20px 16px;
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	border: 0.5px solid #dce0e5;
	@include borderRadius(8px);
	z-index: 99;
	width: 22%;
	max-width: 90%;

	&_header {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;
		padding-bottom: 16px;
		border-bottom: 1px solid #e5e7eb;

		img {
			flex-shrink: 0;
			width: 100%;
			max-width: 20px;
		}

		p {
			@include font(14px, 700, #48bb78);
			margin-bottom: 0;
		}
	}

	&_body {
		padding: 16px 0;

		.body_row {
			&:not(:last-child) {
				border-bottom: 1px solid #e5e7eb;
				padding-bottom: 16px;
				margin-bottom: 16px;
			}

			.doctor_info {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 10px;
				margin-bottom: 16px;

				.img {
					flex-shrink: 0;
					width: 44px;
					height: 44px;
					@include borderRadius(100%);
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						-o-object-fit: cover;
						object-fit: cover;
					}
				}

				.info {
					h3 {
						@include font(16px, 800, #202326);
						margin-bottom: 2px;
					}

					.view_profile {
						@include font(14px, 700, transparent);
						@extend %gradientText;
					}
				}
			}

			.info_row {
				&:not(:last-child) {
					margin-bottom: 16px;
				}

				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 20px;
				}

				span {
					@include font(14px, 700, #202326);
				}
			}

			&.booking_details {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 20px;
				}

				.details {
					h3 {
						@include font(14px, 700, #202326);
						margin-bottom: 2px;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&_footer {
		padding-top: 16px;
		border-top: 1px solid #e5e7eb;
		display: grid;
		gap: 8px;
	}
}
