@import "assets/scss/vars.scss";

.error_ui {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 6px;

	img {
		flex-shrink: 0;
		width: 100%;
		max-width: 16px;
	}

	span {
		@include font(12px, 500, #dc3545);
	}

	&.withBG {
		padding: 10px 12px;
		background-color: #fbebec;
		gap: 12px;
		@include borderRadius(8px);

		img {
			max-width: 24px;
		}

		span {
			@include font(14px, 600, #202326);
		}
	}
}
