@import "assets/scss/vars.scss";

.personal_details {
	.head {
		margin-bottom: 30px;

		h2 {
			@include font(24px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.profile_photo {
		margin-bottom: 30px;

		h6 {
			@include font(14px, 400, #202326);
			margin-bottom: 30px;
			text-transform: capitalize;
		}

		.upload_img {
			input[type="file"] {
				display: none;
			}

			cursor: pointer;

			.img_holder {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 96px;
				height: 96px;
				@include borderRadius(100%);
				border: 1px solid #dce0e5;
				overflow: hidden;

				.uploadIcon {
					width: 20px;
					height: 20px;
				}

				.uploadedImg {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}
		}
	}

	.form_holder {
		.inputs_wrapper {
			margin-bottom: 30px;

			.hint {
				@include font(12px, 500, #808b9a);
				margin-top: 8px;
			}
		}
	}
}
