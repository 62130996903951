@import "assets/scss/vars.scss";

.doctor_messages {
	display: flex;
	min-height: 100%;

	.msg_list {
		width: 360px;
		flex-shrink: 0;
		background-color: #fff;
		-webkit-border-end: 1px solid #dce0e5;
		border-inline-end: 1px solid #dce0e5;
		height: calc(100vh - 65px);
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		display: flex;
		flex-direction: column;

		&_head {
			padding: 32px 24px 8px;

			h1 {
				@include font(24px, 800, #202326);
				margin-bottom: 16px;
			}
		}

		&_body {
			padding: 8px 24px 32px;
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;

			.patient_btn {
				&:not(:last-child) {
					margin-bottom: 6px;
				}

				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;
				text-align: start;
				padding: 10px 8px;
				@include borderRadius(6px);
				background-color: #fff;
				width: 100%;

				.patient_data {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;

					.patient_img {
						flex-shrink: 0;
						width: 44px;
						height: 44px;
						@include borderRadius(100%);
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
						}
					}

					.info {
						h4 {
							@include font(14px, 600, #202326);
							margin-bottom: 2px;
							@include transition(0.3s);
						}

						h5 {
							@include font(14px, 400, #808b9a);
							margin-bottom: 0;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: 198px;
						}
					}
				}

				.msg_count {
					flex-shrink: 0;
					min-width: 24px;
					min-height: 24px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					@include borderRadius(100%);
					padding: 2px 8px;
					background-color: #48bb78;
					@include font(12px, 600, #fff);
				}

				&.active {
					background-color: #eaf8f9;

					.patient_data {
						.info {
							h4 {
								font-weight: 700;
							}
						}
					}
				}
			}
		}
	}

	.msg_chat {
		flex-grow: 1;
		height: calc(100vh - 65px);

		.chat_holder {
			height: 100%;
			display: flex;
			flex-direction: column;

			.chat_head {
				padding: 32px;
				background-color: #f3f4f6;
				border-bottom: 1px solid #dce0e5;
				box-shadow: 0px 1px 8px 0px #020e1f0a;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				.patient_info {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 10px;

					.img {
						flex-shrink: 0;
						width: 44px;
						height: 44px;
						@include borderRadius(100%);
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
						}
					}

					.patient_data {
						h5 {
							@include font(14px, 700, #202326);
							margin-bottom: 2px;
						}

						p {
							@include font(14px, 400, #808b9a);
							margin-bottom: 0;
						}
					}
				}
			}

			.chat_body {
				flex-grow: 1;
				overflow-y: auto;
				overflow-x: hidden;
				padding: 12px 32px;

				.date {
					margin-bottom: 12px;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					padding: 8px;
					@include font(14px, 400, #808b9a);
				}

				.chat_boxes {
					&:not(:last-child) {
						margin-bottom: 12px;
					}

					.chat_box {
						&:not(:last-child) {
							margin-bottom: 12px;
						}

						display: flex;
						align-items: flex-end;
						justify-content: flex-start;
						gap: 12px;

						.img {
							flex-shrink: 0;
							width: 32px;
							height: 32px;
							@include borderRadius(100px);
							overflow: hidden;

							img {
								width: 100%;
								height: 100%;
								-o-object-fit: cover;
								object-fit: cover;
							}
						}

						.chat_bubble {
							--r: 25px; /* the radius */
							--t: 16px; /* the size of the tail */

							padding: 16px;
							@include borderRadius(8px);
							padding: calc(2 * var(--r) / 3);
							@extend %chatMask;

							.text {
								@include font(14px, 500, #202326);
								margin-bottom: 6px;
								text-align: start;
							}

							.time {
								@include font(14px, 400, #808b9a);
								text-align: end;
							}

							&.left {
								background-color: #e6e9ed;

								#{$ltr} & {
									--_d: 0%;
									border-left: var(--t) solid #0000;
									place-self: start;
								}

								#{$rtl} & {
									--_d: 100%;
									border-right: var(--t) solid #0000;
									place-self: end;
								}
							}

							&.right {
								background-color: #d4f0f3;

								#{$ltr} & {
									--_d: 100%;
									border-right: var(--t) solid #0000;
									place-self: end;
								}

								#{$rtl} & {
									--_d: 0%;
									border-left: var(--t) solid #0000;
									place-self: start;
								}

								.text {
									text-align: end;
								}
							}
						}

						&.sender {
							justify-content: flex-end;
						}
					}
				}
			}

			.chat_footer {
				padding: 32px;
				background-color: #fff;
				border-top: 1px solid #dce0e5;
				box-shadow: 0px 1px 8px 0px #020e1f0a;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				[class*="input_holder"] {
					width: 100%;
				}

				[class*="input_box"] {
					height: 46px;
				}

				.send_btn {
					flex-shrink: 0;
				}
			}
		}

		.no_chat {
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			@include font(16px, 600, #202326);
		}
	}
}
