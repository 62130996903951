@import "assets/scss/vars.scss";

.question_answer {
	[class*="page_head"] {
		border-bottom: 1px solid #dce0e5;
		padding-bottom: 24px;
	}

	.view_by {
		display: flex;
		align-items: baseline;
		justify-content: flex-start;
		gap: 20px;
		margin-bottom: 24px;

		@media (max-width: 375.98px) {
			flex-direction: column;
		}

		h5 {
			@include font(14px, 700, #202326);
			margin-bottom: 0;
			flex-shrink: 0;
		}

		.view_by_filter {
			flex-grow: 1;
		}

		[class*="multi_select"] {
			width: 100%;
		}
	}

	.qtys {
		h2 {
			@include font(14px, 400, #808b9a);
			margin-bottom: 24px;
		}
	}
}
