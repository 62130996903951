@import "assets/scss/vars.scss";

.medical_interpreter {
	margin-bottom: 24px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 24px;
	}

	&_content {
		padding: 24px;
		background-color: #fff;
		@include borderRadius(8px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: 575.98px) {
			flex-direction: column;
		}

		.left {
			[class*="switch_input"] {
				[class*="switch_label"] {
					font-weight: 700;
				}
			}
		}

		.right {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;

			.to {
				@include font(14px, 400, #202326);
				flex-shrink: 0;
			}

			:global {
				.input_holder {
					flex-basis: 140px;
				}
			}

			.cost {
				@include font(14px, 700, #202326);
				flex-shrink: 0;
			}
		}
	}
}
