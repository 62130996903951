@import "assets/scss/vars.scss";

.not_answered {
	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 32px;

		.qty_count {
			@include font(14px, 400, #808b9a);
		}

		.sort_by {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;

			.sort_lbl {
				@include font(14px, 500, #202326);
			}
		}
	}

	&_qtys {
		.qty_box {
			&:not(:last-child) {
				margin-bottom: 16px;
			}

			padding: 20px;
			border: 1px solid #dce0e5;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			@include borderRadius(6px);
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 12px;

			@media (max-width: 991.98px) {
				flex-direction: column;
				align-items: flex-start;
			}

			.txt {
				h5 {
					@include font(14px, 700, transparent);
					@extend %gradientText;
					margin-bottom: 6px;
				}

				.qty_date {
					margin-bottom: 16px;

					h3 {
						@include font(16px, 800, #202326);
						margin-bottom: 2px;
					}

					h6 {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}

				p {
					@include font(14px, 400, #202326);
					margin-bottom: 0;
				}
			}
		}
	}
}
