@import "assets/scss/vars.scss";

.white_black_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	padding: 10px 12px;
	@include borderRadius(6px);
	background-color: #fff !important;
	@include font(14px, 700, #020e1f);
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	border: 1px solid transparent !important;
	@include transition(0.3s);
	position: relative;

	:global {
		.p-button-label {
			@include font(14px, 700, #020e1f);
			flex-grow: initial;
		}
	}

	&,
	span {
		@include font(14px, 700, #020e1f);
	}

	span {
		z-index: 1;
	}

	&:hover,
	&:active {
		box-shadow: none;
		background-color: #fff !important;
	}

	img,
	i {
		flex-shrink: 0;
		width: 20px;
		height: 20px;
		position: relative;
	}

	&.outlinedBtn {
		background-color: #fff !important;
		border-color: #dce0e5 !important;

		&,
		span {
			@include font(14px, 700, #020e1f);
		}

		:global {
			.p-button-label {
				@include font(14px, 700, #020e1f);
			}
		}

		&:hover,
		&:active {
			box-shadow: none;
			background-color: #f8f9fc !important;
		}
	}

	&.rightPos {
		img,
		i {
			order: 1;
		}
	}

	&.leftPos {
		img,
		i {
			order: -1;
		}
	}

	&:global(.p610) {
		padding: 6px 10px;
		gap: 6px;

		img,
		i {
			width: 18px;
			height: 18px;
		}

		span,
		:global(.p-button-label) {
			font-size: 12px;
		}
	}

	&:global(.p616) {
		padding: 6px 16px;
		gap: 6px;

		img,
		i {
			width: 18px;
			height: 18px;
		}

		span,
		:global(.p-button-label) {
			font-size: 12px;
		}
	}

	&:global(.p712) {
		padding: 7px 12px;
		gap: 6px;

		img,
		i {
			width: 24px;
			height: 24px;
		}

		span,
		:global(.p-button-label) {
			font-size: 14px;
		}
	}

	&:global(.boxShadow) {
		box-shadow: 0px 4px 12px -2px #3232470f, 0px 0px 1px 0px #0c1a4b1a !important;
	}

	&:hover:global(.boxShadow) {
		box-shadow: 0px 4px 12px -2px #3232470f, 0px 0px 1px 0px #0c1a4b1a !important;
		background-color: #f8f9fc !important;
	}

	&:active:global(.boxShadow) {
		box-shadow: 0px 4px 12px -2px #3232470f, 0px 0px 1px 0px #0c1a4b1a !important;
	}

	&:focus:global(.boxShadow) {
		box-shadow: 0px 4px 12px -2px #3232470f, 0px 0px 1px 0px #0c1a4b1a !important;
		background-color: #f8f9fc !important;
	}
}
