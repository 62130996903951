@import "assets/scss/vars.scss";

.summary {
	margin-bottom: 32px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 32px;
	}

	.summary_holder {
		padding: 20px 24px;
		background-color: #fff;
		border: 0.7px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;

		@media (max-width: 600.98px) {
			flex-wrap: wrap;
		}

		.summary_boxes {
			flex-grow: 1;

			.summary_box {
				h5 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 2px;
				}

				h3 {
					@include font(20px, 800, #202326);
					margin-bottom: 0;
				}
			}
		}
	}
}
