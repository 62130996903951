@import "assets/scss/vars.scss";

.chart_holder {
	padding: 24px;
	background-color: #fff;
	border: 1px solid #dce0e5;
	border-top: 0;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	margin-bottom: 32px;

	.chart_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 16px;
		margin-bottom: 24px;

		@media (max-width: 991.98px) {
			flex-wrap: wrap;
		}

		.text {
			h2 {
				@include font(16px, 800, #202326);
				margin-bottom: 6px;
			}

			.statistics {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;

				img {
					flex-shrink: 0;
					width: 20px;
					height: 20px;
				}

				span {
					&.green {
						@include font(18px, 800, #48bb78);
					}

					&.gray {
						@include font(14px, 400, #808b9a);
					}
				}
			}
		}

		.right {
			display: flex;
			justify-content: flex-start;
			gap: 16px;

			@media (max-width: 600.98px) {
				flex-wrap: wrap;
			}

			.service_type {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 16px;

				.tit {
					@include font(14px, 400, #202326);
				}

				:global {
					.input_holder {
						[class*="input_box"] {
							min-width: 150px;
						}
					}
				}
			}

			.duration_selection {
				:global {
					.p-selectbutton {
						padding: 2px;
						background-color: #f3f4f6;
						@include borderRadius(6px);
						display: flex;
						height: 100%;

						.p-button {
							padding: 5px 10px;
							background-color: transparent;
							border: 1px solid transparent;
							box-shadow: none !important;
							display: flex;
							align-items: center;
							justify-content: center;
							@include font(14px, 700, #676e79);
							@include borderRadius(6px);

							&.p-highlight {
								background-color: #fff;
								border: 1px solid #dce0e5;
								box-shadow: 0px 4px 12px -2px #3232470f,
									0px 0px 1px 0px #0c1a4b1a !important;
								color: #020e1f;
							}
						}
					}
				}
			}
		}
	}

	.chart_wrapper {
		:global {
			.apexcharts-tooltip {
				padding: 10px 16px;
				background-color: #020e1f;
				@include borderRadius(8px);

				span {
					&:first-child {
						@include font(12px, 500, #b3becd);
					}

					&:last-child {
						@include font(16px, 800, #fff);
					}
				}
			}
		}
	}
}
