@import "assets/scss/vars.scss";

.reminders {
	.reminders_medications_tabs {
		:global {
			.p-tabview {
				&-nav-container {
					.p-tabview-nav-content {
						.p-tabview-nav {
							border-color: #dce0e5;
							border-width: 0 0 1px 0;

							.p-unselectable-text {
								.tab_btn {
									display: flex;
									align-items: center;
									justify-content: center;
									gap: 8px;
									padding: 12px;
									margin: 0;
									border: 0;

									img {
										flex-shrink: 0;
										width: 18px;
									}

									.p-tabview-title {
										@include font(14px, 500, #202326);
										text-transform: capitalize;
									}
								}

								&:not(.p-tabview-selected) {
									&:not(.p-highlight) {
										img {
											&.black {
												display: block;
											}

											&.gradient {
												display: none;
											}
										}
									}
								}

								&.p-tabview-selected {
									&.p-highlight {
										img {
											&.black {
												display: none;
											}

											&.gradient {
												display: block;
											}
										}

										.p-tabview-title {
											font-weight: 700;
										}
									}
								}
							}

							.p-tabview-ink-bar {
								height: 3px;
								@include borderRadius(8px);
								background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
							}
						}
					}
				}

				&-panels {
					padding: 24px 0 0 0;
				}
			}
		}
	}
}
