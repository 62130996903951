@import "assets/scss/vars.scss";

.my_profile {
	padding: 32px 0 100px;
	flex-grow: 1;
	background-color: #f8f9fc;

	h1 {
		@include font(18px, 800, #202326);
		margin-bottom: 32px;
	}

	.outlet_wrapper {
		padding: 24px;
		background-color: #fff;
		@include borderRadius(8px);
	}
}
