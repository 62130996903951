@import "assets/scss/vars.scss";

.practice_staff {
	&_content {
		padding: 24px 24px 40px;

		.sec_holder {
			&:not(:last-child) {
				padding-bottom: 24px;
				margin-bottom: 24px;
				border-bottom: 1px solid #e5e7eb;
			}
		}

		.title {
			margin-bottom: 24px;

			h3 {
				@include font(16px, 800, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.doctors_table {
			margin-bottom: 24px;

			.doctor_info {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;

				.img {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 44px;
					height: 44px;
					@include borderRadius(100%);
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						-o-object-fit: cover;
						object-fit: cover;
					}
				}

				.info {
					h5 {
						@include font(14px, 600, #202326);
						margin-bottom: 2px;
					}

					h6 {
						@include font(14px, 500, #808b9a);
						margin-bottom: 0;
					}
				}
			}

			.table_action {
				padding: 6px;
				width: 30px;
				height: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent !important;
				border: 0 !important;

				img {
					width: 18px;
					height: 18px;
				}
			}
		}
	}

	.footer_actions {
		padding: 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-top: 1px solid #dce0e5;
	}
}
