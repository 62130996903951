@import "assets/scss/vars.scss";

.addRecord_head {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;

	.img {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 44px;
		height: 44px;
		@include borderRadius(100%);
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.info {
		h3 {
			@include font(16px, 800, #202326);
			margin-bottom: 0;
		}

		.view_profile {
			@include font(14px, 700, transparent);
			@extend %gradientText;
		}
	}
}

.doctor_appointment {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 16px;
	padding: 24px;
	border-bottom: 1px solid #dce0e5;

	.left {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 16px;

		.date_box {
			flex-shrink: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 6px 14px;
			@include borderRadius(6px);
			background-color: #8392a5;

			span {
				&:first-child {
					@include font(20px, 800, #fff);
				}

				&:last-child {
					@include font(14px, 400, #fff);
				}
			}
		}

		.info {
			h3 {
				@include font(16px, 800, #202326);
				margin-bottom: 0;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}
	}

	.addRecord_dropdown {
		flex-shrink: 0;

		.menu_btn {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			padding: 1px;
			@include borderRadius(4px);
			border: 0;

			.btn_content {
				background-color: #fff;
				padding: 6px 10px;
				@include borderRadius(4px);
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 4px;

				span {
					background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					-moz-background-clip: text;
					-moz-text-fill-color: transparent;
					@include font(12px, 700, transparent);
				}
			}

			&:hover,
			&:focus {
				.btn_content {
					background-color: #eaf8f9;
				}
			}
		}
	}
}

.record_menu {
	padding: 14px 12px;
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	border: 0.5px solid #dce0e5;
	@include borderRadius(8px);
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				&-link {
					padding: 10px 8px;
					@include font(14px, 500, #202326);
					box-shadow: none !important;

					&:hover {
						background-color: #f7f7f7;
					}

					.p-menuitem-text {
						span {
							&.added {
								color: #808b9a;
							}
						}
					}
				}
			}
		}
	}
}

.records_holder {
	:global {
		.p-accordion {
			&-tab {
				margin-bottom: 0;

				.p-accordion-header {
					&-link {
						box-shadow: none !important;
						padding: 16px 24px;
						gap: 16px;
						border-bottom: 1px solid #dce0e5;
						@include borderRadius(0);
						border-top: 0;
						background-color: #f8f9fc !important;
						@include transition(0.3s);

						.p-accordion-toggle-icon {
							order: 1;
							flex-shrink: 0;
							margin: 0;
							width: 18px;
							height: 18px;
							@include transform(rotate(90deg));
							@include transition(0.3s);

							path {
								fill: #8392a5;
							}
						}
					}

					&-text {
						width: 100%;

						.head_content {
							width: 100%;
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 16px;

							.accordion_label {
								@include font(14px, 700, #202326);
							}

							.accordion_actions {
								flex-shrink: 0;
								display: flex;
								align-items: center;
								justify-content: flex-end;
								gap: 16px;

								.p-button {
									flex-shrink: 0;
									padding: 1px;
									display: flex;
									align-items: center;
									justify-content: center;

									img {
										width: 18px;
										height: 18px;
									}
								}
							}
						}
					}
				}

				.p-accordion-content {
					padding: 16px 24px;
					@include borderRadius(0);
					border: 0;
					border-bottom: 1px solid #dce0e5;
				}

				&:last-child {
					.p-accordion-header {
						&-link {
							border-bottom: 0;
						}
					}
				}

				&-active {
					.p-accordion-header {
						&-link {
							border-bottom: 0;

							.p-accordion-toggle-icon {
								@include transform(rotate(180deg));
							}
						}
					}
				}
			}
		}
	}
}
