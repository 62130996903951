@import "assets/scss/vars.scss";

.my_invoices {
	.overview_sec {
		margin-bottom: 32px;

		.sec_title {
			@include font(16px, 800, #202326);
			margin-bottom: 32px;
		}

		.overview_box {
			padding: 20px 24px;
			background-color: #fff;
			border: 0.7px solid #dce0e5;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			@include borderRadius(6px);
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 12px;

			.text {
				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}

				h4 {
					@include font(20px, 800, transparent);

					&.black {
						color: #202326;
					}

					&.yellow {
						color: #cc9a06;
					}

					&.green {
						color: #48bb78;
					}
				}
			}

			.info {
				position: relative;

				.info_btn {
					background-color: transparent !important;
					padding: 0;
					border: 0;
					width: 18px;
					height: 18px;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

.tooltip_holder {
	width: 300px;
	max-width: 100%;

	:global {
		.p-tooltip-arrow {
			border-bottom-color: #020e1f;
		}

		.p-tooltip-text {
			background-color: #020e1f;
			padding: 6px;
			box-shadow: none;
			@include borderRadius(6px);

			h5 {
				@include font(14px, 600, #fff);
				margin-bottom: 2px;
				line-height: 20px;
			}

			p {
				@include font(14px, 400, #fff);
				margin-bottom: 0;
				line-height: 20px;
			}
		}
	}
}
