@import "assets/scss/vars.scss";

.check_your_symptoms {
	background-color: #f8f9fc;

	&_head {
		padding: 40px 0;
		background-color: #fff;

		h1 {
			@include font(20px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	&_body {
		padding: 50px 0;

		.sidebar_steps {
			padding: 24px;
			background-color: #fff;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			@include borderRadius(8px);

			.steps_rows {
				-webkit-border-start: 1px solid #dce0e5;
				border-inline-start: 1px solid #dce0e5;

				.step_row {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 16px;
					padding: 12px 18px;
					background-color: #fff;
					@include transition(0.3s);
					position: relative;

					span {
						@include font(16px, 500, #808b9a);
						text-transform: capitalize;
					}

					img {
						flex-shrink: 0;
						width: 20px;
						height: 20px;
						display: none;
					}

					&::before {
						content: "";
						position: absolute;
						width: 3px;
						height: 100%;
						background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
						top: 0;
						#{$ltr} & {
							left: 0;
						}
						#{$rtl} & {
							right: 0;
						}
						opacity: 0;
						visibility: hidden;
						@include transition(0.3s);
					}

					&.active_steps {
						&::before {
							opacity: 1;
							visibility: visible;
						}

						span {
							@include font(16px, 600, #202326);
						}

						img {
							display: block;
						}
					}

					&.current_step {
						background-color: #eaf8f9;

						&::before {
							opacity: 1;
							visibility: visible;
						}

						span {
							@include font(16px, 700, #202326);
						}

						img {
							display: none;
						}
					}
				}
			}
		}
	}
}
