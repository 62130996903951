@import "assets/scss/vars.scss";

.doctor_notifications {
	flex-shrink: 0;

	.menu_btn {
		background-color: transparent !important;
		border: 0;
		padding: 0;
		width: 40px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 8px;

		img {
			width: 100%;
			max-width: 24px;
		}

		.count {
			position: absolute;
			min-width: 18px;
			min-height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 4px;
			background-color: #dc3545;
			@include borderRadius(26px);
			top: 0;
			#{$ltr} & {
				right: 0;
			}
			#{$rtl} & {
				left: 0;
			}
			@include font(10px, 700, #fff);

			@media (max-width: 375.98px) {
				min-width: 16px;
				min-height: 16px;
			}
		}
	}
}

.notifications_menu {
	padding: 12px;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 1px solid #dce0e5 !important;
	background-color: #fff !important;
	@include borderRadius(8px);
}
