@import "assets/scss/vars.scss";

.transaction_history {
	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 32px;

		h2 {
			@include font(16px, 800, #202326);
			margin-bottom: 0;
		}
	}

	.table_holder {
		background-color: #fff;
		border: 0.7px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);

		:global {
			.p-datatable-table {
				.p-datatable-thead {
					tr {
						th {
							&:first-child {
								#{$ltr} & {
									border-top-left-radius: 6px;
								}
								#{$rtl} & {
									border-top-right-radius: 6px;
								}
							}

							&:last-child {
								#{$ltr} & {
									border-top-right-radius: 6px;
								}
								#{$rtl} & {
									border-top-left-radius: 6px;
								}
							}

							background-color: #f8f9fc;
							@include font(14px, 700, #808b9a);
						}
					}
				}

				.p-datatable-tbody {
					tr {
						td {
							@include font(14px, 600, #202326);
						}
					}
				}
			}

			.p-paginator {
				.p-paginator-left-content {
					margin-right: unset;
					@include font(14px, 600, #202326);
				}

				.p-dropdown {
					-webkit-margin-end: auto;
					margin-inline-end: auto;
					margin-left: 0 !important;
					height: 32px;
					box-shadow: none !important;

					&-label {
						padding: 6px 8px;
					}
				}

				.p-paginator-current {
					-webkit-margin-start: auto;
					margin-inline-start: auto;
					padding: 0;
					@include font(14px, 600, #808b9a);
				}
			}
		}
	}
}
