@import "assets/scss/vars.scss";

.upcoming_appointment {
	margin-bottom: 40px;

	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 24px;

		@media (max-width: 280.98px) {
			flex-wrap: wrap;
		}

		h2 {
			@include font(14px, 700, #202326);
			margin-bottom: 0;
		}
	}

	.upcoming_cards {
		& > * {
			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%; 
    display: flex;
    justify-content: center; 
    align-items: center; 
}
