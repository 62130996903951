@import "assets/scss/vars.scss";

.appointment_box {
	&:not(:last-child) {
		margin-bottom: 16px;
	}

	padding: 16px;
	background-color: #fff;
	@include borderRadius(6px);
	border: 1px solid transparent;
	border-inline-start-width: 3px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;

	@media (max-width: 600.98px) {
		flex-direction: column;
	}

	&.inClinic {
		border-color: #d4f0f3;
		border-inline-start-color: #29b6c2;
	}

	&.videoCall {
		border-color: #f8d7da;
		border-inline-start-color: #dc3545;
	}

	.time {
		flex-shrink: 0;
		-webkit-padding-end: 12px;
		padding-inline-end: 12px;
		-webkit-border-end: 1px solid #dce0e5;
		border-inline-end: 1px solid #dce0e5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 6px;

		@media (max-width: 600.98px) {
			-webkit-padding-end: 0;
			padding-inline-end: 0;
			-webkit-border-end: 0;
			border-inline-end: 0;
			border-bottom: 1px solid #dce0e5;
			padding-bottom: 12px;
			width: 100%;
		}

		img {
			width: 20px;
			height: 20px;
		}

		p {
			@include font(14px, 600, #202326);
			text-align: center;
			margin-bottom: 0;
			line-height: 20px;
		}
	}

	.right {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;

		@media (max-width: 600.98px) {
			flex-direction: column;
			width: 100%;
		}

		.patient_data {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 12px;

			.img {
				flex-shrink: 0;
				width: 44px;
				height: 44px;
				@include borderRadius(100%);
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}

			.info {
				h5 {
					@include font(14px, 700, #202326);
					margin-bottom: 0;
					line-height: 20px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
					line-height: 20px;
				}
			}
		}

		.actions {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 12px;
		}
	}
}
