@import "assets/scss/vars.scss";

.doctor_sideBar {
	background-color: #fff;
	-webkit-border-end: 0.5px solid #dce0e5;
	border-inline-end: 0.5px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	width: 250px;
	display: flex;
	flex-direction: column;

	.logo_holder {
		padding: 24px 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-bottom: 1px solid #dce0e5;

		.logo {
			height: 38px;

			img {
				height: 100%;

				&.original_logo {
					display: block;
				}

				&.small_logo {
					display: none;
				}
			}
		}
	}

	.sidebar_links {
		flex-grow: 1;
		padding: 32px 16px;
		height: calc(100vh - 191px);
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 0.3em;
		}

		.links_holder {
			&:not(:last-child) {
				border-bottom: 1px solid #dce0e5;
				padding-bottom: 20px;
				margin-bottom: 20px;
			}

			.sidebar_link {
				&:not(:last-of-type) {
					margin-bottom: 8px;
				}

				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 8px;
				padding: 12px;
				@include transition(0.3s);
				position: relative;

				&::before {
					content: "";
					height: 100%;
					width: 3px;
					background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					position: absolute;
					@include borderRadius(50px);
					top: 0;
					#{$ltr} & {
						left: 0;
					}
					#{$rtl} & {
						right: 0;
					}
					opacity: 0;
					visibility: hidden;
				}

				.left {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 8px;

					img {
						flex-shrink: 0;
						width: 100%;
						max-width: 20px;
						width: 20px;

						&.gray {
							display: flex;
						}

						&.gradient {
							display: none;
						}
					}

					span {
						@include font(14px, 500, #202326);
						@include transition(0.3s);
						text-transform: capitalize;
					}
				}

				.count {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 20px;
					height: 20px;
					@include borderRadius(100%);
					background-color: #48bb78;
					@include font(10px, 600, #fff);
				}

				&:hover {
					background-color: #f7f7f7;
				}

				&:global(.active) {
					background-color: #eaf8f9;

					&::before {
						opacity: 1;
						visibility: visible;
					}

					.left {
						img {
							&.gray {
								display: none;
							}

							&.gradient {
								display: flex;
							}
						}

						span {
							font-weight: 600;
						}
					}
				}
			}
		}
	}

	.sideBar_resize_btn {
		padding: 40px 16px 24px;

		.collapse_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			padding: 7px;
			background-color: #fff;
			border: 1px solid #dce0e5;
			@include borderRadius(6px);

			img {
				width: 100%;
				max-width: 24px;

				#{$rtl} & {
					@include transform(rotate(180deg));
				}
			}
		}
	}

	&.small_size {
		width: 72px;

		.logo_holder {
			padding: 24px 18px;

			.logo {
				img {
					&.original_logo {
						display: none;
					}

					&.small_logo {
						display: block;
					}
				}
			}
		}

		.sidebar_links {
			.links_holder {
				.sidebar_link {
					width: -webkit-fit-content;
					width: -moz-fit-content;
					width: fit-content;
					padding: 12px 10px;

					.left {
						span {
							display: none;
						}
					}

					.count {
						display: none;
					}
				}
			}
		}

		.sideBar_resize_btn {
			.collapse_btn {
				img {
					@include transform(rotate(180deg));

					#{$rtl} & {
						@include transform(rotate(0deg));
					}
				}
			}
		}
	}

	.side_tooltip {
		z-index: 999;
	}
}
