@import "assets/scss/vars.scss";

.speciality_doctors {
	padding: 96px 0;

	:global {
		.sec_head {
			margin-bottom: 50px;
		}
	}
}
