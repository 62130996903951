@import "assets/scss/vars.scss";

.profile_pass_tabs {
	:global {
		.p-tabview {
			&-nav-container {
				.p-tabview-nav-content {
					border-bottom: 1px solid #dce0e5;

					.p-tabview-nav {
						border-width: 0;

						.p-unselectable-text {
							.p-tabview-nav-link {
								margin: 0;
								border: 0;
								padding: 12px;
								box-shadow: none;

								.p-tabview-title {
									@include font(14px, 500, #202326);
									text-transform: capitalize;
								}
							}

							&.p-tabview-selected {
								&.p-highlight {
									.p-tabview-title {
										font-weight: 700;
									}
								}
							}
						}

						.p-tabview-ink-bar {
							height: 3px;
							@include borderRadius(8px);
							background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
						}
					}
				}
			}

			&-panels {
				padding: 24px 0 0 0;
			}
		}
	}
}
