@import "assets/scss/vars.scss";

.patients_reviews {
	padding: 24px;
	background-color: #fff;
	@include borderRadius(8px);

	h3 {
		@include font(16px, 800, #202326);
		margin-bottom: 16px;
	}

	.rates_holder {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 7px;

		.total_rate {
			flex-shrink: 0;
			background-color: #f8f9fc;
			border: 1px solid #dce0e5;
			@include borderRadius(4px);
			padding: 25px 30px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 10px;

			.rate_val {
				@include font(36px, 700, #202326);
				line-height: 46px;
			}

			.ratings {
				@include font(14px, 500, #202326);
			}
		}

		.rate_progress {
			flex-grow: 1;
			padding: 0 24px;
		}
	}

	.comments {
		margin-top: 24px;

		.comments_rows {
			height: 370px;
			overflow: hidden;

			.comment_row {
				&:first-child {
					padding-top: 16px;
					border-top: 1px solid #dce0e5;
				}

				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid #dce0e5;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 20px;

				.reviewer_chars {
					flex-shrink: 0;
					width: 44px;
					height: 44px;
					display: flex;
					align-items: center;
					justify-content: center;
					@include borderRadius(100px);
					@include font(18px, 600, #fff);
					text-transform: capitalize;
					background-color: #29b6c2;
				}

				.right {
					.reviewer_name {
						@include font(14px, 600, #202326);
						margin-bottom: 7px;
					}

					.rate_date {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 13px;
						margin-bottom: 10px;

						.date {
							@include font(12px, 500, #808b9a);
						}
					}

					.reviewer_comment {
						@include font(14px, 400, #202326);
					}
				}
			}

			&.showAll_reviews {
				height: auto;
			}
		}

		.showAll_btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			background-color: transparent;
			border: 0;
			padding: 7px 12px;
			@include borderRadius(6px);

			:global {
				.p-button-label {
					@include font(14px, 700, transparent);
					@extend %gradientText;
				}
			}
		}
	}
}

.no_reviews {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	@include font(18px, 700, #202326);
}
