@import "assets/scss/vars.scss";

.quick_links {
	margin-bottom: 40px;

	h2 {
		@include font(14px, 700, #202326);
		margin-bottom: 24px;
	}

	.quick_row {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;

		@media (max-width: 1199.98px) {
			flex-wrap: wrap;
		}

		.quick_box {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 16px;
			padding: 20px;
			background-color: #eaf8f9;
			border: 1px solid #d4f0f3;
			@include borderRadius(8px);

			img {
				flex-shrink: 0;
				width: 24px;
				height: 24px;
			}

			.lbl {
				@include font(14px, 700, #202326);
			}
		}
	}
}
