@import "assets/scss/vars.scss";

.customers_stories {
	padding: 96px 0;
	background-color: #eaf8f9;

	.sec_head {
		margin-bottom: 50px;

		.quote_mark {
			display: block;
			width: 100%;
			max-width: 32px;
		}

		h2 {
			@include font(32px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.stories_slider {
		position: relative;

		.story_box {
			padding: 32px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			@include borderRadius(8px);
			background-color: #d4f0f3;
			@include transition(0.3s);
			margin: 10px 0;

			@media (max-width: 280.98px) {
				padding: 10px;
			}

			.quote_mark {
				width: 100%;
				max-width: 16px;
				margin: 0 auto 16px;
			}

			.customer_comment {
				@include font(16px, 400, #202326);
				margin-bottom: 24px;
				text-align: center;

				@media (max-width: 991.98px) {
					font-size: 12px;
				}

				@media (max-width: 280.98px) {
					font-size: 10px;
				}
			}

			.customer_name {
				@include font(16px, 800, #202326);
				text-align: center;

				@media (max-width: 991.98px) {
					font-size: 12px;
				}

				@media (max-width: 280.98px) {
					font-size: 10px;
				}
			}
		}

		:global(.swiper-slide-active) {
			.story_box {
				background-color: #fff;
				box-shadow: 0px 10px 20px 0px #020e1f0a, 0px 0px 1px 0px #020e1f1f;
			}
		}

		:global {
			.review-swiper-button-prev {
				color: #29b6c2 !important;

				#{$ltr} & {
					left: -20px;
					@include transform(translate(-100%, -50%) rotate(0deg));

					@media (max-width: 1400.98px) {
						left: 0;
					}

					@media (max-width: 600.98px) {
						left: 40px;
					}
				}
				#{$rtl} & {
					right: -20px;
					@include transform(translate(100%, -50%) rotate(180deg));

					@media (max-width: 1400.98px) {
						right: 0;
					}

					@media (max-width: 600.98px) {
						right: 40px;
					}
				}
			}

			.review-swiper-button-next {
				color: #29b6c2 !important;

				#{$ltr} & {
					right: -20px;
					@include transform(translate(100%, -50%) rotate(0deg));

					@media (max-width: 1400.98px) {
						right: 0;
					}

					@media (max-width: 600.98px) {
						right: 40px;
					}
				}
				#{$rtl} & {
					left: -20px;
					@include transform(translate(-100%, -50%) rotate(180deg));

					@media (max-width: 1400.98px) {
						left: 0;
					}

					@media (max-width: 600.98px) {
						left: 40px;
					}
				}
			}
		}
	}
}
