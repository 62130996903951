@import "assets/scss/vars.scss";

body {
	margin: 0;
	#{$ltr} & {
		font-family: "Mulish", sans-serif, "primeicons" !important;
	}
	#{$rtl} & {
		font-family: "IBM Plex Sans Arabic", sans-serif, "primeicons" !important;
	}
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
