@import "assets/scss/vars.scss";

.doctor_patients {
	padding: 32px;
	background-color: #fff;
	min-height: 100%;

	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 32px;
		flex-wrap: wrap;

		.title {
			h1 {
				@include font(24px, 800, #202326);
				margin-bottom: 0;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 20px;
		flex-wrap: wrap;
	}

	.patients_table {
		:global {
			.p-datatable {
				&-thead {
					tr {
						th {
							padding: 12px;
							background-color: #f8f9fc;
							@include font(14px, 700, #808b9a);
						}
					}
				}
				&-tbody {
					tr {
						td {
							padding: 8px 12px;
							@include font(14px, 600, #202326);
						}
					}
				}
			}
		}

		.patient_info {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;

			.patient_img {
				flex-shrink: 0;
				width: 44px;
				height: 44px;
				@include borderRadius(100%);
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 100%;
					height: 100%;
					-o-object-fit: cover;
					object-fit: cover;
				}
			}

			.data {
				h5 {
					@include font(14px, 600, #202326);
					margin-bottom: 0;
				}

				p {
					@include font(14px, 500, #808b9a);
					margin-bottom: 0;
				}
			}
		}
	}
}
