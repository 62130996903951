@import "assets/scss/vars.scss";

.rate_holder {
	:global {
		.p-rating {
			gap: 5px !important;
			direction: ltr;

			&-item {
				.p-rating-icon {
					color: #c6ced9 !important;
					font-size: 14px !important;
				}

				&-active {
					.p-rating-icon {
						color: #ffc107 !important;
					}

					&:focus {
						box-shadow: none !important;
					}
				}

				&:focus {
					box-shadow: none !important;
				}
			}

			&.bigStar {
				.p-rating-item {
					.p-rating-icon {
						font-size: 44px !important;
					}
				}
			}
		}
	}
}
