@import "assets/scss/vars.scss";

.notify_btn {
	flex-shrink: 0;
	padding: 8px;
	background-color: transparent !important;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	img {
		width: 100%;
		max-width: 22px;

		@media (max-width: 575.98px) {
			max-width: 18px;
		}

		@media (max-width: 375.98px) {
			max-width: 16px;
		}
	}

	.count {
		position: absolute;
		min-width: 18px;
		min-height: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 4px;
		background-color: #dc3545;
		@include borderRadius(26px);
		top: 0;
		#{$ltr} & {
			right: 0;
		}
		#{$rtl} & {
			left: 0;
		}
		@include font(10px, 700, #fff);

		@media (max-width: 375.98px) {
			min-width: 16px;
			min-height: 16px;
		}
	}
}
