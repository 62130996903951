@import "assets/scss/vars.scss";

.your_answers {
	padding: 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	margin-bottom: 40px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 20px;
	}

	.answers_rows {
		.answer_row {
			&:not(:last-child) {
				margin-bottom: 20px;
			}

			.answer_title {
				@include font(14px, 600, #808b9a);
				margin-bottom: 2px;
			}

			.answer_list {
				list-style: disc;
				-webkit-padding-start: 20px;
				padding-inline-start: 20px;

				li {
					&:not(:last-child) {
						margin-bottom: 5px;
					}

					@include font(14px, 700, #202326);
					line-height: 20px;
				}
			}
		}
	}
}
