@import "assets/scss/vars.scss";

.add_drawer_holder {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	@include borderRadius(8px);
	width: 25rem !important;

	:global {
		.p-sidebar-header {
			display: none;
		}

		.p-sidebar-content {
			display: flex;
			flex-direction: column;
			padding: 0;
		}
	}

	.drawer_header {
		padding: 17px 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;
		border-bottom: 1px solid #dce0e5;

		h2 {
			@include font(20px, 800, #202326);
			margin-bottom: 0;
		}

		.close_btn {
			width: 30px;
			height: 30px;
			padding: 6px;
			@include borderRadius(4px);
			background-color: #fff !important;
			border: 0 !important;
			color: #020e1f !important;
		}
	}

	.drawer_form {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		height: calc(100% - 65px);
	}

	.drawer_content {
		flex-grow: 1;
		overflow-y: auto;
		padding: 24px 24px 40px;

		/* Custom Scroll bar */
		&::-webkit-scrollbar {
			width: 0.3em;
		}

		.alarm {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 12px;

			h3 {
				@include font(14px, 500, #202326);
				margin-bottom: 0;
			}
		}
	}

	.drawer_footer {
		padding: 24px;
		border-top: 1px solid #dce0e5;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 12px;

		.discard_btn {
			padding: 7px 12px;
			background-color: #fff !important;
			border: 0 !important;

			:global {
				.p-button-label {
					@include font(14px, 700, #020e1f);
				}
			}
		}

		.done_btn {
			padding: 7px 12px;

			:global {
				.p-button-label {
					font-size: 14px;
				}
			}
		}
	}
}

.mt16 {
	margin-top: 16px;
}

.add_drawer {
	&:global(.p-sidebar-left) {
		#{$ltr} & {
			justify-content: flex-start;
		}
		#{$rtl} & {
			justify-content: flex-end;
		}
	}
}
