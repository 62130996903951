@import "assets/scss/vars.scss";

.log_P {
	min-height: 100vh;
	background-color: #f8f9fc;

	& > :global(.container) {
		min-height: 100vh;
	}

	.auth_lang {
		position: absolute;
		top: 40px;
		#{$ltr} & {
			right: 50px;

			@media (max-width: 575.98px) {
				right: 20px;
			}
		}
		#{$rtl} & {
			left: 50px;

			@media (max-width: 575.98px) {
				left: 20px;
			}
		}
		-webkit-padding-start: 0;
		padding-inline-start: 0;
		-webkit-margin-start: 0;
		margin-inline-start: 0;
		-webkit-border-start: 0;
		border-inline-start: 0;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);

		@media (max-width: 575.98px) {
			top: 20px;
		}

		[class*="lang_btn"] {
			padding: 7px 12px;

			&:hover {
				background-color: #e6e7e9;
			}
		}
	}

	&_content {
		min-height: 100vh;
		display: flex;
		flex-direction: column;

		.auth_header {
			padding: 50px 150px;

			@media (max-width: 767.98px) {
				padding: 50px 60px;
			}

			@media (max-width: 575.98px) {
				padding: 20px;
			}

			@media (max-width: 375.98px) {
				padding: 20px 10px;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 38px;
				width: -webkit-fit-content;
				width: -moz-fit-content;
				width: fit-content;

				img {
					height: 100%;
				}
			}
		}

		.content_holder {
			flex-grow: 1;
			padding: 30px;
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 375.98px) {
				padding: 30px 10px;
			}

			.card_box {
				padding: 32px 24px;
				background-color: #fff;
				@include borderRadius(16px);
				min-width: 400px;
				width: 100%;
				max-width: 400px;

				@media (max-width: 575.98px) {
					min-width: 100%;
				}

				.back_btn {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 6px;
					margin-bottom: 24px;
					width: -webkit-fit-content;
					width: -moz-fit-content;
					width: fit-content;

					img {
						flex-shrink: 0;
						width: 100%;
						max-width: 24px;

						#{$rtl} & {
							@include transform(rotate(180deg));
						}
					}

					span {
						@include font(14px, 700, transparent);
						@extend %gradientText;
					}
				}

				.f_txt {
					margin-bottom: 24px;

					h3 {
						@include font(24px, 800, #202326);
						margin-bottom: 2px;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}

				.form_holder {
					margin-bottom: 24px;

					.inputs_wrapper {
						margin-bottom: 24px;
					}
				}

				.back_to {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 7px;
					@include font(14px, 700, transparent);
					@extend %gradientText;
				}
			}
		}

		.auth_footer {
			padding: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			a {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(12px, 500, #202326);
				@include transition(0.3s);

				&:hover {
					@include font(12px, 600, transparent);
					@extend %gradientText;
				}
			}
		}
	}
}
