@import "assets/scss/vars.scss";

.doctor_settings {
	display: flex;
	height: 100%;

	.settings_sidebar {
		flex-shrink: 0;
		background-color: #fff;
		width: 262px;
		-webkit-border-end: 1px solid #dce0e5;
		border-inline-end: 1px solid #dce0e5;
		height: calc(100vh - 65px);
		display: flex;
		flex-direction: column;

		h1 {
			padding: 32px 32px 24px;
			@include font(24px, 800, #202326);
		}

		.settings_links {
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 0 32px 32px;

			.links_holder {
				&:not(:last-child) {
					padding-bottom: 12px;
					margin-bottom: 12px;
					border-bottom: 1px solid #dce0e5;
				}

				.setting_link {
					padding: 17px 12px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					@include transition(0.3s);
					position: relative;
					@include font(14px, 500, #202326);

					&::before {
						content: "";
						height: 100%;
						width: 3px;
						background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
						position: absolute;
						@include borderRadius(50px);
						top: 0;
						#{$ltr} & {
							left: 0;
						}
						#{$rtl} & {
							right: 0;
						}
						opacity: 0;
						visibility: hidden;
					}

					&:hover {
						background-color: #f7f7f7;
						font-weight: 600;
					}

					&:global(.active) {
						background-color: #eaf8f9;
						font-weight: 600;

						&::before {
							opacity: 1;
							visibility: visible;
						}
					}
				}
			}
		}
	}

	.outlet_wrapper {
		flex-grow: 1;
		padding: 32px;
		background-color: #fff;
		height: calc(100vh - 65px);
		overflow-y: auto;
		overflow-x: hidden;
	}
}
