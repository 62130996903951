@import "assets/scss/vars.scss";

.from_head {
	@include font(16px, 800, #202326);
	margin-bottom: 20px;
}

.choices_title {
	@include font(16px, 800, #202326);
	margin-bottom: 20px;
}

.choices_rows {
	.choices_row {
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		.notify_type {
			@include font(14px, 400, #202326);
		}

		.check_boxes {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 20px;
		}
	}
}
