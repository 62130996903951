@import "assets/scss/vars.scss";

.log_P {
	min-height: 100vh;
	background-color: #fff;
	padding: 0 12px;

	& > :global(.row) {
		min-height: 100vh;
	}

	.bgGragient {
		background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
	}

	.gradient_side {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.top_sec {
			flex-grow: 1;
			padding: 166px 100px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@media (max-width: 1199.98px) {
				padding: 100px 30px;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				margin-bottom: 16px;

				img {
					height: 100%;
				}
			}

			h1 {
				@include font(40px, 900, #fff);
				text-align: center;
				max-width: 90%;
				margin: 0 auto 16px;
			}

			p {
				@include font(16px, 400, #fff);
				text-align: center;
				max-width: 80%;
				margin: 0 auto;
			}
		}

		.gradientImg {
			width: 100%;
			max-width: 564px;
		}
	}

	.log_form {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 32px 100px;

		@media (max-width: 1199.98px) {
			padding: 32px 60px;
		}

		.top_sec {
			flex-grow: 1;
			padding: 179px 24px;
			margin: 0 40px 10px;
			width: 100%;

			@media (max-width: 1199.98px) {
				padding: 100px 24px;
			}

			@media (max-width: 575.98px) {
				padding: 80px 0px;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				margin-bottom: 60px;

				img {
					height: 100%;
				}
			}

			.f_txt {
				margin-bottom: 24px;

				h3 {
					@include font(24px, 800, #202326);
					margin-bottom: 8px;
				}

				p {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 8px;
					margin-bottom: 0;

					span {
						@include font(14px, 400, #202326);
					}

					a {
						@include font(14px, 700, transparent);
						@extend %gradientText;
					}
				}
			}

			.social_btns {
				margin-bottom: 24px;
				display: flex;
				flex-direction: column;
				gap: 16px;

				.social_btn {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 6px;
					background-color: #fff;
					border: 1px solid #dce0e5;
					padding: 8px 16px;
					@include borderRadius(8px);
					@include transition(0.3s);

					img {
						flex-shrink: 0;
						width: 100%;
						max-width: 24px;
					}

					span {
						@include font(14px, 700, #202326);
					}

					&:hover {
						background-color: #f8f8f8;
					}
				}
			}

			.or {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 24px;
				position: relative;
				margin-bottom: 24px;

				span {
					@include font(14px, 500, #808b9a);
				}

				&::before {
					content: "";
					border-bottom: 1px solid #dce0e5;
					display: inline-block;
					width: 100%;
				}

				&::after {
					content: "";
					border-bottom: 1px solid #dce0e5;
					display: inline-block;
					width: 100%;
				}
			}

			.inputs_wrapper {
				margin-bottom: 30px;
			}
		}

		.auth_footer {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			a {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(12px, 500, #202326);
				@include transition(0.3s);

				&:hover {
					@include font(12px, 600, transparent);
					@extend %gradientText;
				}
			}
		}
	}
}

.inpt_lbl {
	@include font(14px, 400, #202326);
	margin-bottom: 8px;

	&.required {
		position: relative;

		&::after {
			content: "*";
			display: inline-block;
			-webkit-margin-start: 6px;
			margin-inline-start: 6px;
			@include font(14px, 600, #dc3545);
		}
	}
}
