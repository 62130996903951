@import "assets/scss/vars.scss";

.information {
	margin-bottom: 32px;

	.information_card {
		padding: 16px;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(149.6px, 1fr));
		row-gap: 20px;

		.information_box {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 8px;

			img {
				width: 24px;
				height: 24px;
			}

			h6 {
				@include font(14px, 500, #808b9a);
				margin-bottom: 0;
			}

			h5 {
				@include font(14px, 600, #202326);
				margin-bottom: 0;
			}
		}
	}
}
