@import "assets/scss/vars.scss";

.doctor_qty_answer {
	padding: 32px;
	background-color: #fff;
	min-height: 100%;

	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 32px;

		h1 {
			@include font(24px, 800, #202326);
			margin-bottom: 0;
		}

		.head_actions {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;

			.specialization_dropdown {
				flex-shrink: 0;
			}
		}
	}

	.answer_tabs {
		:global {
			.p-tabview {
				&-nav {
					border-bottom: 1px solid #dce0e5;

					.p-unselectable-text {
						.tab_btn {
							padding: 12px;
							background-color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							gap: 8px;
							border: 0;
							margin: 0;

							.title {
								@include font(14px, 500, #202326);
							}

							.count {
								padding: 3px 6px;
								background-color: #f3f4f6;
								border: 1px solid #dce0e5;
								@include borderRadius(6px);
								@include font(10px, 600, #202326);
							}
						}

						&.p-tabview-selected {
							.tab_btn {
								.title {
									font-weight: 700;
								}

								.count {
									background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
									border-color: transparent;
									color: #fff;
								}
							}
						}
					}

					.p-tabview-ink-bar {
						height: 3px;
						background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
						@include borderRadius(50px);
					}
				}

				&-panels {
					padding: 32px 0;
				}
			}
		}
	}
}

.filter_overlay {
	min-width: 320px;
	max-width: 320px;
	margin-top: 10px;
	background-color: #fff;
	border: 0.5px solid #dce0e5;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;

	&::before,
	&::after {
		display: none;
	}

	:global {
		.p-overlaypanel-content {
			padding: 14px 12px;
		}
	}

	.checkboxes {
		margin-top: 22px;

		.CheckBox_row {
			&:not(:last-child) {
				margin-bottom: 6px;
			}

			padding: 10px 8px;

			:global {
				.check_box {
					.checkbox_lbl {
						font-weight: 500;
					}
				}
			}
		}
	}
}
