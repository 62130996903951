@import "assets/scss/vars.scss";


.operational_hours {
	&_content {
		padding: 24px 24px 40px;

		.sec_holder {
			&:not(:last-child) {
				padding-bottom: 24px;
				border-bottom: 1px solid #e5e7eb;
				margin-bottom: 24px;
			}
		}

		.title {
			margin-bottom: 24px;

			h3 {
				@include font(14px, 700, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.select_duration {
			margin-bottom: 24px;
		}

		.consult_patients {
			padding: 12px;
			background-color: #eaf8f9;
			@include borderRadius(8px);

			.consult_online {
				h5 {
					@include font(14px, 700, #202326);
					margin-bottom: 4px;
				}

				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			&.mb24 {
				margin-bottom: 24px;
			}
		}

		.practice_days {
			.day_row {
				&:not(:last-child) {
					margin-bottom: 16px;
				}

				.closed {
					[class*="switch_label"] {
						color: #808b9a;
					}
				}

				.times_picker {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 20px;

					.to {
						@include font(14px, 500, #808b9a);
					}
				}
			}
		}
	}

	.footer_actions {
		padding: 24px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-top: 1px solid #dce0e5;
	}
}
