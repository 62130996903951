@import "assets/scss/vars.scss";

.doctor_patients_details {
	background-color: #fff;
	min-height: 100%;
	display: flex;

	.patients_list {
		width: 340px;
		-webkit-border-end: 1px solid #dce0e5;
		border-inline-end: 1px solid #dce0e5;
		flex-shrink: 0;
		height: calc(100vh - 65px);
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		display: flex;
		flex-direction: column;

		&_head {
			padding: 32px;
			padding-bottom: 0;
			margin-bottom: 16px;

			h1 {
				@include font(24px, 800, #202326);
				margin-bottom: 20px;
			}

			.head_action {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 16px;
				margin-bottom: 16px;

				[class*="filter_patients"] {
					[class*="white_black_btn"] {
						padding: 7px;
					}
				}
			}

			.patients_count {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		&_body {
			flex-grow: 1;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 32px;
			padding-top: 0;

			&::-webkit-scrollbar {
				width: 0.3em;
			}

			.patients_box {
				padding: 12px;
				background-color: #fff;
				border-bottom: 1px solid #dce0e5 !important;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;
				@include borderRadius(0);
				text-align: start;

				.patient_image {
					flex-shrink: 0;
					width: 44px;
					height: 44px;
					@include borderRadius(100%);
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						width: 100%;
						height: 100%;
						-o-object-fit: cover;
						object-fit: cover;
					}
				}

				.patient_info {
					h5 {
						@include font(14px, 600, #202326);
						margin-bottom: 0;
					}

					p {
						@include font(14px, 500, #808b9a);
						margin-bottom: 0;
					}
				}

				&:hover {
					background-color: lighten(#eaf8f9, 3%);
				}

				&.active {
					background-color: #eaf8f9;
				}
			}
		}
	}

	.patients_data {
		flex-grow: 1;

		& > :global(.row) {
			height: 100%;
		}

		.patient_medical_record {
			padding: 32px 24px;
			background-color: #fff;
			-webkit-border-end: 1px solid #dce0e5;
			border-inline-end: 1px solid #dce0e5;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			height: 100%;

			.record_row {
				&:not(:last-child) {
					border-bottom: 1px solid #dce0e5;
				}

				padding-bottom: 20px;
				margin-bottom: 20px;

				.patient_img {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 82px;
					height: 82px;
					@include borderRadius(100%);
					overflow: hidden;
					margin-bottom: 20px;

					img {
						width: 100%;
						height: 100%;
						-o-object-fit: cover;
						object-fit: cover;
					}
				}

				.name_lastUpdated {
					margin-bottom: 20px;

					h5 {
						@include font(20px, 800, #202326);
						margin-bottom: 0;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}

				.patient_contacts {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 20px;

					.call_btn {
						img {
							width: 18px;
							height: 18px;
						}
					}

					.mail_btn {
						img {
							width: 18px;
							height: 18px;
						}
					}
				}

				.record_title {
					@include font(14px, 700, #202326);
					margin-bottom: 20px;
				}

				.information_rows {
					.information_row {
						padding: 10px 0;
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 5px;

						.lbl {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 6px;

							.icon {
								flex-shrink: 0;
								width: 20px;
								height: 20px;
							}

							.txt {
								@include font(14px, 500, #808b9a);
							}
						}

						.val {
							@include font(14px, 600, #202326);
						}
					}
				}

				.vitals_title {
					margin-bottom: 20px;

					.record_title {
						margin-bottom: 0;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
					}
				}

				.lab_results_rows {
					.lab_results_row {
						&:not(:last-child) {
							margin-bottom: 16px;
						}

						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 12px;

						.results_data {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 12px;

							.icon {
								flex-shrink: 0;
								width: 24px;
								height: 24px;
							}

							.name_desc {
								h5 {
									@include font(14px, 500, #202326);
									margin-bottom: 0;
									line-height: 20px;
								}

								p {
									@include font(14px, 400, #808b9a);
									margin-bottom: 0;
									line-height: 20px;
								}
							}
						}

						.view_btn {
							flex-shrink: 0;
							padding: 2px;
							width: 20px;
							height: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}

		.medical_history {
			padding: 32px 24px;
			background-color: #fff;
			height: 100%;

			&_head {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 20px;
				padding-bottom: 24px;
				margin-bottom: 24px;
				border-bottom: 1px solid #dce0e5;
				flex-wrap: wrap;

				h2 {
					@include font(20px, 800, #202326);
					margin-bottom: 0;
				}
			}

			&_body {
				.history_holder {
					&:not(:last-child) {
						margin-bottom: 24px;
					}
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					gap: 12px;

					.date {
						flex-shrink: 0;
						padding: 6px 14px;
						background-color: #8392a5;
						@include borderRadius(6px);
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						gap: 6px;

						span {
							color: #fff;

							&:first-child {
								font-size: 20px;
								font-weight: 800;
							}

							&:last-child {
								font-size: 14px;
								font-weight: 400;
							}
						}
					}

					.history_box {
						flex-grow: 1;
						border: 1px solid #dce0e5;
						box-shadow: 0px 1px 8px 0px #020e1f0a;
						@include borderRadius(6px);
						max-width: 100%;
						width: calc(100% - 67px);

						&_head {
							padding: 16px;
							display: flex;
							align-items: center;
							justify-content: space-between;
							gap: 6px;
							border-bottom: 1px solid #dce0e5;

							.txt {
								h3 {
									@include font(16px, 800, #202326);
									margin-bottom: 2px;
								}

								p {
									@include font(14px, 400, #808b9a);
									margin-bottom: 0;
								}
							}

							.addRecord_dropdown {
								flex-shrink: 0;

								.menu_btn {
									background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
									padding: 1px;
									@include borderRadius(4px);
									border: 0;

									.btn_content {
										background-color: #fff;
										padding: 6px 10px;
										@include borderRadius(4px);
										display: flex;
										align-items: center;
										justify-content: center;
										gap: 4px;

										span {
											background: linear-gradient(
												90deg,
												#45cc9c 0%,
												#0c75e6 100%
											);
											background-clip: text;
											-webkit-background-clip: text;
											-webkit-text-fill-color: transparent;
											-moz-background-clip: text;
											-moz-text-fill-color: transparent;
											@include font(12px, 700, transparent);
										}
									}

									&:hover,
									&:focus {
										.btn_content {
											background-color: #eaf8f9;
										}
									}
								}
							}
						}

						.records_holder {
							:global {
								.p-accordion {
									&-tab {
										margin-bottom: 0;

										.p-accordion-header {
											&-link {
												box-shadow: none !important;
												padding: 16px 24px;
												gap: 16px;
												border-bottom: 1px solid #dce0e5;
												@include borderRadius(0);
												border-top: 0;
												background-color: #f8f9fc !important;
												@include transition(0.3s);

												.p-accordion-toggle-icon {
													order: 1;
													flex-shrink: 0;
													margin: 0;
													width: 18px;
													height: 18px;
													@include transform(rotate(90deg));
													@include transition(0.3s);

													path {
														fill: #8392a5;
													}
												}
											}

											&-text {
												width: 100%;

												.head_content {
													width: 100%;
													display: flex;
													align-items: center;
													justify-content: space-between;
													gap: 16px;

													.accordion_label {
														@include font(14px, 700, #202326);
													}

													.accordion_actions {
														flex-shrink: 0;
														display: flex;
														align-items: center;
														justify-content: flex-end;
														gap: 16px;

														.p-button {
															flex-shrink: 0;
															padding: 1px;
															display: flex;
															align-items: center;
															justify-content: center;

															img {
																width: 18px;
																height: 18px;
															}
														}
													}
												}
											}
										}

										.p-accordion-content {
											padding: 16px 24px;
											@include borderRadius(0);
											border: 0;
											border-bottom: 1px solid #dce0e5;
										}

										&:last-child {
											.p-accordion-header {
												&-link {
													border-bottom: 0;
												}
											}
										}

										&-active {
											.p-accordion-header {
												&-link {
													border-bottom: 0;

													.p-accordion-toggle-icon {
														@include transform(rotate(180deg));
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.record_menu {
	padding: 14px 12px;
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	border: 0.5px solid #dce0e5;
	@include borderRadius(8px);
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				&-link {
					padding: 10px 8px;
					@include font(14px, 500, #202326);
					box-shadow: none !important;

					&:hover {
						background-color: #f7f7f7;
					}

					.p-menuitem-text {
						span {
							&.added {
								color: #808b9a;
							}
						}
					}
				}
			}
		}
	}
}
