@import "assets/scss/vars.scss";

.log_P {
	min-height: 100vh;
	background-color: #fff;
	padding: 0 12px;

	& > :global(.row) {
		min-height: 100vh;
	}

	.bgGragient {
		background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
	}

	.gradient_side {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.top_sec {
			flex-grow: 1;
			padding: 166px 100px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			@media (max-width: 1199.98px) {
				padding: 100px 30px;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				margin-bottom: 16px;

				img {
					height: 100%;
				}
			}

			h1 {
				@include font(40px, 900, #fff);
				text-align: center;
				max-width: 90%;
				margin: 0 auto 16px;
			}

			p {
				@include font(16px, 400, #fff);
				text-align: center;
				max-width: 80%;
				margin: 0 auto;
			}
		}

		.gradientImg {
			width: 100%;
			max-width: 564px;
		}
	}

	.log_form {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 32px 160px;

		@media (min-width: 1200px) and (max-width: 1400.98px) {
			padding: 32px 100px;
		}

		@media (min-width: 992px) and (max-width: 1199.98px) {
			padding: 32px 60px;
		}

		@media (max-width: 767.98px) {
			padding: 32px 60px;
		}

		.form_holder {
			margin-bottom: 24px;
		}

		.top_sec {
			flex-grow: 1;
			padding: 179px 24px;
			margin: 0 40px 10px;
			width: 100%;

			@media (max-width: 1199.98px) {
				padding: 100px 24px;
			}

			@media (max-width: 575.98px) {
				padding: 80px 0px;
			}

			.logo {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 48px;
				margin-bottom: 60px;

				img {
					height: 100%;
				}
			}

			.f_txt {
				margin-bottom: 24px;

				h3 {
					@include font(24px, 800, #202326);
					margin-bottom: 2px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			.phone_num {
				margin-bottom: 24px;

				.phone_lbl {
					@include font(14px, 500, #808b9a);
					margin-bottom: 2px;
				}

				.num {
					@include font(16px, 700, #202326);
				}
			}

			.inputs_wrapper {
				margin-bottom: 16px;
			}

			.get_code {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;

				.txt {
					@include font(14px, 500, #202326);
				}

				.resend_btn {
					padding: 2px;
					background-color: transparent !important;
					border: 0;
					@extend %gradientText;

					:global {
						.p-button-label {
							@include font(14px, 700, transparent);
						}
					}
				}
			}
		}

		.auth_footer {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 20px;

			a {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				@include font(12px, 500, #202326);
				@include transition(0.3s);

				&:hover {
					@include font(12px, 600, transparent);
					@extend %gradientText;
				}
			}
		}
	}
}
