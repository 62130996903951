@import "assets/scss/vars.scss";

.doctor_account_verification {
	padding: 24px 40px;

	@media (max-width: 991.98px) {
		padding: 0;
	}

	.head {
		margin-bottom: 24px;

		h2 {
			@include font(24px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.phone_num {
		margin-bottom: 24px;

		.phone_lbl {
			@include font(14px, 500, #808b9a);
			margin-bottom: 2px;
		}

		.num {
			@include font(16px, 700, #202326);
		}
	}

	.form_holder {
		margin-bottom: 24px;
	}

	.inputs_wrapper {
		margin-bottom: 24px;
	}

	.get_code {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		.txt {
			@include font(14px, 500, #202326);
		}

		.resend_btn {
			padding: 2px;
			background-color: transparent !important;
			border: 0;
			@extend %gradientText;

			:global {
				.p-button-label {
					@include font(14px, 700, transparent);
				}
			}
		}
	}
}
