@import "assets/scss/vars.scss";

.step_content {
	padding: 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	display: flex;
	flex-direction: column;
	min-height: 50vh;

	.top_content {
		flex-grow: 1;
		margin-bottom: 20px;

		.patient_row {
			&:not(:last-child) {
				margin-bottom: 24px;
				border-bottom: 1px solid #dce0e5;
			}

			padding-bottom: 24px;

			.qty_head {
				margin-bottom: 24px;

				h2 {
					@include font(18px, 800, #202326);
					margin-bottom: 2px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			.custom_radioBtns {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 32px;
				flex-wrap: wrap;
			}

			.qty_rows {
				.qty_row {
					&:not(:last-child) {
						margin-bottom: 24px;
						padding-bottom: 24px;
						border-bottom: 1px solid #dce0e5;
					}

					h3 {
						@include font(14px, 700, #202326);
						margin-bottom: 24px;
					}

					.radioBtns_wrapper {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 32px;
						flex-wrap: wrap;
					}
				}
			}
		}
	}

	.step_actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		padding-top: 20px;
		border-top: 1px solid #dce0e5;

		.backBtn,
		.nextBtn {
			img {
				#{$rtl} & {
					@include transform(rotate(180deg));
				}
			}
		}
	}
}
