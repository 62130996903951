@import "assets/scss/vars.scss";

.doctor_onBoarding {
	padding: 32px;

	h1 {
		@include font(24px, 800, #202326);
		margin-bottom: 24px;
	}

	.statistic_box {
		padding: 20px 24px;
		background-color: #fff;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;

		.icon {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
		}

		.info {
			h5 {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
				line-height: 20px;
			}

			h3 {
				@include font(20px, 800, #202326);
				margin-bottom: 0;
				line-height: 28px;
			}
		}
	}
}

.spinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 20vh; 
}