@import "assets/scss/vars.scss";

.doctorInfo_card {
	padding: 24px;
	background-color: #fff;
	@include borderRadius(8px);
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 20px;

	.doctor_img {
		flex-shrink: 0;
		width: 144px;
		height: 160px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			-o-object-fit: cover;
			object-fit: cover;
		}
	}

	.info {
		flex-grow: 1;

		.name {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		.speciality {
			@include font(14px, 400, #808b9a);
			margin-bottom: 6px;
		}

		.rate_reviews {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;
			margin-bottom: 6px;

			.reviews {
				@include font(12px, 500, #808b9a);
			}
		}

		.desc {
			@include font(14px, 400, #202326);
			margin-bottom: 20px;
		}

		.btns {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 10px;

			.add_fav {
				padding: 7px 12px;
				background-color: transparent;
				border: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 28px;
				}

				span {
					@include font(14px, 400, #808b9a);
				}
			}

			i {
				font-size: 17px;
				@extend %gradientText;
			}
		}

		.share {
			padding: 7px 12px;
			background-color: transparent;
			border: 0;
			display: flex;
			align-items: center;
			justify-content: center;

			span {
				@include font(14px, 700, transparent);
				@extend %gradientText;
			}
		}
	}
}