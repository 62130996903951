@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.input_box {
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		@include transition(0.3s);

		:global {
			.p-dropdown {
				border: 0;
				@include borderRadius(6px);
				box-shadow: none !important;
				position: relative;
			}

			.p-inputtext {
				background-color: #fff !important;
				border: 0;
				box-shadow: none !important;
				@include borderRadius(6px);
				position: relative;
				@include font(14px, 600, #202326);

				&.p-placeholder {
					@include font(14px, 400, #808b9a);
				}
			}
		}

		&:hover {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			border-color: transparent;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
		}

		&:global(.p-invalid) {
			&,
			&:hover {
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}
	}
}

.dropdown_panel {
	&.w100 {
		width: 100%;
	}

	:global {
		.p-dropdown-items-wrapper {
			&::-webkit-scrollbar {
				width: 0.3em;
			}

			.p-dropdown-items {
				padding: 12px;

				.p-dropdown-item {
					&:not(:last-child) {
						margin-bottom: 6px;
					}

					padding: 10px 8px;
					@include borderRadius(6px);

					.option_template {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						gap: 12px;

						.radio {
							flex-shrink: 0;
							width: 18px;
							height: 18px;
							display: flex;
							align-items: center;
							justify-content: center;
							@include borderRadius(100%);
							background-color: #fff;
							border: 2px solid #b5bec9;
							box-shadow: 0px 1px 8px 0px #020e1f0a;

							.circle {
								background-color: #fff;
								width: 8px;
								height: 8px;
								@include borderRadius(100%);
							}
						}

						span {
							@include font(14px, 500, #202326);
						}
					}

					&:hover {
						background-color: #f7f7f7 !important;
					}

					&.p-highlight {
						background-color: transparent !important;

						.option_template {
							.radio {
								background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
								border: 0;
							}
						}
					}
				}
			}
		}
	}
}

.option_left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;

	.name_holder {
		flex-shrink: 0;
		width: 32px;
		height: 32px;
		background-color: #8392a5;
		@include borderRadius(100%);
		display: flex;
		align-items: center;
		justify-content: center;
		@include font(12px, 700, #fff);
	}

	.docInfo {
		display: flex;
		flex-direction: column;

		span {
			&:first-child {
				@include font(14px, 500, #202326 !important);
			}

			&:last-child {
				@include font(14px, 400, #808b9a !important);
			}
		}
	}
}
