@import "assets/scss/vars.scss";

.doctor_mainLayout {
	background-color: #f8f9fc;
	min-height: 100vh;
	display: flex;

	main {
		display: flex;
		flex-direction: column;
		width: calc(100vw - 250px);

		@media (max-width: 1024.98px) {
			width: calc(100vw - 72px);
		}

		.outlet_wrapper {
			flex-grow: 1;
			height: calc(100vh - 65px);
			overflow-y: auto;
			overflow-x: hidden;

			@media (max-width: 991.98px) {
				height: calc(100vh - 126px);
			}
		}
	}
}
