@import "assets/scss/vars.scss";

.auth_layout {
	position: relative;

	.auth_lang {
		position: absolute;
		top: 40px;
		#{$ltr} & {
			right: 50px;

			@media (max-width: 575.98px) {
				right: 20px;
			}
		}
		#{$rtl} & {
			left: 50px;

			@media (max-width: 575.98px) {
				left: 20px;
			}
		}
		-webkit-padding-start: 0;
		        padding-inline-start: 0;
		-webkit-margin-start: 0;
		        margin-inline-start: 0;
		-webkit-border-start: 0;
		        border-inline-start: 0;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);

		@media (max-width: 575.98px) {
			top: 20px;
		}

		[class*="lang_btn"] {
			padding: 7px 12px;

			&:hover {
				background-color: #e6e7e9;
			}
		}
	}
}
