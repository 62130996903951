@import "assets/scss/vars.scss";

.sidebar {
	background-color: #f8f9fc;
	padding: 72px 50px;
	display: flex;
	flex-direction: column;
	flex-basis: 31.3%;
	gap: 72px;
	flex-shrink: 0;

	@media (max-width: 767.98px) {
		padding: 72px 30px;
		flex-basis: 41.3%;
	}

	@media (max-width: 575.98px) {
		padding: 30px;
		flex-basis: 100%;
	}

	&_top {
		flex-grow: 1;

		.sidebar_head {
			margin-bottom: 16px;

			h1 {
				@include font(24px, 800, #202326);
				margin-bottom: 16px;
			}

			p {
				@include font(14px, 500, #808b9a);
				margin-bottom: 0;
			}
		}

		.sidebar_links {
			padding: 24px 0;

			.sidebar_link {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 12px;
				position: relative;

				&::before {
					content: "";
					width: 1px;
					height: calc(100% - 40px);
					background: #dce0e5;
					display: inline-block;
					position: absolute;
					top: 28px;
					margin: 6px 0;
					#{$ltr} & {
						left: 14px;
					}
					#{$rtl} & {
						right: 14px;
					}
				}

				.bullet {
					flex-shrink: 0;
					width: 28px;
					height: 28px;
					@include borderRadius(100%);
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #dce0e5;
					overflow: hidden;

					&::before {
						content: "";
						flex-shrink: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 24px;
						@include borderRadius(100%);
						background-color: #f8f9fc;
					}
				}

				.txt {
					h5 {
						@include font(14px, 600, #808b9a);
						margin-bottom: 1px;
					}

					p {
						@include font(14px, 500, #808b9a);
						margin-bottom: 0;
					}
				}

				&:not(:last-child) {
					padding-bottom: 25px;
				}

				&:last-child {
					&::before {
						display: none;
					}
				}

				&.active,
				&:global(.active) {
					.bullet {
						background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					}

					.txt {
						h5 {
							@include font(14px, 700, #202326);
						}
					}
				}

				&.bullet_checked {
					.bullet {
						background-image: url("~/public/img/check-circle-green.svg");
						background-size: cover;
						background-repeat: no-repeat;
						background-color: transparent;

						&::before {
							display: none;
						}
					}

					.txt {
						h5 {
							@include font(14px, 600, #202326);
						}
					}
				}
			}
		}
	}

	.have_account {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;

		span {
			@include font(14px, 400, #202326);
		}

		a {
			@include font(14px, 700, transparent);
			@extend %gradientText;
		}
	}
}
