@import "assets/scss/vars.scss";

.our_services {
	padding: 120px 0 56px;

	.txt_side {
		.txt {
			margin-bottom: 20px;

			h2 {
				@include font(32px, 800, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}
	}

	.service_box {
		box-shadow: 0px 10px 20px 0px #020e1f0a, 0px 0px 1px 0px #020e1f1f;
		@include borderRadius(8px);
		overflow: hidden;
		display: flex;
		flex-direction: column;
		margin-bottom: 40px;

		.service_img {
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
			width: 100%;
			height: 280px;

			@media (max-width: 575.98px) {
				height: 475px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				@include transition(0.3s);
			}
		}

		.service_name {
			padding: 20px 16px;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			@include font(16px, 800, #202326);
			@include transition(0.3s);
		}

		&:hover {
			.service_img {
				img {
					@include transform(scale(1.1));
				}
			}

			.service_name {
				color: #0e78e4;
			}
		}
	}
}
