@import "assets/scss/vars.scss";

.custom_selectBtn {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;
	flex-wrap: nowrap;
	overflow-x: auto;
	padding: 2px;

	&::-webkit-scrollbar {
		height: 0em;
	}

	:global {
		.p-button {
			padding: 10px 16px;
			background-color: #fff !important;
			border: 1px solid #dce0e5 !important;
			@include borderRadius(8px !important);
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 6px;
			flex-shrink: 0;
			box-shadow: none !important;

			img {
				flex-shrink: 0;
				width: 16px;
				height: 16px;
			}

			span {
				@include font(12px, 600, #202326);
			}

			&.p-highlight {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%) !important;

				img {
					filter: invert(0) brightness(200%);
					-webkit-filter: invert(0) brightness(200%);
				}

				span {
					color: #fff;
				}
			}
		}
	}
}
