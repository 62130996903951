@import "assets/scss/vars.scss";

.reminders {
	.head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 24px;

		@media (max-width: 280.98px) {
			flex-wrap: wrap;
		}

		h2 {
			@include font(14px, 700, #202326);
			margin-bottom: 0;
		}
	}

	.reminders_boxes {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 24px;

		@media (max-width: 575.98px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
}
