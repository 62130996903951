@import "assets/scss/vars.scss";

.appointments_requests {
	padding: 20px 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	height: 100%;

	.head {
		margin-bottom: 24px;

		h3 {
			@include font(16px, 800, #202326);
			margin-bottom: 0;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

		.appointments {
			max-height: calc(100vh - 440px); 
   			overflow-y: auto;
			.day_container {
				margin-bottom: 20px; 
				display: grid;
				gap: 6px;
			}
		.appointment_row {
			&:not(:last-child) {
				margin-bottom: 24px;
			}

			.date {
				@include font(14px, 600, #202326);
				margin-bottom: 24px;
			}

			.appointments_boxes {
				.reject {
					padding: 7px;
					background-color: #fff;
					border: 1px solid #dc3545;
					@include borderRadius(6px);

					img {
						width: 24px;
						height: 24px;
					}

					&:hover {
						background-color: #fbebec;
					}
				}

				.approve {
					padding: 7px;
					background-color: #48bb78;
					border: 1px solid transparent;
					@include borderRadius(6px);

					img {
						width: 24px;
						height: 24px;
					}

					&:hover {
						background-color: #6dc993;

						img {
							filter: invert(1);
							-webkit-filter: invert(1);
						}
					}
				}
			}
		}
	}
}
