@import "assets/scss/vars.scss";

.step_content {
	padding: 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	display: flex;
	flex-direction: column;
	min-height: 50vh;

	.top_content {
		flex-grow: 1;
		margin-bottom: 20px;

		.interview_questions {
			.interview_head {
				margin-bottom: 20px;

				h2 {
					@include font(18px, 800, #202326);
					margin-bottom: 20px;
				}

				p {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			.interview_body {
				.qty_row {
					&:not(:last-child) {
						border-bottom: 1px solid #dce0e5;
					}

					padding: 16px 0;
				}
			}
		}
	}

	.step_actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		padding-top: 20px;
		border-top: 1px solid #dce0e5;

		.backBtn,
		.nextBtn {
			img {
				#{$rtl} & {
					@include transform(rotate(180deg));
				}
			}
		}
	}
}
