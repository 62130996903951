@import "assets/scss/vars.scss";

.possible_conditions {
	padding: 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	margin-bottom: 20px;

	&_head {
		margin-bottom: 20px;

		h3 {
			@include font(14px, 700, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	&_rows {
		.possible_conditions_row {
			&:not(:last-child) {
				margin-bottom: 20px;
			}

			h6 {
				@include font(14px, 600, #808b9a);
				margin-bottom: 2px;
			}

			h5 {
				@include font(14px, 700, #202326);
				margin-bottom: 0;
			}
		}
	}
}
