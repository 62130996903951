@import "assets/scss/vars.scss";

.reminder_box {
	background-color: #fff;
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	display: flex;

	@media (max-width: 280.98px) {
		flex-direction: column;
	}

	.time {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		padding: 24px 20px;
		-webkit-border-end: 1px solid #d4f0f3;
		border-inline-end: 1px solid #d4f0f3;
		@include font(14px, 700, #202326);

		@media (max-width: 280.98px) {
			-webkit-border-end: 0;
			border-inline-end: 0;
			border-bottom: 1px solid #d4f0f3;
		}
	}

	.reminder_info {
		flex-grow: 1;
		padding: 14px 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;

		.icon {
			flex-shrink: 0;
			width: 24px;
			height: 24px;
		}

		.txt {
			flex-grow: 1;

			h5 {
				@include font(14px, 700, #202326);
				margin-bottom: 2px;
				line-height: 20px;
			}

			p {
				@include font(14px, 400, #808b9a);
				line-height: 20px;
			}
		}
	}
}
