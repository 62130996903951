@import "assets/scss/vars.scss";

.bread_crumb {
	padding: 32px 0;

	:global {
		.p-breadcrumb {
			background-color: transparent;
			padding: 0;
			border: 0;
			overflow: hidden;

			.p-breadcrumb-list {
				.p-menuitem-separator {
					.p-breadcrumb-chevron {
						#{$ltr} & {
							@include transform(rotate(115deg));
						}
						#{$rtl} & {
							@include transform(rotate(65deg));
						}
						@include font(16px, 400, #808b9a);
					}
				}

				li {
					a,
					.p-menuitem-text {
						box-shadow: none !important;
						@include font(14px, 400, #808b9a);
					}

					&:last-child {
						a,
						.p-menuitem-text {
							@include font(14px, 500, #202326);
						}
					}

					&.p-menuitem {
						&:not(:last-child) {
							a {
								&:hover {
									&,
									.p-menuitem-text {
										@include font(14px, 500, #202326);
										text-decoration: underline !important;
									}
								}
							}
						}
					}

					&.p-breadcrumb-home {
						a {
							&:hover {
								&,
								.p-menuitem-text {
									@include font(14px, 500, #202326);
									text-decoration: underline !important;
								}
							}
						}
					}
				}
			}
		}
	}
}
