@import "assets/scss/vars.scss";

.summary_card {
	padding: 24px;
	background: linear-gradient(0deg, #ffffff, #ffffff),
		linear-gradient(0deg, #dce0e5, #dce0e5);
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(8px);

	&_head {
		padding-bottom: 24px;
		border-bottom: 1px solid #dce0e5;
		@include font(16px, 800, #202326);
	}

	&_body {
		padding: 24px 0;

		.summary_row {
			&:not(:last-child) {
				margin-bottom: 16px;
			}

			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 8px;

			.txt {
				@include font(14px, 400, #202326);
			}

			.val {
				@include font(14px, 600, #202326);
			}

			&.total {
				.val {
					@include font(16px, 800, #202326);
				}
			}
		}
	}

	&_footer {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
}
