@import "assets/scss/vars.scss";

.doctor_dashboard {
	padding: 32px;

	h1 {
		@include font(24px, 800, #202326);
		margin-bottom: 24px;
	}

	.welcome_sec {
		padding: 13px 32px 0;
		background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
		@include borderRadius(6px);
		margin-bottom: 24px;

		.txt {
			padding: 80px 0;

			h2 {
				@include font(24px, 800, #fff);
				margin-bottom: 6px;
			}

			p {
				@include font(14px, 500, #fff);
				margin-bottom: 0;
			}
		}

		.welcome_img {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			height: 100%;

			img {
				width: 100%;
			}
		}
	}

	.get_started {
		h3 {
			@include font(16px, 800, #202326);
			margin-bottom: 24px;
		}

		&_content {
			background-color: #fff;
			border: 1px solid #dce0e5;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			@include borderRadius(6px);

			.get_started_links {
				border-bottom: 1px solid #dce0e5;
				padding: 24px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: wrap;
				gap: 12px;

				.get_started_link {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					position: relative;

					.bullet {
						flex-shrink: 0;
						width: 32px;
						height: 32px;
						@include borderRadius(100%);
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #dce0e5;
						position: relative;

						&::before {
							content: "";
							width: 28px;
							height: 28px;
							@include borderRadius(100%);
							background-color: #fff;
							display: flex;
							align-items: center;
							justify-content: center;
							position: absolute;
							top: 50%;
							left: 50%;
							@include transform(translate(-50%, -50%));
						}

						span {
							position: relative;
							@include font(14px, 500, #808b9a);
						}
					}

					.txt {
						@include font(14px, 500, #808b9a);
					}

					&:not(:last-child) {
						&::after {
							content: "";
							width: 54px;
							height: 1px;
							background-color: #dce0e5;
						}
					}

					&:global(.active) {
						.bullet {
							background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);

							span {
								@include font(14px, 700, #202326);
							}
						}

						.txt {
							@include font(14px, 700, #202326);
						}
					}

					&.currentStep {
						.bullet {
							background-image: url("~/public/img/check-circle-green.svg");
							background-size: cover;
							background-repeat: no-repeat;
							background-color: transparent;

							&::before,
							span {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
