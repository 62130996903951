@import "assets/scss/vars.scss";

.profile_sideBar {
	padding: 24px;
	background-color: #fff;
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(8px);

	.user_info {
		padding-bottom: 16px;
		margin-bottom: 16px;
		border-bottom: 1px solid #dce0e5;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;

		.user_img {
			width: 120px;
			height: 120px;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.txt {
			h5 {
				@include font(16px, 800, #202326);
				margin-bottom: 2px;
				text-align: center;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
				text-align: center;
			}
		}
	}

	.profile_links {
		padding-bottom: 16px;
		margin-bottom: 16px;
		border-bottom: 1px solid #dce0e5;

		.profile_link {
			&:not(:last-child) {
				margin-bottom: 8px;
			}

			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 12px;
			gap: 8px;
			@include transition(0.3s);
			position: relative;

			&::before {
				content: "";
				height: 100%;
				width: 3px;
				background: linear-gradient(180deg, #45cc9c 0%, #0c75e6 100%);
				position: absolute;
				@include borderRadius(50px);
				top: 0;
				#{$ltr} & {
					left: 0;
				}
				#{$rtl} & {
					right: 0;
				}
				opacity: 0;
				visibility: hidden;
			}

			img {
				flex-shrink: 0;
				width: 100%;
				max-width: 20px;

				&.gray {
					display: flex;
				}

				&.gradient {
					display: none;
				}
			}

			span {
				@include font(14px, 500, #202326);
				@include transition(0.3s);
				text-transform: capitalize;
			}

			&:hover {
				background-color: #f7f7f7;
			}

			&:global(.active) {
				background-color: #eaf8f9;

				&::before {
					opacity: 1;
					visibility: visible;
				}

				img {
					&.gray {
						display: none;
					}

					&.gradient {
						display: flex;
					}
				}

				span {
					font-weight: 600;
				}
			}
		}
	}

	.logout_btn {
		padding: 7px 14px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 6px;
		width: 100%;
		background-color: transparent !important;
		box-shadow: none !important;
		border: 0 !important;

		img {
			flex-shrink: 0;
			width: 100%;
			max-width: 24px;
			@include transition(0.3s);

			#{$rtl} & {
				@include transform(rotate(180deg));
			}
		}

		span {
			@include font(14px, 700, #dc3545);
		}
	}
}
