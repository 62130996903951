@import "assets/scss/vars.scss";

.field_holder {
	position: relative;

	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.input_box_arrow {
		position: relative;
	}

	.input_box {
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		@include transition(0.3s);

		:global {
			.p-calendar {
				border: 0;
				@include borderRadius(6px);
				box-shadow: none !important;
				position: relative;
			}

			.p-inputtext {
				background-color: #fff !important;
				border: 0;
				box-shadow: none !important;
				position: relative;
				@include font(14px, 600, #202326);
				@include borderRadius(6px);
				padding: 8px;
				-webkit-padding-end: 36px;
				padding-inline-end: 36px;

				&.p-disabled,
				&[disabled] {
					background-color: #f8f9fc !important;
				}
			}
		}

		&:hover {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			border-color: transparent;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
		}

		&:global(.p-invalid) {
			&,
			&:hover {
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}
	}

	.time_icon {
		position: absolute;
		display: block;
		width: 100%;
		max-width: 20px;
		#{$ltr} & {
			right: 8px;
		}
		#{$rtl} & {
			left: 8px;
		}
		top: 50%;
		@include transform(translateY(-50%));
		pointer-events: none;
	}

	&:global(.closed) {
		.input_box {
			&,
			&:hover {
				background-color: #f8f9fc !important;
				background: #f8f9fc !important;
				border: 1px solid #dce0e5;
			}
		}

		.time_icon {
			opacity: 0.5;
		}
	}
}
