@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.input_box {
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		overflow: hidden;
		@include transition(0.3s);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		position: relative;

		.search_icon {
			flex-shrink: 0;
			width: 100%;
			max-width: 20px;
			position: absolute;
			z-index: 1;
			top: 50%;
			@include transform(translateY(-50%));
			#{$ltr} & {
				left: 12px;
			}
			#{$rtl} & {
				right: 12px;
			}
		}

		:global {
			.p-inputtext {
				background-color: #fff !important;
				border: 0;
				box-shadow: none !important;
				@include borderRadius(6px);
				position: relative;
				padding-inline-start: 40px;
			}
		}

		&:hover {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			border-color: transparent;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
		}

		&:global(.p-invalid) {
			&,
			&:hover {
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}
	}
}
