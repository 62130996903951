@import "assets/scss/vars.scss";

.lab_results {
    margin-bottom: 32px;

    .lab_results_card {
        padding: 16px 20px;
        background-color: #fff;
        border: 1px solid #dce0e5;
        box-shadow: 0px 1px 8px 0px #020e1f0a;
        @include borderRadius(6px);

        .result_row {
            &:not(:last-child) {
                margin-bottom: 22px;
            }

            .icon_name {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 6px;

                img {
                    flex-shrink: 0;
                    width: 24px;
                    height: 24px;
                }

                .name {
                    @include font(14px, 500, #202326);
                    word-break: break-word;
                }
            }

            .date_action {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 10px;

                .date {
                    @include font(14px, 600, #808b9a);
                    word-break: break-word;
                }

                .action {
                    flex-shrink: 0;

                    .action_btn {
                        width: 20px;
                        height: 20px;
                        padding: 3px 9px;
                        font-size: 14px;
                        background-color: #fff;
                        border: 0;
                        color: #8392a5;

                        :global(.p-button-icon) {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

.action_menu {
    background-color: #fff;
    box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
    border: 0.5px solid #dce0e5 !important;
    @include borderRadius(8px);
    padding: 12px;
    width: 15rem;

    :global {
        .p-menu-list {
            .p-menuitem {
                .p-menuitem-link {
                    padding: 10px 8px;
                    @include borderRadius(4px);
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 12px;
                    width: 100%;
                    background-color: #fff !important;
                    border: 0 !important;

                    img {
                        flex-shrink: 0;
                        width: 18px;
                        height: 18px;
                    }

                    span {
                        @include font(14px, 500, transparent);
                        text-transform: capitalize;

                        &.update {
                            color: #202326;
                        }

                        &.delete {
                            color: #dc3545;
                        }
                    }

                    &:hover {
                        background-color: #f3f4f6 !important;
                    }
                }
            }
        }
    }
}

.custom_upload_file {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed #dfe1e3;
    @include borderRadius(6px);
    padding: 24px;
    background-color: #f5f7fa;
    cursor: pointer;

    input[type="file"] {
        display: none;
    }

    .upload_txt {
        margin-bottom: 16px;

        h3 {
            @include font(16px, 800, #202326);
            margin-bottom: 2px;
            text-align: center;
        }

        h4 {
            @include font(14px, 400, #808b9a);
            margin-bottom: 0;
            text-align: center;
        }
    }

    .upload_btn {
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        background-color: #fff !important;
        box-shadow: 0px 4px 6px 0px #1f29370a;
        border: 1px solid #e5e7eb !important;
        @include borderRadius(6px);

        img {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
        }

        span {
            @include font(16px, 700, #202326);
        }
    }
}

.left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    .file_icon {
        flex-shrink: 0;
        width: 100%;
        max-width: 32px;
    }

    .file_data {
        h5 {
            @include font(14px, 700, #202326);
            margin-bottom: 2px;

            @media (max-width: 375.98px) {
                font-size: 12px;
            }
        }

        h6 {
            @include font(14px, 400, #808b9a);
            margin-bottom: 0;

            @media (max-width: 375.98px) {
                font-size: 12px;
            }
        }
    }
}

.delete_btn {
    flex-shrink: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;

    &:hover {
        background: transparent !important;
    }
}