@import "assets/scss/vars.scss";

.consult_doctor {
	padding: 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	margin-bottom: 20px;

	&_title {
		@include font(18px, 800, #202326);
		margin-bottom: 20px;
	}

	&_desc {
		@include font(16px, 400, #202326);
		margin-bottom: 20px;
	}

	.recommendation_box {
		background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
		@include borderRadius(12px);
		padding: 1px;
		overflow: hidden;

		&_content {
			@include borderRadius(12px);
			background-color: #fff;

			.recommendation_rows {
				.recommendation_row {
					&:not(:last-child) {
						border-bottom: 1px solid #dce0e5;
					}

					padding: 16px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 10px;

					@media (max-width: 375.98px) {
						flex-wrap: wrap;
					}

					img {
						flex-shrink: 0;
						width: 24px;
						height: 24px;
					}

					.recommendation_text {
						h5 {
							@include font(14px, 600, #808b9a);
							margin-bottom: 2px;
						}

						h4 {
							@include font(16px, 800, #202326);
							margin-bottom: 0;
						}
					}
				}
			}

			.srch_btn {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
		}
	}
}
