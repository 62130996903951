@import "assets/scss/vars.scss";

.fullCalendar_holder {
	padding: 24px 16px;
	background-color: #fff;
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);

	.fullCalendar_wrapper {
		margin-bottom: 140px;

		:global {
			.fc {
				&-toolbar {
					margin-bottom: 16px;
					gap: 16px;

					&-chunk {
						&:nth-child(1) {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							gap: 12px;

							.fc-button {
								padding: 0;
								width: 24px;
								height: 24px;
								flex-shrink: 0;
								display: flex;
								align-items: center;
								justify-content: center;
								font-size: 12px;
								margin: 0;
								background-color: #fff !important;
								border: 0;
								color: #020e1f !important;

								.fc-icon {
									font-size: 12px;
									color: #020e1f !important;
								}
							}

							.fc-toolbar-title {
								margin: 0;
								@include font(14px, 500, #202326);
								display: flex;
								align-items: center;
								justify-content: center;
								gap: 12px;

								span {
									@include font(20px, 800, #202326);
								}
							}
						}

						&:nth-child(2) {
							flex-grow: 1;
							display: flex;
							justify-content: flex-end;

							:global {
								.fc-today-button {
									padding: 5px 12px;
									background-color: #fff;
									border: 1px solid #e5e7eb;
									@include borderRadius(6px);
									display: flex;
									align-items: center;
									justify-content: center;
									@include font(14px, 700, #020e1f);
									text-transform: capitalize;
									opacity: 1;
								}
							}
						}

						&:nth-child(3) {
							padding: 2px;
							background-color: #f3f4f6;
							@include borderRadius(6px);
							display: flex;
							gap: 12px;

							:global {
								.fc-button {
									margin: 0;
									padding: 4px 12px;
									background-color: transparent;
									border: 1px solid transparent;
									@include font(14px, 700, #676e79);
									text-transform: capitalize;

									&-active {
										background-color: #fff !important;
										border-color: #dce0e5 !important;
										color: #020e1f !important;
										box-shadow: 0px 4px 12px -2px #3232470f,
											0px 0px 1px 0px #0c1a4b1a !important;
									}
								}
							}
						}
					}
				}

				&-view-harness {
					.fc-view {
						.fc-scrollgrid {
							thead {
								.fc-scrollgrid-section-header {
									th {
										.fc-scroller {
											-webkit-padding-end: 0.2em;
											padding-inline-end: 0.2em;

											&::-webkit-scrollbar {
												width: 0.2em;
											}
										}

										.fc-col-header {
											width: 100% !important;

											thead {
												tr {
													th {
														background-color: #fff;
														border-color: #dce0e5;

														.fc-col-header-cell-cushion {
															padding: 0;
															display: block;

															.dayHeader_content {
																padding: 20px 22px;
																display: flex;
																flex-direction: column;
																align-items: center;
																justify-content: center;
																gap: 2px;

																span {
																	white-space: nowrap;

																	&.day {
																		@include font(14px, 500, #202326);
																		text-transform: capitalize;
																	}

																	&.date {
																		@include font(12px, 400, #808b9a);
																		text-transform: capitalize;
																	}
																}
															}
														}

														&.fc-day-today {
															.week_type {
																position: relative;
																background-color: #eaf8f9;

																&::before {
																	content: "";
																	width: 42px;
																	height: 3px;
																	background: linear-gradient(
																		90deg,
																		#45cc9c 0%,
																		#0c75e6 100%
																	);
																	@include borderRadius(6px);
																	position: absolute;
																	bottom: 0;
																	left: 50%;
																	@include transform(translateX(-50%));
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}

							tbody {
								.fc-scrollgrid-section-body {
									td {
										.fc-scroller-harness {
											.fc-scroller {
												-webkit-padding-end: 0.2em;
												padding-inline-end: 0.2em;

												&::-webkit-scrollbar {
													width: 0.2em;
												}
											}

											.fc-timegrid-body {
												width: 100% !important;

												.fc-timegrid-slots {
													table {
														width: 100% !important;

														tbody {
															tr {
																.fc-timegrid-slot {
																	border-color: #dce0e5;

																	&.fc-timegrid-col {
																		&.fc-day-today {
																			background-color: #eaf8f9;
																		}
																	}

																	&-label-cushion {
																		@include font(14px, 500, #202326);
																	}
																}
															}
														}
													}
												}

												.fc-timegrid-cols {
													table {
														width: 100% !important;

														tbody {
															tr {
																td {
																	.fc-event {
																		background-color: transparent !important;
																		border: 0 !important;
																		@include borderRadius(0);
																		box-shadow: none;

																		&-main {
																			padding: 0;
																			cursor: pointer;
																			.event_box {
																				padding: 6px 8px;
																				-webkit-border-start: 3px solid
																					transparent;
																				border-inline-start: 3px solid
																					transparent;
																				height: 100%;
																				display: flex;
																				flex-direction: column;
																				gap: 2px;

																				.event_title {
																					@include font(12px, 600, #202326);
																					margin-bottom: 0;
																					text-transform: capitalize;
																				}

																				.event_desc {
																					@include font(12px, 400, #808b9a);
																					margin-bottom: 0;
																					text-transform: capitalize;
																				}

																				&.inClinic {
																					background-color: #eaf8f9;
																					border-color: #29b6c2;
																				}

																				&.videoCall {
																					background-color: #fbebec;
																					border-color: #dc3545;
																				}
																			}
																		}
																	}

																	&.fc-day-today {
																		background-color: #eaf8f9;

																		.event_box {
																			&.inClinic {
																				background-color: #d4f0f3;
																				border-color: #29b6c2;
																			}

																			&.videoCall {
																				background-color: #f8d7da;
																				border-color: #dc3545;
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}

											.fc-daygrid-body {
												width: 100% !important;

												table {
													width: 100% !important;

													tbody {
														tr {
															td {
																.fc-daygrid-day-frame {
																	padding: 12px;

																	.fc-daygrid-day-top {
																		flex-direction: row;

																		.fc-daygrid-day-number {
																			@include font(14px, 500, #202326);
																			width: 30px;
																			height: 30px;
																			@include borderRadius(100%);
																			display: flex;
																			align-items: center;
																			justify-content: center;
																		}
																	}

																	.custom-more-link {
																		margin-top: 8px;
																		padding: 0;
																		display: flex;
																		align-items: center;
																		justify-content: flex-start;
																		@include font(12px, 600, #202326);
																	}
																}

																.fc-event {
																	padding: 0;
																	background-color: transparent;

																	.event_box {
																		padding: 6px 8px;
																		-webkit-border-start: 3px solid transparent;
																		border-inline-start: 3px solid transparent;
																		width: 100%;
																		display: flex;
																		flex-direction: column;
																		gap: 2px;
																		cursor: pointer;

																		.event_title {
																			@include font(12px, 600, #202326);
																			margin-bottom: 0;
																			text-transform: capitalize;
																			overflow: hidden; 
																				text-overflow: ellipsis; 
																				white-space: nowrap; 
																				max-width: 100%; 
																				&:hover {
																				white-space: normal;
																				max-width: none; 
																				}
																		}

																		.event_desc {
																			@include font(12px, 400, #808b9a);
																			margin-bottom: 0;
																			text-transform: capitalize;
																		}

																		&.inClinic {
																			background-color: #eaf8f9;
																			border-color: #29b6c2;
																		}

																		&.videoCall {
																			background-color: #fbebec;
																			border-color: #dc3545;
																		}
																	}
																}

																&.fc-day-today {
																	background-color: #fff;

																	.fc-daygrid-day-frame {
																		.fc-daygrid-day-top {
																			.fc-daygrid-day-number {
																				background: linear-gradient(
																					90deg,
																					#45cc9c 0%,
																					#0c75e6 100%
																				);
																				@include font(14px, 700, #fff);
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}

					.fc-more-popover {
						padding: 20px 16px;
						background-color: #fff;
						border: 0.5px solid #dce0e5;
						box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
						@include borderRadius(8px);

						.fc-popover-header {
							padding: 0;
							border: 0;
							margin-bottom: 16px;
							background-color: #fff;

							.fc-popover-title {
								display: flex;
								flex-direction: column;
								@include font(14px, 400, #808b9a);
								margin: 0;

								& > span {
									@include font(16px, 800, #202326);
									margin-bottom: 2px;
								}
							}

							.fc-popover-close {
								display: none;
							}
						}

						.fc-popover-body {
							padding: 0;
							border: 0;

							.fc-daygrid-event-harness {
								&:not(:last-child) {
									margin-bottom: 16px;
								}

								.fc-event {
									padding: 0;
									background-color: transparent;

									.event_box {
										padding: 6px 8px;
										-webkit-border-start: 3px solid transparent;
										border-inline-start: 3px solid transparent;
										width: 100%;
										display: flex;
										flex-direction: column;
										gap: 2px;

										.event_title {
											@include font(12px, 600, #202326);
											margin-bottom: 0;
											text-transform: capitalize;
										}

										.event_desc {
											@include font(12px, 400, #808b9a);
											margin-bottom: 0;
											text-transform: capitalize;
										}

										&.inClinic {
											background-color: #eaf8f9;
											border-color: #29b6c2;
										}

										&.videoCall {
											background-color: #fbebec;
											border-color: #dc3545;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.fullCalendar_labels {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 16px;

		.fullCalendar_label {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;

			.bullet {
				flex-shrink: 0;
				width: 9px;
				height: 9px;
				@include borderRadius(100%);

				&.inClinic {
					background-color: #29b6c2;
				}

				&.videoCall {
					background-color: #dc3545;
				}
			}

			.label {
				@include font(12px, 600, #202326);
				text-transform: capitalize;
			}
		}
	}
}
