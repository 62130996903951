@import "assets/scss/vars.scss";

.user_dropdown {
	flex-shrink: 0;
	position: relative;

	.menu_btn {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		padding: 0;
		background-color: transparent !important;
		border: 0;
		@include font(14px, 700, #202326 !important);

		.profile_icon {
			flex-shrink: 0;
			width: 36px;
			height: 36px;
			@include borderRadius(100%);
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
		}

		.name_arrow {
			padding: 8px 2px;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 10px;

			.user_name {
				@include font(14px, 700, #202326 !important);

				@media (max-width: 375.98px) {
					font-size: 12px;
				}
			}

			:global {
				.pi {
					width: 18px;
					height: 18px;
					font-size: 18px;
				}
			}
		}
	}
}

.user_menu {
	padding: 12px;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 1px solid #dce0e5 !important;
	background-color: #fff !important;
	@include borderRadius(8px);

	:global {
		.p-menu {
			&-list {
				.p-menuitem {
					&-link {
						padding: 10px 8px;
						box-shadow: none !important;
						justify-content: flex-start;
						gap: 12px;

						&:hover {
							background-color: #f7f7f7;
						}
					}

					&-icon {
						margin: 0 !important;
						font-size: 18px;
						height: 18px;
						color: #8392a5;
						width: 100%;
						max-width: 18px;
						flex-shrink: 0;
					}

					&-text {
						@include font(14px, 500, #202326);
					}
				}
			}
		}
	}
}
