@import "assets/scss/vars.scss";

.details_sec {
	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 32px;
	}

	.details_holder {
		padding: 24px;
		background-color: #fff;
		border: 0.7px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);

		.details_row {
			&:not(:last-child) {
				margin-bottom: 12px;
				border-bottom: 1px solid #dce0e5;
			}

			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 20px;
			padding-bottom: 16px;

			@media (max-width: 600.98px) {
				flex-direction: column;
			}

			.circle {
				flex-shrink: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				width: 44px;
				height: 44px;
				@include borderRadius(100%);
				@include font(18px, 600, #fff);
				text-transform: uppercase;
				overflow: hidden;
			}

			.info {
				.name {
					@include font(14px, 600, #202326);
					margin-bottom: 7px;
				}

				.rate_time {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					margin-bottom: 10px;

					.time {
						@include font(12px, 500, #808b9a);
					}
				}

				.desc {
					@include font(14px, 400, #202326);
					margin-bottom: 0;
				}
			}

			&:nth-child(3n + 1) {
				.circle {
					background-color: #29b6c2;
				}
			}

			&:nth-child(3n + 2) {
				.circle {
					background-color: #8392a5;
				}
			}

			&:nth-child(3n + 3) {
				.circle {
					background-color: #dc3545;
				}
			}
		}
	}
}
