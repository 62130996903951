@import "assets/scss/vars.scss";

.current_medications {
	&_card {
		padding: 16px;
		background-color: #fff;
		border: 1px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);

		.medication_row {
			&:not(:last-child) {
				margin-bottom: 6px;
			}

			padding: 8px 0;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 6px;

			img {
				flex-shrink: 0;
				width: 24px;
				height: 24px;
			}

			span {
				@include font(14px, 500, #202326);
			}
		}
	}
}
