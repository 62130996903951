@import "assets/scss/vars.scss";

.inputs_wrapper {
	padding-bottom: 32px;
	margin-bottom: 32px;
	border-bottom: 1px solid #e5e7eb;
}

.inpt_lbl {
	@include font(14px, 400, #202326);
	margin-bottom: 8px;
}
