@import "assets/scss/vars.scss";

.doctorGrid_card {
	background-color: #fff;
	@include borderRadius(6px);
	overflow: hidden;

	.doctor_img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 332px;
		overflow: hidden;
		position: relative;

		@media (max-width: 575.98px) {
			height: 432px;
		}

		@media (max-width: 300.98px) {
			height: 332px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.fav_btn {
			position: absolute;
			width: 28px;
			height: 28px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: rgba(#fff, 0.5);
			padding: 4px;
			@include borderRadius(6px);
			border: 0;
			top: 10px;
			#{$ltr} & {
				right: 10px;
			}
			#{$rtl} & {
				left: 10px;
			}

			:global {
				.p-button-icon {
					width: 20px;
					height: 20px;
					font-size: 20px;
					@extend %gradientText;
				}
			}
		}
	}

	.card_body {
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 16px;

		.top {
			.name_speciality {
				margin-bottom: 16px;

				.name {
					@include font(16px, 800, #202326);
					margin-bottom: 2px;
				}

				.speciality {
					@include font(14px, 400, #808b9a);
				}
			}

			.rate_reviews {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 8px;

				.reviews {
					@include font(12px, 500, #808b9a);
				}
			}
		}

		.info_list {
			.info_row {
				&:not(:last-child) {
					margin-bottom: 5px;
				}

				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 2px;

				img {
					flex-shrink: 0;
					width: 100%;
					max-width: 16px;
				}

				span {
					@include font(14px, 400, #202326);
				}
			}
		}

		.card_btns {
			display: flex;
			flex-direction: column;
			gap: 12px;
		}
	}
}
