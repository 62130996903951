@import "assets/scss/vars.scss";

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	line-height: normal;
}

* {
	#{$ltr} & {
		font-family: "Mulish", sans-serif, "primeicons" !important;
	}
	#{$rtl} & {
		font-family: "IBM Plex Sans Arabic", sans-serif, "primeicons" !important;
	}
	@include transition(0.3s);
}

html {
	scroll-behavior: smooth;
}

body {
	#{$ltr} & {
		font-family: "Mulish", sans-serif, "primeicons" !important;
	}
	#{$rtl} & {
		font-family: "IBM Plex Sans Arabic", sans-serif, "primeicons" !important;
	}
	padding: 0;
	margin: 0;
	text-align: start;
	min-height: 100vh;
	font-size: 14px;
}

#root {
	height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}

a {
	text-decoration: none !important;
	transition: all 0.3s;
}

*::-webkit-input-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: rgba(#808b9a, 0.7);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

*::-moz-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: rgba(#808b9a, 0.7);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

*:-ms-input-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: rgba(#808b9a, 0.7);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

*::-ms-input-placeholder {
	font-size: 14px;
	font-weight: 400;
	color: rgba(#808b9a, 0.7);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

*::placeholder {
	font-size: 14px;
	font-weight: 400;
	color: rgba(#808b9a, 0.7);
	transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

/* Custom Scroll bar */
body::-webkit-scrollbar,
*::-webkit-scrollbar {
	width: 0.5em;
	height: 0.3em;
}

body::-webkit-scrollbar-track,
*::-webkit-scrollbar-track {
	background-color: #0c75e6;
}

// body::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb {
	background-color: #45cc9c;
}

/* custom scrollbar for firefox */
@-moz-document url-prefix() {
	*,
	html,
	body {
		/* "auto" or "thin"  */
		scrollbar-width: thin;
		/* scroll thumb & track */
		scrollbar-color: #45cc9c #0c75e6;
	}
}

// My Style
.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	// overflow: hidden;
}

main {
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: auto;
}

// Inputs Auto Fill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	box-shadow: none !important;
	background-color: #fff !important;
}

// Button
button {
	&:focus {
		box-shadow: none !important;
	}
}

// Table
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
	text-align: start !important;
}

.p-datatable > .p-datatable-wrapper {
	&::-webkit-scrollbar {
		height: 0.2em;
	}
}

.table_holder {
	.p-datatable .p-datatable-thead > tr > th {
		background-color: #f8f9fc;
		padding: 12px;
		border: 0;
		@include font(14px, 700, #808b9a);
		text-transform: capitalize;
	}

	.p-datatable .p-datatable-tbody > tr > td {
		padding: 12px;
		border-color: #dce0e5;
		@include font(14px, 600, #202326);
	}
}

// Dialog
.p-dialog {
	@media (max-width: 991.98px) {
		width: 80vw !important;
	}

	@media (max-width: 575.98px) {
		width: 90vw !important;
	}
}

// Swiper Pagination Bullets [Pagination]
.swiper-pagination-bullet {
	margin: 0 !important;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	width: 12px;
	height: 12px;
	background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
	opacity: 0.4;
	@include transition(0.3s);

	&-active {
		opacity: 1;
	}
}

// Sections Head
.sec_head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	flex-wrap: wrap;

	h2 {
		@include font(24px, 800, #202326);
		margin-bottom: 1px;
	}

	p {
		@include font(14px, 400, #808b9a);
		margin-bottom: 0;
	}
}

// Swiper Button [Navigation]
.review-swiper-button-prev,
.review-swiper-button-next {
	width: 40px !important;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 20px -2px #020e1f14, 0px 0px 1px 0px #020e1f1a;
	background-color: #fff !important;
	border: 1px solid #dce0e5;
	@include borderRadius(30px);
	padding: 8px;
	color: #0202c1 !important;
	position: absolute;
	top: 50%;
	z-index: 1;
	@include transition(0.3s);
}

.review-swiper-button-prev {
	#{$ltr} & {
		left: 0;
		@include transform(translate(-50%, -90%) rotate(0deg));
	}
	#{$rtl} & {
		right: 0;
		@include transform(translate(50%, -90%) rotate(180deg));
	}
}

.review-swiper-button-next {
	#{$ltr} & {
		right: 0;
		@include transform(translate(50%, -90%) rotate(0deg));
	}
	#{$rtl} & {
		left: 0;
		@include transform(translate(-50%, -90%) rotate(180deg));
	}
}

// Input Holder
.input_holder {
	display: flex;
	flex-direction: column;
	gap: 8px;

	// &:not(:last-child) {
	// 	margin-bottom: 20px;
	// }

	h6 {
		&.required {
			position: relative;

			&::after {
				content: "*";
				display: inline-block;
				-webkit-margin-start: 6px;
				margin-inline-start: 6px;
				@include font(14px, 600, #dc3545);
			}
		}
	}
}

input {
	font-size: 14px !important;
	font-weight: 500 !important;
	color: #202326 !important;
	caret-color: #3368c6;
	box-shadow: none !important;
}

.p-dropdown {
	&.p-focus {
		box-shadow: none;
	}
}

// Margin Bottm
.m-b-20 {
	margin-bottom: 20px;
}

.m-b-24 {
	margin-bottom: 24px;
}

// CheckBox
.check_box {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;

	.p-checkbox {
		width: 18px;
		height: 18px;

		&-box {
			width: 18px;
			height: 18px;
			border: 1px solid #b5bec9 !important;
			background: #fff;
			box-shadow: 0px 1px 8px 0px #020e1f0a !important;
			@include borderRadius(4px);
			padding: 2px;

			&.p-highlight {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%) !important;
				border-color: transparent !important;
				-o-border-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				border-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				border-image-slice: 1;
				border-image-width: 1px;
			}

			.p-checkbox-icon {
				width: 100% !important;
				height: 100% !important;
			}
		}
	}

	.checkbox_lbl {
		@include font(14px, 400, #202326);
	}
}

// Title & Action
.title_action {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	margin-bottom: 32px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 0;
	}
}

// Table Pagination
.p_pagination {
	padding: 20px;
	background-color: transparent;
	gap: 12px;
	border-bottom-left-radius: 6px !important;
	border-bottom-right-radius: 6px !important;

	@media (max-width: 420.98px) {
		gap: 8px;
	}

	@media (max-width: 410.98px) {
		gap: 4px;
	}

	.p-paginator-prev,
	.p-paginator-next {
		margin: 0;
		height: auto;
		min-width: auto;
		border: 1px solid #dce0e5;
		background-color: #fff;
		@include borderRadius(6px);
		padding: 9px 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		@include font(14px, 500, #202326);
		@include transition(0.3s);

		#{$rtl} & {
			@include transform(rotate(180deg));
		}

		&.p-disabled {
			border-color: transparent;
			background-color: #eceff2;
			color: #b3becd;
			opacity: 1;
		}

		@media (max-width: 410.98px) {
			padding: 9px 10px;
		}
	}

	.p-paginator-pages {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;

		@media (max-width: 399.98px) {
			gap: 4px;
		}

		.p-paginator-page {
			margin: 0;
			height: auto;
			min-width: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 10px 14px;
			@include borderRadius(6px);
			border: 1px solid #dce0e5;
			background-color: #fff;
			@include font(14px, 500, #202326);
			line-height: 20px;

			&.p-highlight {
				position: relative;
				border-color: transparent;
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
				@include font(14px, 500, #202326);
				z-index: 0;

				&::before {
					content: "";
					width: 100%;
					height: 100%;
					background-color: #eaf8f9;
					position: absolute;
					z-index: -1;
				}

				span {
					z-index: 1;
					@include font(14px, 500, #202326);
				}
			}
		}
	}
}

// Line Height
.lineH-24 {
	line-height: 24px;
}

.lineH-normal {
	line-height: normal !important;
}

// Margin Bottom
.mb-32 {
	margin-bottom: 32px;
}

// Margin inlne Start [auto]
.mis-auto {
	-webkit-margin-start: auto;
	margin-inline-start: auto;
}

// Margin inlne End [auto]
.mie-auto {
	-webkit-margin-end: auto;
	margin-inline-end: auto;
}
