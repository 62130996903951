@import "assets/scss/vars.scss";

.doctors_available {
	padding: 50px 0;
	background-color: #f8f9fc;

	.sec_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 24px;
		margin-bottom: 32px;
		flex-wrap: wrap;

		.left_side {
			h2 {
				@include font(16px, 800, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.right_side {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 24px;
			flex-wrap: wrap;

			.sort_by {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 24px;

				:global {
					.input_holder {
						min-width: 160px;
					}
				}
			}
		}
	}
}
