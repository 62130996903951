@import "assets/scss/vars.scss";

.switch_input {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;

	.switch_holder {
		flex-shrink: 0;
		width: 40px;
		height: 24px;

		:global {
			.p-inputswitch-slider {
				@include borderRadius(30px);
				background-color: #e6e9ed;
				box-shadow: none !important;
				padding: 3px;

				&::before {
					width: 18px;
					height: 18px;
					box-shadow: 0px 2px 6px 0px #8392a580;
					margin-top: 0;
					top: 50%;
					@include transform(translate(0, -50%));
					left: 3px;
				}
			}
		}

		&:hover {
			:global {
				.p-inputswitch-slider {
					background-color: #e6e9ed;
				}
			}
		}
	}

	:global {
		.p-inputswitch-checked {
			.p-inputswitch-slider {
				background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%) !important;

				&::before {
					left: auto;

					#{$ltr} & {
						@include transform(translate(0.97rem, -50%));
					}
					#{$rtl} & {
						@include transform(translate(0.07rem, -50%));
					}
				}
			}
		}
	}

	.switch_label {
		@include font(14px, 400, #202326);
	}
}
