@import "assets/scss/vars.scss";

.summary {
	margin-bottom: 32px;

	h2 {
		@include font(16px, 800, #202326);
		margin-bottom: 32px;
	}

	&_box {
		padding: 20px 24px;
		background-color: #fff;
		border: 0.7px solid #dce0e5;
		box-shadow: 0px 1px 8px 0px #020e1f0a;
		@include borderRadius(6px);

		.info_side {
			-webkit-border-end: 1px solid #dce0e5;
			border-inline-end: 1px solid #dce0e5;
			height: 100%;

			@media (max-width: 991.98px) {
				-webkit-border-end: 0;
				border-inline-end: 0;
				border-bottom: 1px solid #dce0e5;
				padding-bottom: 16px;
			}

			.avg_ratings {
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid #dce0e5;
				-webkit-padding-end: 16px;
				padding-inline-end: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 12px;

				@media (max-width: 991.98px) {
					-webkit-padding-end: 0;
					padding-inline-end: 0;
				}

				.left {
					h6 {
						@include font(14px, 400, #808b9a);
						margin-bottom: 2px;
					}

					h3 {
						@include font(36px, 800, #202326);
						margin-bottom: 2px;
					}
				}

				.growth {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 12px;

					img {
						flex-shrink: 0;
						height: 12px;
					}

					span {
						@include font(18px, 800, #48bb78);
					}
				}
			}

			.total_reviews {
				-webkit-padding-end: 16px;
				padding-inline-end: 16px;

				@media (max-width: 991.98px) {
					-webkit-padding-end: 0;
					padding-inline-end: 0;
				}

				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 2px;
				}

				h4 {
					@include font(24px, 800, #202326);
					margin-bottom: 0;
				}
			}
		}

		.ratings {
			height: 100%;

			.rating_row {
				&:not(:last-child) {
					margin-bottom: 13px;

					@media (max-width: 600.98px) {
						margin-bottom: 20px;
					}
				}

				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 24px;

				@media (max-width: 600.98px) {
					flex-direction: column;
					align-items: flex-start;
					gap: 10px;
				}

				h5 {
					@include font(14px, 700, #202326);
					margin-bottom: 0;
				}

				[class*="progressBar_wrapper"] {
					flex-grow: 1;
					margin-bottom: 0;

					@media (max-width: 600.98px) {
						width: 100%;
					}

					:global {
						.p-progressbar {
							height: 24px;
							background-color: #f3f4f6;
						}
					}
				}

				h6 {
					@include font(14px, 500, #202326);
					margin-bottom: 0;
				}
			}
		}
	}
}
