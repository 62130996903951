@import "assets/scss/vars.scss";

%status {
	padding: 3px 6px;
	@include borderRadius(6px);
	display: flex;
	align-items: center;
	justify-content: center;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	text-transform: capitalize;
	@include font(10px, 600, transparent);
	line-height: 14px;
}

.status_badge {
	&:global(.active) {
		@extend %status;
		background-color: #48bb78;
		color: #fff;
	}

	&:global(.inactive) {
		@extend %status;
		background-color: #8392a5;
		color: #fff;
	}

	&:global(.accepted) {
		@extend %status;
		background-color: #48bb78;
		color: #fff;
	}

	&:global(.denied) {
		@extend %status;
		background-color: #8392a5;
		color: #fff;
	}
}
