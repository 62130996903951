@import "assets/scss/vars.scss";

.today_appointments {
	padding: 20px 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);

	.head {
		margin-bottom: 24px;

		h3 {
			@include font(16px, 800, #202326);
			margin-bottom: 0;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.appointments_boxes {
		.view_p {
			span {
				line-height: 18px;
			}
		}

		.action_btn {
			padding: 6px;

			img {
				width: 18px;
				height: 18px;
			}
		}
	}
}

.action_menu {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 0.5px solid #dce0e5 !important;
	@include borderRadius(8px);
	padding: 12px;
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				.p-menuitem-link {
					padding: 10px 8px;
					@include borderRadius(4px);
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					width: 100%;
					background-color: #fff !important;
					border: 0 !important;

					img {
						flex-shrink: 0;
						width: 18px;
						height: 18px;
					}

					span {
						@include font(14px, 500, #202326);
						text-transform: capitalize;
					}

					&:hover {
						background-color: #f3f4f6 !important;
					}
				}
			}
		}
	}
}
