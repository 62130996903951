@import "assets/scss/vars.scss";

.changePass_content {
	.head {
		margin-bottom: 24px;

		h2 {
			@include font(16px, 800, #202326);
			margin-bottom: 2px;
		}

		p {
			@include font(14px, 400, #808b9a);
			margin-bottom: 0;
		}
	}

	.hint {
		@include font(12px, 500, #808b9a);
		margin-top: 8px;
	}
}
