// DIRECTION
$ltr: "body[dir='ltr']";
$rtl: "body[dir='rtl']";

// MIXINS
@mixin font($size, $weight, $color) {
	font-size: $size;
	font-weight: $weight;
	color: $color;
}

@mixin transition($val) {
	transition: $val;
	-webkit-transition: $val;
	-moz-transition: $val;
	-ms-transition: $val;
	-o-transition: $val;
}

@mixin transform($args...) {
	transform: $args;
	-webkit-transform: $args;
	-moz-transform: $args;
	-ms-transform: $args;
	-o-transform: $args;
}

@mixin borderRadius($val) {
	border-radius: $val;
	-webkit-border-radius: $val;
	-moz-border-radius: $val;
	-ms-border-radius: $val;
	-o-border-radius: $val;
}

// PLACEHOLDERS
%gradientText {
	background-image: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

// Chat Bubble Mask
%chatMask {
	mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%) var(--_d)
			100% / calc(100% - var(--r)) var(--t) no-repeat,
		conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)
			calc(var(--r) / -2) padding-box,
		radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space
			padding-box;
	-webkit-mask: radial-gradient(var(--t) at var(--_d) 0, #0000 98%, #000 102%)
			var(--_d) 100% / calc(100% - var(--r)) var(--t) no-repeat,
		conic-gradient(at var(--r) var(--r), #000 75%, #0000 0) calc(var(--r) / -2)
			calc(var(--r) / -2) padding-box,
		radial-gradient(50% 50%, #000 98%, #0000 101%) 0 0 / var(--r) var(--r) space
			padding-box;
}
