@import "assets/scss/vars.scss";

.my_answers {
	&_head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		margin-bottom: 32px;

		.qty_count {
			@include font(14px, 400, #808b9a);
		}

		.sort_by {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 20px;

			.sort_lbl {
				@include font(14px, 500, #202326);
			}
		}
	}

	&_qtys {
		.qty_box {
			&:not(:last-child) {
				margin-bottom: 16px;
			}

			padding: 20px;
			border: 1px solid #dce0e5;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
			@include borderRadius(6px);

			h5 {
				@include font(14px, 700, transparent);
				@extend %gradientText;
				margin-bottom: 6px;
			}

			.qty_date {
				margin-bottom: 16px;

				h3 {
					@include font(16px, 800, #202326);
					margin-bottom: 2px;
				}

				h6 {
					@include font(14px, 400, #808b9a);
					margin-bottom: 0;
				}
			}

			p {
				@include font(14px, 400, #202326);
				margin-bottom: 0;
			}

			.your_answer {
				margin-top: 12px;
				padding-top: 12px;
				border-top: 1px solid #dce0e5;

				h2 {
					@include font(14px, 700, #202326);
					margin-bottom: 12px;
				}

				.answer_box {
					padding: 12px 16px;
					background-color: #eaf8f9;
					@include borderRadius(6px);

					.answer_text {
						@include font(14px, 400, #202326);
						margin-bottom: 6px;
					}

					.answer_date {
						@include font(14px, 400, #808b9a);
					}
				}
			}
		}
	}
}
