@import "assets/scss/vars.scss";

.qty_card {
	&:not(:last-child) {
		margin-bottom: 24px;
	}

	padding: 20px;
	background-color: #fff;
	border: 1px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	gap: 12px;

	@media (max-width: 767.98px) {
		flex-direction: column;
	}

	.qty_data {
		flex-grow: 1;

		.specialty {
			@include font(14px, 700, transparent);
			@extend %gradientText;
			margin-bottom: 6px;
		}

		.qty_date {
			margin-bottom: 16px;

			h3 {
				@include font(16px, 800, #202326);
				margin-bottom: 2px;
			}

			h6 {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.description {
			@include font(14px, 400, #202326);
			margin-bottom: 16px;
		}

		.no_answer {
			padding: 12px;
			background-color: #f8f9fc;
			border: 1px solid #dce0e5;
			@include borderRadius(6px);
			@include font(14px, 400, #808b9a);
		}
	}

	.qty_action {
		flex-shrink: 0;

		@media (max-width: 767.98px) {
			order: -1;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}

		.action_btn {
			width: 20px;
			height: 20px;
			padding: 3px 9px;
			font-size: 14px;
			background-color: #fff;
			border: 0;
			color: #8392a5;

			:global(.p-button-icon) {
				font-size: 14px;
			}
		}
	}
}

.action_menu {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a !important;
	border: 0.5px solid #dce0e5 !important;
	@include borderRadius(8px);
	padding: 12px;
	width: 15rem;

	:global {
		.p-menu-list {
			.p-menuitem {
				.p-menuitem-link {
					padding: 10px 8px;
					@include borderRadius(4px);
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;
					width: 100%;
					background-color: #fff !important;
					border: 0 !important;

					img {
						flex-shrink: 0;
						width: 18px;
						height: 18px;
					}

					span {
						@include font(14px, 500, transparent);
						text-transform: capitalize;

						&.update {
							color: #202326;
						}

						&.delete {
							color: #dc3545;
						}
					}

					&:hover {
						background-color: #f3f4f6 !important;
					}
				}
			}
		}
	}
}
