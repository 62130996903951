@import "assets/scss/vars.scss";

.step_content {
	padding: 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	display: flex;
	flex-direction: column;
	min-height: 50vh;

	.top_content {
		flex-grow: 1;
		margin-bottom: 20px;

		.symptoms_head {
			margin-bottom: 20px;

			h2 {
				@include font(18px, 800, #202326);
				margin-bottom: 2px;
			}

			p {
				@include font(14px, 400, #808b9a);
				margin-bottom: 0;
			}
		}

		.symptoms_body {
			.search_keywords {
				margin-top: 20px;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 16px;
				flex-wrap: wrap;

				.keyword_box {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 6px;
					background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
					padding: 7px 10px;
					@include borderRadius(8px);

					.keyword_label {
						@include font(12px, 600, #ffffff);
					}

					.remove_btn {
						padding: 0;
						width: 16px;
						height: 16px;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						background-color: transparent !important;
						border: 0 !important;

						img {
							width: 100%;
						}
					}
				}
			}
		}
	}

	.step_actions {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 20px;
		padding-top: 20px;
		border-top: 1px solid #dce0e5;

		.backBtn,
		.nextBtn {
			img {
				#{$rtl} & {
					@include transform(rotate(180deg));
				}
			}
		}
	}
}
