@import "assets/scss/vars.scss";

.consultation_call {
	padding: 24px;
	background-color: #eaf8f9;
	border: 1px solid #7fd3da;
	@include borderRadius(8px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 24px;

	&_img {
		width: 100%;
		max-width: 122px;
		margin: 0 auto;
	}

	&_txt {
		.txt_title {
			@include font(16px, 800, #202326);
			text-align: center;
			margin-bottom: 2px;
		}

		.sub_title {
			@include font(14px, 400, #808b9a);
			text-align: center;
		}
	}

	.download_btns {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 16px;
		flex-wrap: wrap;

		.app_btn {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 48px;

			img {
				height: 100%;
			}
		}
	}

	&.without_border {
		border: 0;
	}
}
