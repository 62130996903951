@import "assets/scss/vars.scss";

.booking_info {
	padding: 24px;
	background-color: #fff;
	@include borderRadius(8px);
	margin-bottom: 16px;

	h3 {
		@include font(16px, 800, #202326);
		margin-bottom: 16px;
	}

	.booking_rows {
		.booking_row {
			&:not(:last-child) {
				margin-bottom: 14px;
			}

			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: 8px;

			img {
				flex-shrink: 0;
				width: 100%;
				max-width: 20px;
			}

			span {
				@include font(14px, 400, #202326);
			}
		}
	}
}
