@import "assets/scss/vars.scss";

.field_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.input_box {
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		@include transition(0.3s);

		:global {
			.p-calendar {
				border: 0;
				@include borderRadius(6px);
				box-shadow: none !important;
				position: relative;

				.p-inputtext {
					background-color: #fff !important;
					border: 0;
					box-shadow: none !important;
					position: relative;
					@include font(14px, 600, #202326);
					#{$ltr} & {
						@include borderRadius(6px 0 0 6px);
					}
					#{$rtl} & {
						@include borderRadius(0 6px 6px 0);
					}
				}

				.p-button {
					background-color: #fff;
					color: #000;
					border: 0;
					#{$ltr} & {
						@include borderRadius(0 6px 6px 0);
					}
					#{$rtl} & {
						@include borderRadius(6px 0 0 6px);
					}
				}

				&.withoutIcon {
					.p-inputtext {
						#{$ltr} & {
							@include borderRadius(6px);
						}
						#{$rtl} & {
							@include borderRadius(6px);
						}
					}
				}
			}
		}

		&:hover {
			background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
			border-color: transparent;
			box-shadow: 0px 1px 8px 0px #020e1f0a;
		}

		&:global(.p-invalid) {
			&,
			&:hover {
				background-color: #dc3545;
				border-color: #dc3545;
			}
		}

		&.customP {
			:global {
				.p-inputtext {
					padding: 8px;
				}

				.p-datepicker-trigger {
					width: 2rem;
					padding: 0;

					svg {
						path {
							fill: #8392a5;
						}
					}
				}
			}
		}
	}
}

.calendar_menu {
	background-color: #fff;
	box-shadow: 0px 20px 24px 0px #020e1f0f, 0px 0px 1px 0px #020e1f1a;
	border: 0.5px solid #dce0e5;

	:global {
		.p-datepicker-header {
			.p-datepicker-prev {
				#{$rtl} & {
					@include transform(rotate(180deg));
				}
			}

			.p-datepicker-next {
				#{$rtl} & {
					@include transform(rotate(180deg));
				}
			}
		}

		.p-datepicker-calendar {
			thead {
				tr {
					th {
						span {
							@extend %gradientText;
						}
					}
				}
			}

			tbody {
				tr {
					td {
						span {
							color: #202326;
							@include borderRadius(8px);
							background-color: #fff !important;

							&.p-disabled {
								color: #808b9a;
							}

							&.p-highlight {
								background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
								color: #fff;
							}
						}

						&.p-datepicker-today {
							span {
								&::before {
									content: "";
									width: 12px;
									height: 4px;
									@include borderRadius(2px);
									position: absolute;
									bottom: 0;
									left: 50%;
									@include transform(translateX(-50%));
									background: linear-gradient(90deg, #45cc9c 0%, #0c75e6 100%);
								}
							}
						}
					}
				}
			}
		}
	}
}
