@import "assets/scss/vars.scss";

.balance_box {
	padding: 20px 24px;
	background-color: #fff;
	border: 0.7px solid #dce0e5;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);

	h6 {
		@include font(14px, 400, #808b9a);
		margin-bottom: 0;
		line-height: 20px;
	}

	h4 {
		@include font(20px, 800, transparent);
		margin-bottom: 0;
		line-height: 28px;

		&.green {
			color: #48bb78;
		}

		&.black {
			color: #202326;
		}
	}
}

.account_holder {
	h6 {
		@include font(14px, 400, #202326);
		margin-bottom: 8px;
	}

	.account_row {
		padding: 12px 10px;
		background-color: #fff;
		border: 1px solid #dce0e5;
		@include borderRadius(6px);
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;

		.account_icon {
			flex-shrink: 0;
			width: 20px;
			height: 20px;
		}

		.account_text {
			@include font(14px, 600, #202326);
			margin-bottom: 0;
		}
	}
}
