@import "assets/scss/vars.scss";

.nextP_details {
	padding: 20px 24px;
	background-color: #fff;
	box-shadow: 0px 1px 8px 0px #020e1f0a;
	@include borderRadius(6px);
	margin-bottom: 24px;

	h3 {
		@include font(16px, 800, #202326);
		margin-bottom: 16px;
	}

	.details {
		&_row {
			&:not(:last-child) {
				padding-bottom: 16px;
				margin-bottom: 16px;
				border-bottom: 1px solid #e5e7eb;
			}

			&.info {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				@media (max-width: 600.98px) {
					flex-wrap: wrap;
				}

				.pic_name {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 10px;

					.img {
						flex-shrink: 0;
						width: 44px;
						height: 44px;
						@include borderRadius(100%);
						display: flex;
						align-items: center;
						justify-content: center;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							-o-object-fit: cover;
							object-fit: cover;
						}
					}

					.name {
						@include font(16px, 800, #202326);
						margin-bottom: 0;
					}
				}

				.info_actions {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 10px;

					@media (max-width: 600.98px) {
						flex-wrap: wrap;
					}

					.view_p {
						span {
							line-height: 24px;
						}
					}
				}
			}

			&.contact {
				.contact_row {
					&:not(:last-child) {
						margin-bottom: 16px;
					}

					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 12px;

					.icon {
						flex-shrink: 0;
						width: 20px;
						height: 20px;
					}

					h5 {
						@include font(14px, 700, #202326);
						margin-bottom: 0;
					}
				}
			}

			&.appointment {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 12px;

				.icon {
					flex-shrink: 0;
					width: 20px;
					height: 20px;
				}

				.appointment_info {
					h4 {
						@include font(14px, 700, #202326);
						margin-bottom: 0;
						line-height: 20px;
					}

					p {
						@include font(14px, 400, #808b9a);
						margin-bottom: 0;
						line-height: 20px;
					}
				}
			}

			&.actions {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 12px;

				.action_btn {
					span {
						line-height: 24px;
					}
				}
			}
		}
	}
}
